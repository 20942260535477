<div class="container_fluid pb-5">
    <div class="row px-3 px-md-5 py-4 m-0" *ngIf="!loader">
        <div class="col-12 col-md-3 my-3">
            <div class="row shadow p-3 m-1 h-100">
                <div class="col-12">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <h5 class="burgendy fw-semibold">TOTAL USERS</h5>
                            <h4 class="fw-semibold">{{summaryData.totalUsers}}</h4>
                        </div>
                        <div class="col-2 offset-2 text-end d-flex align-items-center total_Users py-2 h-100 rounded">
                            <i class="bi bi-person-circle text-center w-100"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 py-4">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Invited Users</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.invitedUsers}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Onboarded Users</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.onboardedUsers}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex align-items-end">
                    <a routerLink="/adminDashboard/users">View all users</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3 my-3">
            <div class="row shadow p-3 m-1 h-100">
                <div class="col-12">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <h5 class="burgendy fw-semibold">ALL EVENTS</h5>
                            <h4 class="fw-semibold">{{summaryData?.totalEvents}}</h4>
                        </div>
                        <div class="col-2 offset-2 h-100 rounded py-2 text-end d-flex align-items-center total_events ">
                            <i class="bi bi-calendar2-event text-center w-100"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 py-4">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Published Events</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.publishedEvents}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Draft events</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.draftEvents}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Revenue</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.totalRevenue}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex align-items-end">
                    <a routerLink="/adminDashboard/eventsList">View all events</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3 my-3">
            <div class="row shadow p-3 m-1 h-100">
                <div class="col-12">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <h5 class="burgendy fw-semibold">ALL NEWS</h5>
                            <h4 class="fw-semibold">{{summaryData?.totalNews}}</h4>
                        </div>
                        <div class="col-2 offset-2 h-100 rounded py-2 text-end d-flex align-items-center total_news">
                            <i class="bi bi-newspaper text-center w-100"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 py-4">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Published News</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.publishedNews}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-item-center">
                        <div class="col-8 pb-5">
                            <p class="color-ash">Draft News</p>
                        </div>
                        <div class="col-4 text-end pb-2">
                           <p class="color-ash">{{summaryData?.draftNews}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex align-items-end">
                    <a routerLink="/adminDashboard/news">View all news</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3 my-3">
            <div class="row shadow p-3 m-1 h-100">
                <div class="col-12">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <h5 class="burgendy fw-semibold">ALL BLOGS</h5>
                            <h4 class="fw-semibold">{{summaryData?.totalBlogs}}</h4>
                        </div>
                        <div class="col-2 offset-2 h-100 rounded py-2 text-end d-flex align-items-center total_blogs">
                            <i class="bi bi-book text-center w-100"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 py-4">
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Published Blogs</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.publishedBlogs}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Draft Blogs</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.draftBlogs}}</p>
                        </div>
                    </div>
                    <div class="row d-flex align-item-center">
                        <div class="col-8">
                            <p class="color-ash">Authors</p>
                        </div>
                        <div class="col-4 text-end">
                           <p class="color-ash">{{summaryData?.totalAuthors}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 d-flex align-items-end">
                    <a routerLink="/adminDashboard/blogs">View all blogs</a>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <p-progressSpinner *ngIf="loader"
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
    <hr>
    <div class="row px-3 px-md-5 py-4 m-0">
        <div class="col-12" *ngIf="!eventsLoader">
            <p-table #eventsList [value]="upcomingEvents" sortMode="single" [responsive]="true" [rows]="5" sortOrder="-1"
            [paginator]="true"   [globalFilterFields]="['eventName','ticketsSold','revenue','eventStartDateAndTime']">
            <ng-template pTemplate="caption">
                <div class="row align_inline">
                    <div class="col-12 col-md-4 d-flex align-items-center">
                        <h4 class="burgendy fw-bold">Upcoming Events</h4>
                    </div>
                    <div class="col-12 col-md-4 d-flex" style="text-align: right">
                        <input type="text"
                            class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2"
                            autocomplete="off" pInputText size="50" placeholder=" Search here"
                            (input)="eventsList.filterGlobal($event.target.value, 'contains')" style="height: 2.25rem;font-size: 13px;
                        border-radius: 5px 0px 0px 5px;">
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'eventName'" class="fw-semibold">Event Title<p-sortIcon
                            [field]="'eventName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'ticketsSold'" class="fw-semibold text-center">Tickets Sold <p-sortIcon
                            [field]="'ticketsSold'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'revenue'" class="fw-semibold text-center">Revenue<p-sortIcon
                            [field]="'revenue'"></p-sortIcon>
                    </th>

                    <th [pSortableColumn]="'eventStartDateAndTime'" class="fw-semibold">Event Date<p-sortIcon
                            [field]="'eventStartDateAndTime'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-event let-rowData let-columns="columns" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                    <td class="custom_width__ForTextWrap">{{event?.eventName }}</td>
                    <td  class="text-center">{{event?.ticketsSold}}</td>
                    <td class="text-center" > $ {{event?.revenue}}</td>
                    <td >{{event?.eventStartDateAndTime | date: 'MMM dd-yyyy'
                    }}</td>
                </tr>
            </ng-template>
            </p-table>
        </div>
        <div class="col-12 text-center">
            <p-progressSpinner *ngIf="eventsLoader"
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>