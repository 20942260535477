import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-event-public-detail-view',
  templateUrl: './event-public-detail-view.component.html',
  styleUrls: ['./event-public-detail-view.component.css']
})
export class EventPublicDetailViewComponent implements OnInit {
  eventId:any;
  eventDetails:any;
  eventName:string = "";
  eventDescription:string = "";
  loader: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private EventDetails:EventDetailsService,
    private router: Router,
    private appinsightsService: LoggingService,
    private messageService: MessageService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event list public detail view screen visited')
    this.titleService.setTitle('Event Details');
    this.route.params.forEach((urlParams) => {
      this.eventId = urlParams['id'];
    });
    this.getEventDetails(this.eventId);
  }
  
  getEventDetails(eventId){
    try{
     this.loader = true;
    this.EventDetails.getEventDetails(eventId).subscribe((data) => {
      this.loader = false;
      this.eventDetails = data.result;
      this.eventName = this.eventDetails.name;
      this.eventDescription = this.eventDetails.description
    },(error)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting Event public details api", {payload: JSON.stringify(eventId),error:JSON.stringify(error)})
       if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }
    }) 
  }
  catch(error){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  }

  navigateToPublicEvents(){
    this.router.navigate(['/Events']);
  }

}
