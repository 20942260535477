import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { createEvent } from './create-event';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { LoggingService } from 'src/app/services/logging.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TimeZoneService } from 'src/app/services/time-zone.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css'],
})
export class CreateEventComponent implements OnInit {
  createNewEvent: FormGroup;
  eventStatusOptions=[
    {id:'0', value:'--Select--'},
    {id:'DRAFT', value:'DRAFT'},
    {id:'PUBLISHED', value:'PUBLISHED'},
    {id:'CLOSE_SALES', value:'CLOSE_SALES'}
  ]
  loader: boolean = false;
  timeZone:any[] ; 
  minEndDate = new Date();
  ticketStatusOptions:any;
  startTimeValue:any;
  endTimeValue: any;
  minStartDate =  new Date();
  
  constructor(
    private fb: FormBuilder, 
    private titleService: Title,
    private EventDetails:EventDetailsService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private loginService: LoginService, 
    private router: Router,
    private confirmationService: ConfirmationService,
    private appinsightsService: LoggingService,
    private loaderService: LoaderService,
    private timeZon : TimeZoneService,
    private loginDetails: LoginService
    ) {
      this.timeZone = JSON.parse(JSON.stringify(this.timeZon.timeZone));
    }
    items = new FormArray([], this.customGroupValidation );
    arrayItems: FormArray;

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event Creation screen visited')
    this.titleService.setTitle('Create Event');
    this.ticketStatusOptions = this.EventDetails.ticketStatusOptions;
    this.startTimeValue =  JSON.parse(JSON.stringify(this.EventDetails.timeValue));
    this.endTimeValue = JSON.parse(JSON.stringify(this.EventDetails.timeValue));
    this.createNewEvent = this.fb.group({
      eventName: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventStartsOnCalendar:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventStartsOnTime:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventEndsOnCalendar:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventEndsOnTime:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      timeZone:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      venue:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      postcode:new FormControl(
        null,
        Validators.compose([Validators.required,Validators.pattern('[0-9]+')])
      ),
      description:new FormControl(null,Validators.compose([Validators.required])),
      eventStatus:new FormControl(null,Validators.compose([Validators.required])),
      items: new FormArray([], this.customGroupValidation),
    });
    this.addItem();
   
  }
  get TicketDetailsArr(): FormArray {
    return this.createNewEvent.get('items') as FormArray;
  }
  addItem(): void {
    this.arrayItems = this.createNewEvent.get('items') as FormArray;
    this.arrayItems.push(this.createItem());
  }
  createItem(): FormGroup {
    return this.fb.group({
      ticketName: new FormControl(null, Validators.compose([Validators.required])),
      price: new FormControl(null, Validators.compose([Validators.required, Validators.pattern("[0-9]+(\.[0-9]+)?")])),
      bookingprice: new FormControl(null, Validators.compose([Validators.pattern("[0-9]+(\.[0-9]+)?")])),
      quantity: new FormControl(null, Validators.compose([Validators.required, Validators.pattern("[0-9]+")])),
      status: new FormControl(null, Validators.compose([Validators.required])),
    });
  }
  deleteItem(index: number) {
    const control = <FormArray>this.createNewEvent.controls['items'];
    if (control.length !== 1) {
      control.removeAt(index);
    }
  }

  customGroupValidation (formArray) {
    let isError = false;
    const result = _.groupBy( formArray.controls , c => {
      return [c.value.ticketName];
    });

    for (const prop in result) {
        if (result[prop].length > 1 && result[prop][0].controls['ticketName'].value !== null) {
          isError = true;
            _.forEach(result[prop], function (item: any, index) {
             
              item._status = 'INVALID';
            });
        } else {
            result[prop][0]._status = 'VALID';
            
        }
    }
    if (isError) { 
      return {'duplicate': 'duplicate entries'};
     }
     return null;
}

 async onSubmit(formdata: any) {
    try{
    if (this.createNewEvent.valid) {
      let eventDetailsObj:createEvent ={
        currency: "usd",
        name: formdata.eventName,
        description: formdata.description,
        postalCode: formdata.postcode,
        timeZone:formdata.timeZone.name,
        venue: formdata.venue,
        startDate: this.datePipe.transform(formdata.eventStartsOnCalendar,'yyyy-MM-dd'),
        startTime: formdata.eventStartsOnTime.id,
        endDate: this.datePipe.transform(formdata.eventEndsOnCalendar,'yyyy-MM-dd'),
        endTime: formdata.eventEndsOnTime.id,
        eventStatus: formdata.eventStatus.id,
        ticketTypeRequests: [],
      }
      this.TicketDetailsArr.controls.forEach((element:any, index) => {
        eventDetailsObj.ticketTypeRequests.push({
          ticketName: element.value.ticketName,
          ticketPrice:parseInt(element.value.price),
          bookingFee: element.value.bookingprice != null? parseInt(element.value.bookingprice) : 0,
          ticketDescription: " ", 
          ticketStatus: element.value.status.value , 
          quantity: parseInt(element.value.quantity), 
           });
       });
      this.loader = true;
      await this.loginDetails.tokenRenewal();
      await this.EventDetails.createEvent(eventDetailsObj).subscribe((data)=>{
        this.loader = false;
        this.messageService.add({
          severity:'success', 
          summary: 'Success', 
          detail: 'Event created successfully'
        });
        this.createNewEvent.reset();
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in Event Creation api", {payload: JSON.stringify(eventDetailsObj),error:JSON.stringify(error)})
        if(error.status == 401){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException[0]
          });
        }
      })
    }
    else{
      for (const field in this.createNewEvent.controls) {
        this.createNewEvent.controls[field].markAsDirty();
      }
    }
  }
  catch(error){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  }
  navigateToEvents(){
    this.router.navigate(['/adminDashboard/eventsList']);;
  }

  validateDate(event:any){
    console.log(event)
  }
  onDateSelection(event){
    this.createNewEvent.controls['eventEndsOnCalendar'].reset();
    const yesterdayDate = new Date(event);
    this.minEndDate = yesterdayDate;
  }
  onEndDateSelection(event){
    this.disableEndTime(this.createNewEvent.controls['eventStartsOnTime'].value)
  }
  onSelectingStartTime(event){
    this.createNewEvent.controls['eventEndsOnTime'].reset();
    this.disableEndTime(event);

  }
  disableEndTime(event){
    let index = 0;
    const startDate = new Date(this.createNewEvent.controls['eventStartsOnCalendar'].value);
    const endDate = new Date(this.createNewEvent.controls['eventEndsOnCalendar'].value)
    if(startDate.getDate() == endDate.getDate()){
      this.startTimeValue.map((x,i)=>{
        if(x.id == event.value.id || x.id == event.id){
         index = i;
        }
      })
    this.endTimeValue.map((x,i)=>{
      if(index >=i&& index != 0){

        x.disabled = true;
      }
      else{
        x.disabled = false;
      }
    })
    }
    else{
      this.endTimeValue.map((x,i)=>{
          x.disabled = false;
      })
    }
  }
}
