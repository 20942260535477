import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-submenu',
  templateUrl: './news-submenu.component.html',
  styleUrls: ['./news-submenu.component.css']
})
export class NewsSubmenuComponent implements OnInit {
  newsId:any;
  selectedNewsStatus: string;
  constructor(    
    private loginService: LoginService,
    private titleService: Title,
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
    private NewsDetails: NewsService, 
) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('News submenu visited')
  this.titleService.setTitle('News Submenu');
 try{
    this.loginService.SelectednewsID.subscribe(data=>{
      this.newsId = data;
      this.getNewsDetails(this.newsId);
    })
  }
  catch(error){
    this.appinsightsService.logException(error,2)
  }
}

getNewsDetails(newsid){
  try{
    this.NewsDetails.getSingleNews(newsid,false).subscribe((data)=>{
      if(data.statusCode == 200){
        this.selectedNewsStatus = data.result.status;
      }
    },(error)=>{
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in single news api", {payload: JSON.stringify(newsid),error:JSON.stringify(error)})
    })
  }
  catch(error){
    this.appinsightsService.logException(error,2)
  }
}
}
