import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: ['./event-summary.component.css']
})
export class EventSummaryComponent implements OnInit {
  eventName:string ='';
  constructor(
    private router: Router,
    private appinsightsService: LoggingService,
    private titleService: Title,  
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event summary screen visited')
    this.titleService.setTitle('Event summary');
    this.eventName = localStorage.getItem('selectedEvent');
  }

  navigateToEvents(){
    this.router.navigate(['/adminDashboard/eventsList']);
  }
}
