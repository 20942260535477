
<div class="container-fluid pb-5">
    <div class="row">
        <div class="col-12">
            <h4 class="burgendy py-3 fw-semibold"> OVERVIEW </h4>
        </div>
    </div>
    <div class="row" *ngIf="!loader">
        <div class="col-12 col-md-3 text-center my-2">
            <div class="shadow p-3  py-5 h-100">
                <h6 class="color-ash">VIEWS</h6>
                <h4 class="m-0 fw-semibold">{{overview?.totalViews}}</h4>
            </div>
        </div>
        <div class="col-12 col-md-3 text-center my-2">
            <div class="shadow p-3  py-5 h-100">
                <h6 class="color-ash">COMMENTS</h6>
                <h4 class="m-0 fw-semibold">{{overview?.totalComments}}</h4>
            </div>
        </div>
        <div class="col-12 col-md-3 text-center my-2">
            <div class="shadow p-3  py-5 h-100">
                <h6 class="color-ash">LIKES</h6>
                <h4 class="m-0 fw-semibold">{{overview?.totalLikes}}</h4>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="loader">
        <div class="col-12 text-center">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
    <div class="row">
        <div class="col-12 my-2">
            <div class="shadow p-3 h-100">
                <h5 class="fw-semibold">BLOG PAGE LINK</h5>
                <div class="col-12 col-md-8">
                    <div class="p-2  m-2 d-md-flex align-items-center justify-content-between bg-purple">
                        <p class="m-0 text-wrap" id="textToCopy">{{blogLink}}</p>
                        <i class="fa fa-copy cursor-pointer" pTooltip="{{tooltipMessage}}" (click)="copyTextToClipboard()" ></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-center" key="bc"></p-toast>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>




