
<div class="container-fluid p-0 custom_right_Border"> 
    <div class="row m-0 justify-content-center pt-3">
        <div class="col p-0" >
                <ul class="text-black list-unstyled">
                        <li class="menu cursor-pointer p-3" [routerLink]="'/adminDashboard/blogSummary/blogoverview/'+ blogId" routerLinkActive="activeClass"><i
                                class="fa fa-th-large" style="padding-right: 10px;"></i><span class="hide_textMenu p-0"> Blog Overview</span></li>              
                        <li class="menu cursor-pointer p-3 " [routerLink]="'/adminDashboard/blogSummary/blogedit/'+ blogId" routerLinkActive="activeClass"><i
                                class="fa fa-file-text" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Edit Blog</span></li>
                        <!-- <li class="menu cursor-pointer p-3"  [routerLink]="'/landing/adminDashboard/blogSummary/blogdelete/'+ blogId" routerLinkActive="activeClass"><i
                                class="fa fa-calendar-times-o" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Delete Blog</span></li> -->
                </ul>
        </div>
</div>
</div>