<div class="container-fluid">
    <div class="row">
        <div class="col-md-6">
            image
        </div>
        <div class="col-md-6">
            <div class="row text-center">
                <div class="col-12">
                    HARVARD SCHOOL LOGO
                    <P>{{notifyMsg}}</P>
                    <P class="redText">{{notifyMail}}</P>
                </div>
            </div>
            <hr>
            <div class="row mt-10">
                <div class="col-12 text-center">
                    <h1> {{heading}}</h1>
                </div>
                <div class="col-12">
                    <form [formGroup]="createPwForm" (ngSubmit)="onSubmit(createPwForm.value)">
                        <div class="row justify-content-center">
                            <div class="col-md-8 pt-2">
                                <label for="newPassword">Enter Password</label>:
                                <div class="input-container clsControl form-control">
                                    <input pInputText name="newPassword" id="newPassword"  [type]="passwordType"
                                        class="clsControl form-control inputField" formControlName="newPassword"
                                        placeholder="Enter New Passsword" />
                                        <p-button class="eyeIcon" aria-label="Show Password" tabindex="0"
                                        *ngIf="showeye === true" (click)="togglepw()">
                                        <span class="p-viewer2">
                                            <i class="pi pi-eye eyeicon" style="cursor: pointer;padding-right: 10px;"
                                                aria-hidden="true"></i>
                                        </span>
                                    </p-button>
                                    <p-button class="eyeIcon" aria-label="Hide Password" tabindex="0"
                                        *ngIf="hideeye === true" (click)="togglepw()">
                                        <span class="p-viewer2">
                                            <i class="pi pi-eye-slash eyeicon"
                                                style="cursor: pointer;padding-right: 10px;" aria-hidden="true"></i>
                                        </span>
                                    </p-button>
                                </div>
                                <div class="error"
                                    *ngIf="!createPwForm?.controls['newPassword']?.valid&&createPwForm?.controls['newPassword']?.dirty">
                                    <span
                                        *ngIf="createPwForm?.controls['newPassword']?.errors['required']">{{globalResource.passwordRequired}}</span>
                                    <span
                                        *ngIf="createPwForm?.controls['newPassword']?.errors['pattern']">{{globalResource.passwordInValid}}</span>
                                </div>
                            </div>
                            <div class="col-md-8 pt-2">
                                <label for="confirmPassword">Confirm Password</label>:
                                <div class="input-container clsControl form-control">
                                    <input pInputText name="confirmPassword" id="confirmPassword" [type]="confirmPasswordType"
                                        class="clsControl form-control inputField" formControlName="confirmPassword"
                                        placeholder="Confirm Passsword" />
                                        <p-button class="eyeIcon" aria-label="Show Password" tabindex="0"
                                        *ngIf="showConfirmeye === true" (click)="toggleConfirmpw()">
                                        <span class="p-viewer2">
                                            <i class="pi pi-eye eyeicon" style="cursor: pointer;padding-right: 10px;"
                                                aria-hidden="true"></i>
                                        </span>
                                    </p-button>
                                    <p-button class="eyeIcon" aria-label="Hide Password" tabindex="0"
                                        *ngIf="hideConfirmeye === true" (click)="toggleConfirmpw()">
                                        <span class="p-viewer2">
                                            <i class="pi pi-eye-slash eyeicon"
                                                style="cursor: pointer;padding-right: 10px;" aria-hidden="true"></i>
                                        </span>
                                    </p-button>
                                </div>
                                <div class="error"
                                    *ngIf="!createPwForm?.controls['confirmPassword']?.valid&&createPwForm?.controls['confirmPassword']?.dirty">
                                    <span
                                        *ngIf="createPwForm?.controls['confirmPassword']?.errors['required']">{{globalResource.passwordRequired}}</span>
                                    <span
                                        *ngIf="createPwForm?.controls['confirmPassword']?.errors['pattern']">{{globalResource.passwordInValid}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-6 col-8 pt-2 termsConditionsBlock">
                                 <input type="checkbox"  name="termsAndConditions" id="termsAndConditions"  formControlName="termsAndConditions" role="text" tabindex="0">
                                 <span for="confirmPassword">I agree to the Terms of Use and Privacy Policy</span>
                            </div>
                        </div>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-6 col-8 pt-2">
                                <button aria-label="Continue" tabindex="0" type="submit" class="submit_Btn">Continue</button>
                            </div>
                        </div>
                        <div class="row justify-content-center pt-3">
                            <div class="col-md-8 pt-2">
                                <p>In order to protect your account, make sure your password:</p>
                            </div>
                            <div class="col-md-8">
                                <ul>
                                    <li>Is longer than 8 characters</li>
                                    <li>Include both lower and upper case letters</li>
                                    <li>Include atleast one number</li>
                                    <li>Include atleast one special character</li>
                                    <li>Password should not start with a number or _(underscore)</li>
                                </ul>
                            </div>
                            <!-- <div class="col-12">
                                <p>Is longer than 8 characters</p>
                            </div>
                            <div class="col-12">
                                <p>Include both lower and upper case letters</p>
                            </div> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>