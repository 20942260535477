<div class="container-fluid pb-5">
    <div class="row">
        <h4 class="burgendy fw-semibold py-3"> Tickets Issued </h4>
        <div class="row">
            <div class="col-12 col-md-12">
        <div class=" rounded" *ngIf="!loader">
            <p-table #tickets [value]="ticketissued" sortMode="single" [responsive]="true" [rows]="10" 
            sortOrder="-1" [paginator]="true"  [globalFilterFields]="['buyerName','orderId','ticketCode','issuedDate','ticketName','status']" >
            <ng-template pTemplate="caption">
                <div class="row align_inline flex-row-reverse">
                    <div class="col-12 col-md-4 d-flex">
                        <input type="text" class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2" autocomplete="off" 
                        (input)="tickets.filterGlobal($event.target.value, 'contains')"  pInputText size="50" placeholder=" Ex: Search Events..."
                       style="height: 2.25rem;font-size: 13px;
                        border-radius: 5px 0px 0px 5px;">
                        <div class="input-group-prepend filter-styles">
                        <span class="input-group-text" style="background: #99A7BA;
                        border: none;">
                        <i class="fa fa-search"  style="margin:4px 4px 0 0;color: white;"></i>
                        </span>
                    </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'buyerName'" class="fw-semibold">Name<p-sortIcon [field]="'buyerName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'orderId'" class="fw-semibold">Order ID <p-sortIcon [field]="'orderId'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'ticketCode'" class="fw-semibold">Ticket Code<p-sortIcon [field]="'ticketCode'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'issuedDate'" class="fw-semibold">Issued Date<p-sortIcon [field]="'issuedDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'ticketName'" class="text-center fw-semibold">Ticket Name<p-sortIcon [field]="'ticketName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'status'" class="text-center fw-semibold">Status<p-sortIcon [field]="'status'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ticket let-rowData let-columns="columns" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                    <td class="custom_width__ForTextWrap">{{ticket?.buyerName }} </td>      
                    <td  class="text-align">{{ticket?.orderId}} </td> 
                    <td class="text-align">{{ticket?.ticketCode}} </td>     
                    <td class="text-align">{{ticket?.issuedDate}} </td>    
                    <td class="text-align">{{ticket?.ticketName}}  </td>   
                    <td class="text-align">{{ticket?.status}}</td>         
                </tr>
            </ng-template>
        </p-table>

        </div>
        <div class="text-center" *ngIf="loader">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
        </div>
    </div>
 </div><p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
 <p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>