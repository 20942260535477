import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-public-view',
  templateUrl: './news-public-view.component.html',
  styleUrls: ['./news-public-view.component.css'],
})
export class NewsPublicViewComponent implements OnInit {
  newsList:any;
  selectedNews:any=[];
  tooltipMessage = 'Copy';
  showMessage: boolean = false;
  tooltipVisible = false;
  selectedNewsId:any;
  copyText=window.location.origin;
  detailNewsLoader: boolean = false;
  mostRecentNewsLoader: boolean = false;
  constructor(
    private router: Router, 
    private loaderService: LoaderService, 
    private NewsDetails: NewsService, 
    private titleService: Title,
    private route: ActivatedRoute,
    private appinsightsService: LoggingService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  private loginService: LoginService,
) {}

  ngOnInit(): void {
    try {
      this.appinsightsService.logEvent('News list public deatil view screen visited')
      this.titleService.setTitle('News Detail');
      this.route.params.forEach((urlParams) => {
        this.selectedNewsId = urlParams['id'];
      });
      this.getNewsList();
      this.sendViewData();
      if(this.selectedNewsId != null){
        this.getSingleNews(this.selectedNewsId);
      }
    } catch (error) {
      this.appinsightsService.logException(error,2)
    }
  }

  sendViewData(){
    try{
      this.NewsDetails.updateViews("News", this.selectedNewsId , true).subscribe((data)=>{
        console.log(data)
      },(error:any)=>{
        if(error.status >= 400 && error.status < 500 ){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else{

        }
      })
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }

  getNewsList() {
    try {
      this.mostRecentNewsLoader = true;
      this.NewsDetails.getnewslists(5,false).subscribe((data) => {
        this.mostRecentNewsLoader = false;
        this.newsList = data.result;
      },(error)=>{
        this.mostRecentNewsLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in news list api", {payload: JSON.stringify(0),error:JSON.stringify(error)})
        if(error.status >= 400 && error.status < 500 ){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
  
      });
    } catch (error) {
      this.mostRecentNewsLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  getSingleNews(id){
    try{
      this.detailNewsLoader = true;
      this.NewsDetails.getSingleNews(id,true).subscribe((data)=>{
        if(data.result){
          this.detailNewsLoader = false;
        this.selectedNews = data.result;
        this.selectedNews.description =  this.selectedNews.description.replaceAll('"','')
        }
      },(error)=>{
        this.detailNewsLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in single news api", {payload: JSON.stringify(id),error:JSON.stringify(error)})
        if(error.status >= 400 && error.status < 500 ){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      })
    }
    catch(error){
      this.detailNewsLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  onSelectNews(news:any){
    try{
      this.selectedNewsId = news.id;
      this.getSingleNews(news.id)
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }
  navigateToNews() {
    this.router.navigate(['/News']);
  }
  copyNews() {
    try{
    this.showMessage = true;
    const textToCopy = this.copyText+ '/News/' +this.selectedNewsId;
    const tempInput = document.createElement("textarea");
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); 
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.tooltipMessage = 'Copied!';
    this.tooltipVisible = true;
    this.messageService.add({
      severity:'success', 
      detail: 'Link copied'
    });
    setTimeout(() => {
      this.tooltipVisible = false;
      this.tooltipMessage = 'Copied';
    }, 1500);
  }
  catch(error:any){
    this.appinsightsService.logException(error,2)
  }
}

}
