
<div class="row m-0 justify-content-center pt-3">
        <div class="col p-0">
                <ul class="text-white list-unstyled">
                        <h5 class="hide_textMenu color_darkCloud fw-bold" style="font-size: 17px;">{{heading}}</h5>
                        <li class="menu cursor-pointer" routerLink="/abc" routerLinkActive="activeClass"><i
                                class="bi bi-speedometer2" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Dashboard</span></li>
                        <h5 class="hide_textMenu color_darkCloud fw-bold" style="font-size: 17px">{{usersHeading}}</h5>
                        <li class="menu cursor-pointer" routerLink="/adminDashboard/uploadExcel" routerLinkActive="activeClass"><i
                                class="bi bi-upload" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Upload Users</span></li>
                        <li class="menu cursor-pointer"  routerLink="/adminDashboard/users" routerLinkActive="activeClass"><i
                                class="bi bi-person-fill" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Users</span></li>
                        <li class="menu cursor-pointer" routerLink="/adminDashboard/stageUsers" routerLinkActive="activeClass"><i
                                class="bi bi-speedometer2" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Invited Users</span></li>
                        <h5 class="hide_textMenu color_darkCloud fw-bold" style="font-size: 17px">{{eventHeading}}</h5>
                        <li class="menu cursor-pointer" routerLink="/adminDashboard/createEvent" routerLinkActive="activeClass"><i
                                class="bi bi-calendar-event" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Create Event</span></li>
                        <li class="menu cursor-pointer" routerLink="/adminDashboard/eventsList" routerLinkActive="activeClass"><i
                                class="bi bi-speedometer2" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Events List</span></li>
                </ul>
        </div>
</div>