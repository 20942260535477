

<div class="container-fluid p-0 pb-5">
    <p-toast position="top-center"></p-toast>
    <div class="row mx-md-3 p-0 m-0">
        <div class="col-12">
            <div class="row d-md-flex align-items-center justify-content-between px-md-5 pt-3">
                <div class="col-12 col-md-8 align_inline">
                    <h2 class="fw-semibold"> Edit News</h2>
                </div>
                <div class="col-12 col-md-4 justify-content-end p-0  pb-4 ">
                        <p-button label="Delete" class="transparent_btn" (click)="showDeleteDialog=true"></p-button>
                        <p-button label="Preview" icon="fa fa-eye" iconPos="left" class="maroon_btn col-12"
                        (click)="displayPreviewDialog = true"></p-button>  
                </div>
            </div>
        </div>
           <p-dialog [modal]="true" [draggable]="false"  [resizable]="false" class="addUserPopup" [(visible)]=" showDeleteDialog"
           closable="false" [style]="{'width': '100%', 'max-width': '600px'}" >
           <div class="row  ">
                   <div class="col-12 col-md-12 pb-5">
                       <h4 class="burgendy fw-semibold pl-2">Are you sure you want to delete this News?</h4>
                   </div>
                   <div class="col-12 col-md-12 d-flex align-item-center justify-content-end bg-light-ash   ">
                       <p-button class="m-2 cancel_Btn" label="Cancel" type="button" (click)="showDeleteDialog = false"></p-button>
                       <p-button class="m-2 primeNgButton" label="Delete" type="button" (click)="deleteNews()"></p-button>
                   </div>
           </div>
       </p-dialog> 
       <p-dialog *ngIf="this.newsedit.valid" [contentStyle]="{'overflow': 'auto', 'overflow-x': 'hidden', 
       'max-height': '80vh','min-height':'250px'}" class="custom-dialog" header="Preview"   [(visible)]="displayPreviewDialog" [style]="{ width: '80%' , height:'70%' }">
         <div class="row pt-3 px-md-5">
            <div class="col-12 px-md-5">
                <div>
                    <h3 class="burgendy fw-semibold">{{this.newsedit['controls']['newsHeading'].value}}</h3>
                </div>
                <div>
                    <div class="d-md-flex align-items-center py-2">
                        <div class="py-1">
                            <i class="fa fa-user-circle-o creamy rounded-circle p-2"></i><span class="burgendy fw-semibold px-2 mr-2">{{this.newsedit['controls']['department'].value}}</span>
                        </div>
                        <div class="py-1">
                            <i class="fa fa-calendar p-2"></i><span class="px-2">{{currentDate | date:'MMM dd yyyy'}}</span>
                        </div>
                    </div>
                    <div class="col-12 position-relative w-100 p-0">
                        <img alt="Card" [src]="postImage"  alt="News-image"
                        class="w-100 h-100 img-fixed-size" />
                    </div>
                    <div class="py-3">
                        <p-editor class="pEditor__Readonly w-100" [(ngModel)]="newsedit['controls']['description'].value"
                            [readonly]="true"></p-editor>
                    </div>
                </div>
            </div>
         </div>
       </p-dialog>
    </div>

    <div class="p-3 rounded-4 bg-white custom_border mx-md-5 pb-5 mt-3" *ngIf="!loader ">
        <div class="row">
            <div class="col">     
                <form [formGroup]="newsedit" >
                    <div class="row">
                        <div class="col">
                            <label class="fs-6 py-2" for="newsHeading">News <span class="star">*</span></label>:
                            <input pInputText
                                name="newsHeading"
                                id="newsHeading"
                                type="text"
                                autocomplete="off"
                                class="clsControl form-control"       
                                formControlName="newsHeading"
                                placeholder="heading... "
                               

                            > 
                            <div class="error" *ngIf="newsedit.controls['newsHeading'].invalid && newsedit.controls['newsHeading'].dirty">
                                <span *ngIf="newsedit.controls['newsHeading'].errors['required']">Heading Required</span>
                              </div>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="fs-6 py-2" for="department">Department<span class="star">*</span></label>:
                            <input pInputText
                                name="department"
                                id="department"
                                type="text"
                                autocomplete="off"
                                class="clsControl form-control"       
                                formControlName="department"
                                placeholder="Enter department Name "
                               
                            >
                            <div class="error" *ngIf="newsedit.controls['department'].invalid && newsedit.controls['department'].dirty">
                                <span *ngIf="newsedit.controls['department'].errors['required']">Department name is Required</span>
                              </div>
                           
                        </div>
                        <div class="col-12">
                            <label class="fs-6 py-2" for="description">Description <span class="star">*</span>
                            </label>:
                            <p-editor  name="description"  id="description" formControlName="description" [style]="{'height':'320px'}"></p-editor>
                            <div class="error"
                                *ngIf="newsedit.controls['description'].invalid && newsedit.controls['description'].dirty">
                                <span *ngIf="newsedit.controls['description'].errors['required']">Description
                                    Required</span>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <p >Choose or upload a banner image:<span class="star">*</span></p>
                            <input #fileInput type="file" id="fileInput"  accept="image/png, image/jpeg" style="display: none" formControlName="image"
                            (change)="onFileSelected($event) ">
                            
                            <div class="d-flex align-items-center flex-column justify-content-center custom_css_uploadImage" (click)="browseFiles()">
                                <p class="mt-3">Upload <br>(Max 1MB) <br>600X400 Px </p>
                            </div>
                              <div *ngIf="fileSizeWithinLimit == false" class="error">
                                <span>Image size exceeds the limit of 1MB.</span>
                            </div>

                            <div class="row p-3">
                                <div class="col-md-4 col-xs-2"><img *ngIf="postImage" [src]="postImage" class="img-fluid image-css" alt="Preview" /></div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-start  pt-3 cursor-pointer"(click)="revert()">
                            <i class="fa fa-plus burgendy p-3"> Revert Changes</i>
                            </div>
                        <div class="col d-flex justify-content-end align-items-center pt-3" *ngIf="showActionButtons">
                            <p-button class="saveBtn mr-2 event__btn"  (click)="onSubmit(newsedit.value,false)" label="Save" type="button" ></p-button>
                            <p-button class="primeNgButton    publishBtn ml-2" (click)="onSubmit(newsedit.value,true)"     label="Publish" type="button" ></p-button>
                            <p-progressSpinner *ngIf="editLoader"
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
                        </div> 
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="text-center" *ngIf="loader">
        <p-progressSpinner
        styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
        animationDuration=".5s"></p-progressSpinner>
    </div>
</div>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"   label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>
