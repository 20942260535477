<p-dialog [(visible)]="displayPreviewDialog" modal="true" responsive="true" [style]="{'width': '100%', 'max-width': '600px'}">
    <div class="container-fluid p-0">
        <p-toast position="top-center"></p-toast>
        <div class="m-3">
            <div class="px-3 pb-4 pt-0">
                <h4 class="burgendy fw-semibold">Are you sure you want to delete this event?</h4>
            </div>
        </div>
        <div class="d-flex align-items-end custom_button flex-row-reverse">
            <p-button class="m-2 primeNgButton" label="Delete" type="button" (click)="deleteEvent()"></p-button>
            <p-button class="m-2 cancel_Btn" label="Cancel" type="button" (click)="navigateBackToList()"></p-button>
        </div>
    </div>
</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<p-confirmDialog key="toastMsg" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>