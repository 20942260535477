import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoaderService } from 'src/app/services/loader.service';
@Component({
  selector: 'app-blog-create',
  templateUrl: './blog-create.component.html',
  styleUrls: ['./blog-create.component.css']
})
export class BlogCreateComponent implements OnInit {
  [x: string]: any;
  displayPreviewDialog: boolean = false;
  blogid: number;
  file: any;
  roles: any[] = [
    { name: 'Admin' },
    { name: 'Teacher' },
    { name: 'Student' },
    { name: 'Faculty' },
  ];
  
 
  createNewBlog: FormGroup;
  @ViewChild('fileInput') fileInput: any;
  postImage:any;
  currentDate = new Date();
  tagInput: string = '';
  tags: string[] = [];
  loader: boolean = false;
  constructor(
    private titleService: Title,
    private BlogDetails:BlogDetailsService,
    private router: Router,
    private fb: FormBuilder, 
    private loginService: LoginService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private appinsightsService: LoggingService,
    private loaderService: LoaderService,
    private loginDetails: LoginService
  ) { }

  ngOnInit(): void {
    try{
      this.appinsightsService.logEvent('Blog creation screen visited')
      this.titleService.setTitle('Create Blog');
      this.route.params.forEach((urlParams) => {
        this.blogid = urlParams['blogid'];
        this.loginService. SelectedblogID.next(this.blogid)
      });
      this.createNewBlog = this.fb.group({
        postName:new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        author:new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        authorType:new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        tags:new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        image:new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        description:new FormControl(null, Validators.compose([Validators.required])),
      })
    }
   catch(error){
    this.appinsightsService.logException(error,2)
   }
  }

  addTag() {
    try{
      const tag = this.createNewBlog['controls']['tags'].value;
      if (tag !== '') {
        this.tags = [...this.tags, tag];
      }
      this.createNewBlog['controls']['tags'].reset();
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }
  removeTag(tag: string) {
    this.tags = this.tags.filter((t) => t !== tag);
  }
  removeAllTags(){
    this.tags = [];
  }
  closePreview() {
    this.displayPreviewDialog = false;
  }

  navigateToBlogList(){
    this.router.navigate(['/adminDashboard/blogs']);
  }

  browseFiles() {
    this.fileInput.nativeElement.click();
  }

async onSubmit(formdata: any,ShouldPublish: boolean) {
  try {
    this.createNewBlog['controls']['tags'].patchValue(this.tags)
    this.loader = true;
    if (this.createNewBlog.valid) {
      const formData: FormData = new FormData();
      formData.append("Title", formdata.postName);
      formData.append("AuthorName", formdata.author);
      formData.append("AuthorType", formdata.authorType.name);
      formData.append("Description", formdata.description);
      formData.append('IsPublished', ShouldPublish.toString());
      formData.append("Tags", this.createNewBlog['controls']['tags'].value);
      formData.append('Image', this.file, this.file.name );
       await this.loginDetails.tokenRenewal();
      await this.BlogDetails.postBlogs(formData).subscribe(
        (data) => {
          if (data.statusCode == 201) {
            this.loader = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Blog created successfully',
          });
          this.tags=[];
          this.createNewBlog.reset();
         } },
        (error: any) => {
          this.loader = false;
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in Blog creation api", {payload: JSON.stringify(formData),error:JSON.stringify(error)})
          if (error.status == 401) {
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key: 'toastMsg',
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          } else if (error.status == 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Something went wrong at server side!',
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.responseException[0],
            });
          }
        }
      );
      this.file = undefined;
      this.postImage = null;
    } else {
      this.loader = false;
      for (const field in this.createNewBlog.controls) {
        this.createNewBlog.controls[field].markAsDirty();
      }
    }
  } catch (error) {
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  
}
onFileSelected(event: any) {
  try{
    const img = this.createNewBlog.controls['image'];
    this.file = event.target.files[0];
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      const reader = new FileReader();
          reader.onload = (event: ProgressEvent) => {
            const result = (<FileReader>event.target).result;
            this.postImage = result as string;
            img.patchValue(this.postImage);
          };
          reader.onloadend = () => {
            const postImage = reader.result as BinaryData;
            img.patchValue(postImage);
           };
           reader.readAsDataURL(event.target.files[0]);
           reader.readAsBinaryString(event.target.files[0]);
        }
  }
  catch(error){
   this.appinsightsService.logException(error,2);
    }
  }

  showPreview(){
    try{
      this.createNewBlog['controls']['tags'].patchValue(this.tags)
      this.displayPreviewDialog = true
    }
    catch(error){
      this.appinsightsService.logException(error,2);
    }
  }
  close(){
    this.createNewBlog['controls']['tags'].reset();
  }
}
