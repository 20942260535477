<div class="container">
    <h2 class="burgendy py-4 fw-semibold">Privacy Policy, Terms and Conditions</h2>

    <h5 class="fw-semibold">Why has the Harvard Business School Club of Seattle built this site?</h5>
    <p class="ash">In its efforts to build the strength of the HBS alumni network in the state of Washington, the Harvard Business School Club of  has provided this site for use by local HBS alumni.</p>
<br>
    <h5  class="fw-semibold">What information do we collect?</h5>
    <p class="ash">HBS Club of Seattle maintains a database with contact information of all known HBS alumni in the Washington area. This database is compiled and maintained through periodic updates from Harvard Business School Alumni Records and local alumni periodically updating their contact information directly to the Club.</p>

    <p class="ash">This database is used by the Club to maintain contact with local alumni through periodic email, send out event fliers, and solicit for annual membership in the Club. Parts of this database can be accessed, similar to the alumni database featured on the HBS alumni site, by paid Club members.</p>

    <p class="ash">Our Terms and Conditions clearly state that Club members shall not transmit "through this site or its email links any content that is libelous, defamatory, obscene, threatening, invasive of privacy or publicity rights, abusive, illegal, discriminatory, or that would constitute or encourage a criminal or civil offense, violate the rights of any party, or that would otherwise give rise to liability or violates any law. In addition, [Club members] may not use the HBSCS website to send unsolicited advertising, promotional material, or other forms of solicitation to other users except those specifically sanctioned by HBSCS. </p>

<br>

    <h5  class="fw-semibold">Will HBSPS disclose the information it collects to outside parties?</h5>
    <p class="ash pb-5">The Harvard Business School Club of Seattle does not and will not sell, trade, share, or rent your personal information to others. Also, HBSCS will not sell, trade, share or rent aggregate statistics about its alumni base.</p>

  </div>
