import { Component, OnInit } from '@angular/core';
import { UploadExcelService } from 'src/app/services/upload-excel.service';
import {stageUser} from './stage-users';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-stage-users',
  templateUrl: './stage-users.component.html',
  styleUrls: ['./stage-users.component.css']
})
export class StageUsersComponent implements OnInit {
  stagedUsersList: stageUser[] = [];
  paginationValues: any;

  constructor(private UploadExcel: UploadExcelService,     private loginService: LoginService,private titleService: Title,private router: Router,private confirmationService: ConfirmationService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.titleService.setTitle('Invited Users');
    this.getStagedUsersList();
  }
  public  getPaginationOptions(): any {
    return [5, 10, 15, 20];
  }
  getStagedUsersList(){
    this.UploadExcel.getStagedUsers().subscribe((data) => {
     this.stagedUsersList = data.result;
     this.stagedUsersList.forEach(x => {
      x.dateInvited = this.datePipe.transform(x?.dateInvited ,'longDate')
     })
     this.paginationValues= this.getPaginationOptions();
    },(error:any)=>{
      if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'toastMsg',
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);;
          },
        })
      }
    })
    if(this.stagedUsersList.length>20)
    {
      let length = Math.ceil(this.stagedUsersList.length / 10) * 10;
      this.paginationValues[this.getPaginationOptions().length] = length;
    }
  }

}
