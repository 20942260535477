// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//export const environment = {
//  production: false,
//  // apiEndpoint:'https://localhost:7158/',
//  apiEndpoint: 'https://c2s-rg-hbsapi.azurewebsites.net/',
//  appInsights: {
//    instrumentationKey: '34888b0e-2b3d-4567-8633-679c33e5727a'
//  }
//};

// Below is a hack for prod deployment
export const environment = {
  production: false,
  apiEndpoint: 'https://hbsseattle-api.azurewebsites.net/',
  appInsights: {
    instrumentationKey: 'f5161453-8bd8-492e-b010-6fd033f49ccc'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
