import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { LoginService } from 'src/app/services/login.service';
import { BlogList } from './blog-list';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';


@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
  blogsList: any;
  blogId: number;
  blog: any;
  blogid: any;
  paginationValues: any;
  selectedBlogForDelete : any;
  showDeleteDialog : boolean = false;
  copyOfBlogsList:any;
  allBlogsOptions: any;
  loader: boolean = false;
  selectedOption: any;
  constructor(
    private titleService: Title,
    private router: Router,
    private loginDetails: LoginService,
    private messageService: MessageService,
    private BlogDetails:BlogDetailsService,
    private confirmationService: ConfirmationService, 
    private loginService: LoginService,
    private appinsightsService: LoggingService,
    private loaderService: LoaderService,
  ) { }
  ngOnInit(): void {
    this.appinsightsService.logEvent('Blog list screen visited')
    this.titleService.setTitle('Blog list');
    this.getBlogsList();
  }
  closeDelete(){
    this.showDeleteDialog = false;
    }

  async getBlogsList(){
    try {
      await this.loginDetails.tokenRenewal();
      this.loader = true;
      await this.BlogDetails.getBlogs(0,false).subscribe(
        (data) => {
          if (data) {
           this.loader = false;
            this.blogsList = data.result;
            this.copyOfBlogsList = this.blogsList;
            this.allBlogsOptions = this.blogsList;
            this.allBlogsOptions = Array.from(this.blogsList.reduce((m, t) => m.set(t.status, t), new Map()).values())
            if (this.blogsList.length > 20) {
              let length = Math.ceil(this.blogsList.length / 10) * 10;
              this.paginationValues[this.getPaginationOptions().length] = length;
            }
            this.allBlogsOptions.push({
              status:"All Blogs"
            })
            this.selectedOption = this.allBlogsOptions[this.allBlogsOptions.length-1]
          }
        
        },
        (error: any) => {
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in  Blog list api", {payload: JSON.stringify(0),error:JSON.stringify(error)})
          this.loader = false;
          if (error.status >= 400 && error.status < 500) {
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key:"sessionExpiry",
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          }
          else if(error.status == 500){
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: "Something went wrong at server side!"
            });
          }
          else{
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: error.error.responseException.message
            });
          }
    
        }
      );
    }  catch(error){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }  
  }
  onDeleteBlog(blogList){
    this.showDeleteDialog = true;
     this.selectedBlogForDelete =blogList;
  }
 async deleteBlog(){
    try {
       await this.loginDetails.tokenRenewal();   
      await this.BlogDetails.deleteBlogDetails(this.selectedBlogForDelete.id).subscribe(
        (data) => {
          try {
            if (data.statusCode == 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Blog deleted successfully'
              });
              this.showDeleteDialog = false;
              this.getBlogsList();
            }
          } catch (error) {
            console.error('Error occurred in API success response:', error);
          }
        },
        (error: any) => {
          try {
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: error in  Blog delete api in blog list screen", {payload: JSON.stringify(this.selectedBlogForDelete.id),error:JSON.stringify(error)})
            if (error.status == 401) {
              this.confirmationService.confirm({
                message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                key:"sessionExpiry",
                accept: () => {
                  localStorage.clear();
                  this.loginService.SelectedFieldID.next(null);
                  this.router.navigate(['/home']);
                },
              });
            } else if (error.status == 500) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at the server side!'
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: error.error.responseException.message
              });
            }
          } catch (error) {
            this.appinsightsService.logException(error,2)
          }
        }
      );
    } catch (error) {
      this.appinsightsService.logException(error,2)
    }
    
    
  }

  public  getPaginationOptions(): any {
    return [5, 10, 15, 20];
  }
  navigateToCreateBlog(){
    this.router.navigate(['/adminDashboard/createBlog']);
  }

  editblog(blogList){
    localStorage.setItem('SelectedBlogName', blogList.title)
    this.router.navigate(['/adminDashboard/blogSummary/blogedit', blogList.id]);
  }
  blogsOverView(blogList){
    localStorage.setItem('SelectedBlogName', blogList.title)
    this.router.navigate(['/adminDashboard/blogSummary/blogoverview/', blogList.id]);
  }
  filterDataBasedOnSelectedStatus(event:any){
    this.blogsList = this.copyOfBlogsList;
    if( event.value.status.toUpperCase() == 'ALL BLOGS'){
      this.blogsList = this.copyOfBlogsList;
    }
    else{
      this.blogsList = this.blogsList.filter(x => x.status == event.value.status)
    }
  }
}
