import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoginService } from './login.service';


@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(  private http: DataService, private loginDetails: LoginService) { }
  gethome(){
    let url = 'api/Home';
    const headers = this.loginDetails.setHeaders();
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }


  contactUs(contact){
    let url = 'api/Home/SendEmail';
    const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
    'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.post(url,contact,{headers: requestHeader}).pipe(map(data => data))   
  }

  getSummaryDataForAdminDasboard(){
    let url = 'api/Home/AdminDashboard';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
}
