import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Meta, Title  } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login.service';
import { GlobalResources } from 'src/app/services/global-resource/global-resource';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  showLoginPopUp: boolean = false;
  public loginForm: FormGroup;
  public formgotPasswordForm: FormGroup;
  public changePasswordForm: FormGroup;
  passwordType: string = 'password';
  public showeye: boolean = false;
  public hideeye: boolean = true;
  errorMessage: string = '';
  public globalResource: any;
  loader: boolean = false;
  isUserLoggedIn: boolean = false;
  loggedInUserData: any;
  decodedToken: any;
  items: MenuItem[];
  showForgotPwPopUp: boolean = false;
  forgotPasswordError: string ="";
  address1: string=" 2792 Westhimer Rd. Santa Ana, Illion"  ;
  address2: string="  debbie.baker@example.com" ;
  phno: string=" (404)1234 (505)2453"  ;
  selectUserid: number;
  currentRoute: any;
  showLoader: boolean;
  showForgotpasswordForm: boolean = true;
  timerCount: number = 1;
  showChangePassword: boolean = false;
  confirmPasswordType: string ='password';
  confirmPwShoweye:boolean = false;
  confirmPwHideeye: boolean =  true;
  showPasswordValidationError:boolean = false;
  constructor(
    private fb: FormBuilder,
    private titleService: Title,
    private loginService: LoginService,
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private appinsightsService: LoggingService,
    private loginDetails: LoginService,
    private meta :Meta
  ) {}

  ngOnInit(): void {
    this.appinsightsService.logEvent('landing screen visited, it has - menu, footer, login, forgot password and change password screens')
    this.titleService.setTitle('Home');
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
  });
  this.loginService.SelectedFieldID.subscribe((data)=>{
    if(data == null){
      this.isUserLoggedIn =  false;
      this.dynamicMenu();
    }
  })
    this.currentRoute = window.location.href;
    this.isUserLoggedIn = localStorage.getItem('token') &&  this.validateToken(JSON.parse(localStorage.getItem('token'))) ? true : false;
    if(localStorage.getItem('loggedInUser') && this.isUserLoggedIn){
      this.loggedInUserData = JSON.parse(localStorage.getItem('loggedInUser'));
      this.setUserDetails(this.loggedInUserData);
    }
    else if(localStorage.getItem('loggedInUser') ){
      localStorage.clear();
      this.router.navigate(['/home']);
      this.dynamicMenu();
    }
    else{
      localStorage.clear();
      this.dynamicMenu();
    }
    this.globalResource = GlobalResources.getResources().en;
    this.loginForm = this.fb.group({
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      ),
      password: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });

    this.formgotPasswordForm = this.fb.group({
      emailAddress: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      )
    })
    this.changePasswordForm = this.fb.group({
      newPassword: new FormControl(null,  Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
        ),
      ])),
      confirmPassword: new FormControl(null,  Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
        ),
      ]))
    })


    this.loginService.showForgotPasswordPopUp.subscribe((data)=>{
      if(data == true){
        this.showLoginPopUp = false;
        this.showForgotPwPopUp = true;
        this.showForgotpasswordForm = true;
      }
    },(error:any)=>{
      this.appinsightsService.logException(error,2)
    })
  //   const dynamicImageUrl = 'https://hbsseattle.com/assets/images/logo-img.png';
  //   this.meta.updateTag({ property: 'og:image', content: dynamicImageUrl });
  //   this.meta.updateTag({ name: 'title', content: 'testing' });
  // this.meta.updateTag({ name: 'description', content: "Welcome text content." });
    
  }

  validateToken(token){
    try{
    const tokenDetails =   this.loginDetails.decodeToken(token);
    const minutesDifference = this.loginDetails.getTimeDifference(tokenDetails);
      if(minutesDifference<=0){
        return false;
      }
      return true;
    }
    catch(error){
      this.appinsightsService.logException(error,2)
      return false;
    }


  }

  dynamicMenu(){
    if(this.isUserLoggedIn && this.loggedInUserData?.roleId == 1){
      this.items = [
        {
          label: 'Dashboard',
          routerLink: '/adminDashboard/dashBoard', 
        },
        {
          label: 'Users',
          routerLink: '/adminDashboard/users', 
        },
        {
          label: 'Events',
          routerLink: '/adminDashboard/eventsList', 
        },
        {
            label: 'News',
            routerLink: '/adminDashboard/news', 
        },
        {
          label: 'Blogs',
          routerLink: '/adminDashboard/blogs', 
        },
       
        {
          label: '', 
          icon: 'fa fa-user-circle-o',
          items: [
            {label: this.loggedInUserData?.userName},
            {label:'My Profile',
            routerLink: '/My-profile'},
            {label: 'Change Password',
            command: () => {
              this.changePasswordForm.reset();
              this.showChangePassword = true;
            }},
            {
              label: 'Logout',
              command: () => {
                this.logout();
            }
            },
          ]
        },
    ];
    }
    else if((this.isUserLoggedIn && this.loggedInUserData?.roleId != 1)){
      this.items = [
        {
            label: 'Home',
            routerLink: '/home', 
        },
        {
          label: 'About Us',
          routerLink :  '/about-us',
        },
        // {
        //   label: 'Events',
        //   routerLink: '/Events', 
        // },
        {
            label: 'News and Events',
            routerLink: '/News', 
        },
      
        {
          label: 'Blogs',
          routerLink: '/Blogs', 
        },
        {
          label: 'Contact',
          routerLink: '/contactus', 
        },
       
        {
          label: '', 
          icon: 'fa fa-user-circle-o',
          items: [
            {label: this.loggedInUserData?.userName},
            {label:'My Profile',
            routerLink: '/My-profile'},
            {label: 'My Events',
            routerLink:'/My-events'},
            {label: 'Change Password',
            command: () => {
              this.changePasswordForm.reset();
              this.showChangePassword = true;
            }},
            {
              label: 'Logout',
              command: () => {
                this.logout();
            }
            },
          ]
        },
    ];
    }
    else{
      this.items = [
        {
            label: 'Home',
            routerLink: '/home', 
        },
        {
          label: 'About Us',
          routerLink :  '/about-us',
        },
        // {
        //   label: 'Events',
        //   routerLink: '/Events', 
        // },
        {
            label: 'News and Events',
            routerLink: '/News', 
            
        },
      
        {
          label: 'Blogs',
          routerLink: '/Blogs', 
        },
        {
          label: 'Contact',
          routerLink: '/contactus', 
        },
  
        {
          label: 'Login',
          command: () => {
            this.showLoginModel();
        }
        }
    ];
    }
  }
  decodeLoggedInToken(){
    if (this.isUserLoggedIn) {
      this.decodedToken = this.decodeToken(localStorage.getItem('token'));
      const tokenExpDate = new Date(this.decodedToken.exp * 1000);
      const currentDate = new Date();
      if(this.decodedToken.Lefa && tokenExpDate > currentDate){
        this.getLoggedInUserData(this.decodedToken.Lefa);
      }
      else{
        this.logout();
      }
    }
  }

  getLoggedInUserData(lefa){
    this.loginService.loggedInUserData(lefa).subscribe((data)=>{
      this.loggedInUserData = data.result;
      this.selectUserid = this.loggedInUserData[0];
      localStorage.setItem("loggedInUser",JSON.stringify( this.loggedInUserData));
      setTimeout(()=>{
        this.navigateToHome();
      },1000)
      this.setUserDetails(this.loggedInUserData);
    },(error:any)=>{
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Unable to login!',
      });
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: Getting error in fetching loggedin user data api", {error:JSON.stringify(error)})
    })
  }
  setUserDetails(userDara){
    this.loggedInUserData.userName = userDara?.firstName+ ' '+ userDara?.lastName
    this.dynamicMenu();
  }
  decodeToken(token: string): any {
    try {
      const decodedToken = jwt_decode(token);
      return decodedToken;
    } catch (error) {
      this.appinsightsService.logException(error,2)
      this.appinsightsService.logEvent("Error: Getting error in decoding token", {error:JSON.stringify(error)})
      return null;
    }
  }
  showLoginModel() {
    this.showLoginPopUp = true;
    this.passwordType = 'text';
    this.loginForm.reset();
    this.formgotPasswordForm.reset();
    this.togglepw();
  }
  public togglepw() {
    if (this.passwordType != 'text') {
      this.passwordType = 'text';
      this.showeye = true;
      this.hideeye = false;
    } else {
      this.passwordType = 'password';
      this.showeye = false;
      this.hideeye = true;
    }
  }

  onSubmit(formData: { email: string; password: string }) {
    if (this.loginForm.valid) {
      this.loader = true;
      let loginDetails = {
        email: formData.email,
        password: formData.password,
      };
      this.loginService.login(loginDetails).subscribe(
        (data) => {
          this.loader = false;
          if (data) {
            this.reset();
            localStorage.setItem('token', JSON.stringify(data.result));
            // this.loginService.SelectedFieldID.next(data.result)
            this.decodedToken = this.decodeToken(localStorage.getItem('token'));
            this.getLoggedInUserData(this.decodedToken.Lefa);
          }
        },
        (error: any) => {
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in login api", {payload: JSON.stringify(loginDetails),error:JSON.stringify(error)})
          this.loader = false;
          this.errorMessage = error.error.title;
        }
      );
    } else {
      this.loginForm.controls['email'].markAsDirty();
      this.loginForm.controls['password'].markAsDirty();
    }
  }

  closeDialog() {
    this.showLoginPopUp = false;
    this.showForgotPwPopUp = false;
    this.showForgotpasswordForm = false;
    this.showChangePassword = false;
    this.loader = false;
    this.loginForm.reset();
    this.errorMessage = '';
  }
  closeForgotPwDialog(){
    this.timerCount = 1;
    this.forgotPasswordError = "";
    this.showForgotpasswordForm = true;
    this.showForgotPwPopUp = false;
  }
  revertLoginFor(){
    this.showForgotPwPopUp = false;
    this.showLoginPopUp = true;
    this.loader = false;
    this.loginForm.reset();
    this.errorMessage = '';
  }
  reset() {
    this.showLoginPopUp = false;
    this.loader = false;
    this.loginForm.reset();
    this.errorMessage = '';
    this.isUserLoggedIn = true;
  }
  logout() {
    this.isUserLoggedIn = false;
    localStorage.clear();
    this.loginService.SelectedFieldID.next(null)
    this.showLoginPopUp = false;
    this.loader = false;
    this.dynamicMenu();
    this.loginForm.reset();
    this.errorMessage = '';
    this.router.navigate(['/home']);
  }

  navigateToAdminDashboard(){
    this.router.navigate(['/adminDashboard/uploadExcel'])
  }
  navigateToUsers(){
    this.router.navigate(['/adminDashboard/users'])
  }
  navigateToEventsList(){
    this.router.navigate(['/adminDashboard/eventsList'])
  }
  showForgotPassword(){
    this.formgotPasswordForm.reset();
    this.showLoginPopUp = false;
    this.showForgotPwPopUp = true;
    this.showForgotpasswordForm = true;
  }
  onForgotPasswordFormSubmit(formdata:any){
    try{
      if(this.formgotPasswordForm.valid){
        this.loader = true;
        this.loginService.forgotPassword(formdata.emailAddress).subscribe((data)=>{
          this.loader = false;
          this.showForgotpasswordForm = false;
          this.timerCount = 1;
          let timer = setInterval(()=>{
            if(this.timerCount === 5){
              clearInterval(timer);
              this.closeForgotPwDialog();
          }
          ++this.timerCount;
          },1000)
        },(error:any)=>{
          this.loader = false;
          this.showForgotpasswordForm = true;
          this.forgotPasswordError =error.error.title
          if (error.status == 401) {
            this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in forgot password email api", {payload: JSON.stringify(formdata.emailAddress),error:JSON.stringify(error)})
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key: 'toastMsg',
              accept: () => {
                localStorage.clear();
                this.showChangePassword = false;
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          }
        })
      }
      else{
        this.formgotPasswordForm.controls['emailAddress'].markAsDirty();
      }
    }
    catch(error:any){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  public toggleConfirmPw() {
    if (this.confirmPasswordType != 'text') {
      this.confirmPasswordType = 'text';
      this.confirmPwShoweye = true;
      this.confirmPwHideeye = false;
    } else {
      this.confirmPasswordType = 'password';
      this.confirmPwShoweye = false;
      this.confirmPwHideeye = true;
    }
  }
  validatePassword(event:any){
    if(this.changePasswordForm.controls['newPassword'].value != this.changePasswordForm.controls['confirmPassword'].value)
    {
        this.showPasswordValidationError = true;
    }
    else{
      this.showPasswordValidationError = false;
    }
  }
  onChangePassword(formData:any){
    try{
      if(this.changePasswordForm.valid){
       let changePasswordObj = {
          "password": formData.newPassword,
          "confirmPassword": formData.confirmPassword
        }
        this.loader = true;
        this.loginService.changePassword(changePasswordObj).subscribe((data)=>{
          this.loader = false;
            if(data.statusCode == 200){
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Password updated successfully',
              });
              this.showChangePassword = false;
              this.changePasswordForm.reset();
            }
        },(error)=>{
          this.loader = false;
          if (error.status == 401) {
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: Getting error in change password api", {payload: JSON.stringify(changePasswordObj),error:JSON.stringify(error)})
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key: 'toastMsg',
              accept: () => {
                localStorage.clear();
                this.showChangePassword = false;
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          } else if (error.status == 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Something went wrong at server side!',
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.responseException[0],
            });
          }
          this.changePasswordForm.reset();
        })
      }
      else{
        this.changePasswordForm.controls['newPassword'].markAsDirty();
        this.changePasswordForm.controls['confirmPassword'].markAsDirty();
      }
    }
    catch(error:any){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  navigateToHome(){
    if( this.loggedInUserData?.roleId == 1){
      this.router.navigate(['/adminDashboard/dashBoard']);
    }
    else
    {
      this.router.navigate(['/home']);
    }
  }
}
