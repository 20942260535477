import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

// const baseUrl = environment.apiEndpoint;
const baseUrl = environment.apiEndpoint;

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  get(url, params?: any): Observable<any> {
    return this.http.get<any>(baseUrl + url, params);
  }
  post(url, body?, header?): Observable<any> {
    return this.http.post<any>(baseUrl + url, body, header);
  }
  postWithOutBody(url, header): Observable<any> {
    return this.http.post<any>(baseUrl + url, header);
  }

  patch(url, body): Observable<any> {
    return this.http.patch<any>(baseUrl + url, body);
  }

  put(url, body, header): Observable<any> {
    return this.http.put<any>(baseUrl + url, body, header);
  }
  putWithoutBody(url, header): Observable<any> {
    return this.http.put<any>(baseUrl + url, header);
  }
  putWithOutBody(url, header): Observable<any> {
    return this.http.put<any>(baseUrl + url, header);
  }

  delete(url, params?: any): Observable<any> {
    return this.http.delete<any>(baseUrl + url, params);
  }
}
