
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { catchError, map, retry } from 'rxjs/operators';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggingService } from './logging.service';

const baseUrl = environment.apiEndpoint;

@Injectable()
export class LoginService {
  SelectedFieldID: any = new Subject<any>();
  SelectednewsID: any = new Subject<any>();
  SelectedblogID: any = new Subject<any>();
  SelectedEventId: any = new Subject<any>();
  SelectedEventName: any = new Subject<any>();
  showForgotPasswordPopUp: any = new Subject<any>();
  constructor(
    private http: DataService,
    private appinsightsService: LoggingService
  ) {}
  private headers = new Headers({
    'Content-Type': 'application/json',
  });

  login(loginData) {
    let url = 'api/Login';
    return this.http
      .post(url, loginData, this.headers)
      .pipe(map((data) => data));
  }

  loggedInUserData(lefa) {
    let url = `api/Users/${lefa}`;
    const requestHeader = new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: '*/*',
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
    });
    return this.http
      .get(url, { headers: requestHeader })
      .pipe(map((data) => data));
  }

  forgotPassword(lefa) {
    let url = `api/Login/SendForgotPasswordEmail/${lefa}`;
    return this.http
      .postWithOutBody(url, this.headers)
      .pipe(map((data) => data));
  }

  changePassword(updatedPw) {
    let url = 'api/Login/ChangePassword';
    const requestHeader = new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: '*/*',
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
    });
    return this.http
      .post(url, updatedPw, { headers: requestHeader })
      .pipe(map((data) => data));
  }

  forgotPasswordUpdate(forgotPasswordObj, userId) {
    let url = `api/Login/ForgotPassword?userId=${userId}`;
    return this.http
      .post(url, forgotPasswordObj, this.headers)
      .pipe(map((data) => data));
  }
  getRenewedToken(token) {
    let url = `api/Login/renew?token=${token}`;
    return this.http.post(url).pipe(map((data) => data));
  }

  async tokenRenewal() {
    try {
      const value: any = localStorage.getItem('token');
      if (value != null || value != undefined) {
        let token = value ? JSON.parse(value) : '';
        let decodedToken = this.decodeToken(token);
        const minutesDifference = this.getTimeDifference(decodedToken);
        if (minutesDifference <= 5 && minutesDifference > 0) {
          const data = await await this.getRenewedToken(token).toPromise();
          localStorage.setItem('token', JSON.stringify(data.result));
          this.appinsightsService.logEvent(
            'Info: Received renewal token successfully'
          );
          return;
        }
        return;
      } else {
        return;
      }
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
      return;
    }
  }

  getTimeDifference(decodedToken) {
    const tokenExpDate = new Date(decodedToken.exp * 1000);
    const currentDate = new Date();
    let difference = tokenExpDate.getTime() - currentDate.getTime();
    const minutesDifference = Math.floor(difference / (1000 * 60)); // Convert to minutes
    return minutesDifference;
  }
  decodeToken(token: string): any {
    try {
      const decodedToken = jwt_decode(token);
      return decodedToken;
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
      return null;
    }
  }

  setHeaders() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
  }
}
