<div class="container-fluid pb-5">
    <p-toast position="top-center"></p-toast>
    <div class="row mt-3 mx-md-5 p-3">
        <div class="col-12  align_inline">
            <h2 class="burgendy fw-semibold">Add News</h2>
            <div (click)="navigateToNewsList()">
                <i class="fa fa-arrow-left p-2 burgendy cursor-pointer"></i><span class="burgendy cursor-pointer">Back
                    to News</span>
            </div>
        </div>
    </div>
    <hr>
    <div class="row  mx-md-5 ">
        <span class="col-12  align_inline">
            <div class="col-md-10"></div>
            <div class="col-md-2 p-0  pb-4  text-end">
                <p-button label="Preview" icon="fa fa-eye" iconPos="left" class="maroon_btn col-12"
                    (click)="displayPreviewDialog = true"></p-button>
            </div>
        </span>
    </div>
    <p-dialog *ngIf="this.addNewNews.valid" [contentStyle]="{'overflow': 'auto', 'overflow-x': 'hidden', 
    'max-height': '80vh','min-height':'250px'}" class="custom-dialog" header="Preview"
        [(visible)]="displayPreviewDialog" [style]="{ width: '80%' , height:'70%' }">
        <div class="row pt-3 px-md-5">
            <div class="col-12">
                <h1 class="burgendy fw-semibold px-md-3">News</h1>
            </div>
            <div class="col-12 px-md-5">
                <div>
                    <h3 class="burgendy fw-semibold">{{this.addNewNews['controls']['newsHeading'].value}}</h3>
                </div>
                <div>
                    <div class="d-md-flex align-items-center py-2">
                        <div class="py-1">
                            <i class="fa fa-user-circle-o creamy rounded-circle p-2"></i><span class="burgendy fw-semibold px-2 mr-2">{{this.addNewNews['controls']['department'].value}}</span>
                        </div>
                        <div class="py-1">
                            <i class="fa fa-calendar p-2"></i><span class="px-2">{{currentDate | date:'MMM dd yyyy'}}</span>
                        </div>
                    </div>
                    <div class="col-12 position-relative w-100 p-0">
                        <img alt="Card" [src]="postImage"  alt="News-image"
                        class="w-100 h-100 img-fixed-size" />
                    </div>
                    <div class="py-3">
                        <p-editor class="pEditor__Readonly w-100" [(ngModel)]="addNewNews['controls']['description'].value"
                            [readonly]="true"></p-editor>
                    </div>
                </div>
            </div>
         </div>
    </p-dialog>
    <div class="p-3 rounded-4 bg-white custom_border mx-md-5 my-md-5 py-5">
        <div class="row ">
            <div class="col">
                <form [formGroup]="addNewNews">
                    <div class="row">
                        <div class="col-12">
                            <label class="fs-6 py-2" for="newsHeading">News Heading<span class="star">*</span></label>:
                            <input pInputText name="newsHeading" id="newsHeading" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="newsHeading" placeholder="heading... ">
                            <div class="error"
                                *ngIf="addNewNews.controls['newsHeading'].invalid && addNewNews.controls['newsHeading'].dirty">
                                <span *ngIf="addNewNews.controls['newsHeading'].errors['required']">Heading
                                    Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="fs-6 py-2" for="department">Department<span class="star">*</span></label>:
                            <input pInputText name="department" id="department" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="department"
                                placeholder="Enter department Name ">
                            <div class="error"
                                *ngIf="addNewNews.controls['department'].invalid && addNewNews.controls['department'].dirty">
                                <span *ngIf="addNewNews.controls['department'].errors['required']">Department name is
                                    Required</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <label class="fs-6 py-2" for="description">Description<span class="star">*</span></label>:
                            <p-editor name="description" id="description" formControlName="description"
                                [style]="{'height':'320px'}"></p-editor>
                                <div class="error"
                                *ngIf="addNewNews.controls['description'].invalid && addNewNews.controls['description'].dirty">
                                <span *ngIf="addNewNews.controls['description'].errors['required']">Description
                                    Required</span>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <p>Choose or upload a banner image:<span class="star">*</span></p>
                            <input #fileInput type="file" id="fileInput" accept="image/png, image/jpeg"
                                style="display: none" (change)="onFileSelected($event)">
                            <div class="d-flex align-items-center flex-column justify-content-center custom_css_uploadImage"
                                (click)="browseFiles()">
                                <p class="mt-3">Upload <br>(Max 1MB) <br>600X400 Px</p>
                              
                            </div>
                            <div class="row">
                                <div *ngIf="!fileSizeWithinLimit && this.addNewNews.controls['image'].dirty"
                                    class="error">
                                    <span>Image Required</span>
                                </div>
                                <div class="row p-3 ">
                                    <div class="col-md-4 col-xs-2" *ngIf="postImage !== null"><img [src]="postImage"
                                            class="img-fluid image-css" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center pt-3">
                            <p-button class="saveBtn mr-2 event__btn" (click)="onSubmit(addNewNews.value,false)" label="Save"
                                type="button"></p-button>
                            <p-button class="primeNgButton    publishBtn ml-2" (click)="onSubmit(addNewNews.value,true)"
                                label="Publish" type="button"></p-button>
                            <p-progressSpinner *ngIf="loader"
                                styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>