import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { eventsList } from './events-list';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit {
  eventsList: eventsList[] = [];
  paginationValues: any;
  allEventListOptions:any;
  copyOfEventsList:any;
  loader: boolean = false;
  showDeletePopUp: boolean = false;
  selectedOption: any;
  constructor(
    private titleService: Title,
    private EventDetails:EventDetailsService,
    private confirmationService: ConfirmationService, 
    private loginService: LoginService,
    private messageService: MessageService,
    private router: Router,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event list screen visited')
    this.titleService.setTitle('Events list');
    this.getEventsList();
  }

  public  getPaginationOptions(): any {
    return [5, 10, 15, 20];
  }

 async getEventsList(){
    try{
    this.loader = true;
    await this.EventDetails.getEvents(0).subscribe((data)=>{
      if(data){
        this.loader = false;
        this.eventsList = data.result.data
        this.allEventListOptions = this.eventsList;
        this.allEventListOptions = Array.from(this.eventsList.reduce((m, t) => m.set(t.status, t), new Map()).values());
        this.allEventListOptions.push({
          status:"All Events"
        })
        this.selectedOption = this.allEventListOptions[this.allEventListOptions.length-1]
        this.eventsList.forEach(x => {
          x.location = x.venue?.name;
          x.eventDate = x.next_occurrence_date?.date;
          x.eventCreatedDate = this.datePipe.transform(x.eventCreatedDate,'yyyy-MM-dd');
        })
        this.copyOfEventsList = this.eventsList;
        this.paginationValues= this.getPaginationOptions();
      } 
    },(error:any)=>{   
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting Event list api", {payload: JSON.stringify(0),error:JSON.stringify(error)})
      if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'toastMsg',
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);;
          },
        })
        if(this.eventsList.length>20)
        {
          let length = Math.ceil(this.eventsList.length / 10) * 10;
          this.paginationValues[this.getPaginationOptions().length] = length;
        }
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }
    })
  }
  catch(error:any){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  }

  navigateToCreateEvent(){
    this.router.navigate(['/adminDashboard/createEvent']);
  }

  filterDataBasedOnSelectedStatus(event:any){
    this.eventsList = this.copyOfEventsList;
    if( event.value.status.toUpperCase() == 'ALL EVENTS'){
      this.eventsList = this.copyOfEventsList;
    }
    else{
      this.eventsList = this.eventsList.filter(x => x.status == event.value.status)
    }
  }
  editDelete(event){
    this.showDeletePopUp = true;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      key: 'toastMsg',
      accept: () => {
          this.deleteEvent(event.id)
      },
      reject: () => {
      }
  });
   }

   async deleteEvent(eventId){
    try {
      //  await this.loginDetails.tokenRenewal();
      await this.EventDetails.deleteEventDetails(eventId).subscribe(
        (data) => {
          if (data.statusCode == 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Event deleted successfully',
            });
          }
          this.getEventsList();
        },
        (error: any) => {
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in getting Event delete api", {payload: JSON.stringify(eventId),error:JSON.stringify(error)})
          if (error.status == 401) {
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key: 'toastMsg',
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          } else if (error.status == 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Something went wrong at server side!',
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.title,
            });
          }
        }
      );
    } catch (error) {
      console.error('An error occurred:', error);
    }
    
  }
  editEvent(event){
   localStorage.setItem('selectedEvent', event.name)
    this.router.navigate(['/adminDashboard/eventsSummary/editEvent', event.id]);
  }
  eventOverView(event){
    localStorage.setItem('selectedEvent', event.name)
    this.router.navigate(['/adminDashboard/eventsSummary/eventOverView/', event.id]);
  }
}
