import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { contact } from './contact';
import { HomeService } from '../services/home.service';
import { LoaderService } from '../services/loader.service';
import { MessageService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { LoggingService } from '../services/logging.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  contactform: FormGroup;
  loader: boolean = false;
  constructor(
    private fb: FormBuilder,
    private home: HomeService,
    private messageService: MessageService,
    private titleService: Title, 
    private appinsightsService: LoggingService,
    private loaderService: LoaderService
  ) {}
  ngOnInit(): void {
    this.appinsightsService.logEvent('contact-us screen visited')
  this.titleService.setTitle('Contact');
    this.contactform = this.fb.group({
      cname: new FormControl(null, Validators.compose([Validators.required,Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])),
      cemail: new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])),
      cphone: new FormControl(
        null,
        Validators.compose([Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}')])
      ),
      cmsg: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null),
    });
  }
  sendmsg(formdata: any) {
    try{
    if (this.contactform.valid) {
      this.loader = true;
      let contactobj: contact = {
        name: formdata.cname,
        email: formdata.cemail,
        phone: formdata.cphone,
        message: formdata.cmsg,
      };
      this.home.contactUs(contactobj).subscribe(
        (data) => {
          this.loader = false;
          if(data.statusCode == 200){
            this.messageService.add({
              severity:'success', 
              summary: 'Success', 
              detail: 'Message sent successfully'
            });
            this.contactform.reset();
          }
        },
        (error) => {
          this.loader = false;
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in contact us api", {payload: JSON.stringify(contactobj),error:JSON.stringify(error)})
          if(error.status == 500){
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: "Something went wrong at server side!"
            });
          }
          else{
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: error.error.responseException.message
            });
          }
    
        }
      );
    } else {
      for (const field in this.contactform.controls) {
        this.contactform.controls[field].markAsDirty();
      }
    }
  }
  catch(error){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
}
}
