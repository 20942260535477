import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { newsoverview } from './newsoverviewlist';
import { NewsService } from 'src/app/services/news.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { Title } from '@angular/platform-browser';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-new-overview',
  templateUrl: './new-overview.component.html',
  styleUrls: ['./new-overview.component.css']
})
export class NewOverviewComponent implements OnInit {
  newsId: any;
  newsoverview: any;
  showMessage: boolean = false;
  tooltipVisible = false;
  tooltipMessage = 'Copy';
  newsLink: string;
  loader: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private News: NewsService ,
    private loginService: LoginService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, 
    private loaderService: LoaderService,
    private titleService: Title,
    private appinsightsService: LoggingService,
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('News overview screen visited')
    this.titleService.setTitle('News Overview');
    this.route.params.forEach((urlParams) => {
      this.newsId = urlParams['newsId'];
      this.loginService.SelectednewsID.next(this.newsId)
    });
    if(this.newsId){
      this.getnewsoverview(this.newsId);
    }
  }

async  getnewsoverview(newsId){
    try{
    this.loader = true
    let location = window.location.origin;
    this.newsLink = location + "/News/" + newsId
    localStorage.setItem("selectedNewsId",JSON.stringify(newsId));
    await this.loginService.tokenRenewal();
   await this.News.getnewsoverview(newsId).subscribe((data) => {
      if(data){
        this.newsoverview = data.result;
        this.loader = false
      }
        },(error:any)=>{
          this.loader = false
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in getting news overview api", {payload: JSON.stringify(newsId),error:JSON.stringify(error)})
          if(error.status == 401){
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key:"sessionExpiry",
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null)
                this.router.navigate(['/home']);
              },
            })
          }
          else if(error.status == 500){
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: "Something went wrong at server side!"
            });
          }
          else{
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: error.error.responseException.message
            });
          }
        });
      }
      catch(error){
        this.loader = false
        this.appinsightsService.logException(error,2)
      }
  }
  copyTextToClipboard() {
    try{
    this.showMessage = true;
    const textToCopy = document.getElementById("textToCopy").innerText;
    const tempInput = document.createElement("textarea");
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); 
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.tooltipMessage = 'Copied!';
    this.tooltipVisible = true;
    this.messageService.add({
      severity:'success', 
      detail: 'Link copied'
    });
    setTimeout(() => {
      this.tooltipVisible = false;
      this.tooltipMessage = 'Copied';
    }, 1500);
  }
  catch(error:any){
    console.log(error)
  }
}
  }

