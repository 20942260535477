<div class="container-fluid pb-5">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
            <h2 class="py-2 pt-5 burgendy fw-semibold">My Profile</h2>
        </div>
        <div class="col-12 col-md-8 offset-md-2" *ngIf="!loader">
            <form [formGroup]="myProfileForm" (ngSubmit)="onSubmit(myProfileForm.value)">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="firstName">First Name<span class="star">*</span></label>:
                        <input pInputText name="firstName" id="firstName" type="text" autocomplete="off"
                            class="clsControl form-control" formControlName="firstName" placeholder="First Name">
                        <div class="error"
                            *ngIf="myProfileForm.controls['firstName'].invalid && myProfileForm.controls['firstName'].dirty">
                            <span *ngIf="myProfileForm.controls['firstName'].errors['required']">First Name
                                Required</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="lastName">Last Name<span class="star">*</span></label>:
                        <input pInputText name="lastName" id="lastName" type="text" autocomplete="off"
                            class="clsControl form-control" formControlName="lastName" placeholder="Last Name">
                        <div class="error"
                            *ngIf="myProfileForm.controls['lastName'].invalid && myProfileForm.controls['lastName'].dirty">
                            <span *ngIf="myProfileForm.controls['lastName'].errors['required']">Last Name
                                Required</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="MiddleName">Midddle Name</label>:
                        <input pInputText name="MiddleName" id="MiddleName" type="text" autocomplete="off"
                            class="clsControl form-control" formControlName="MiddleName" placeholder="Middle Name">
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="Role">Role<span class="star">*</span></label>:
                        <p-dropdown id="Role" placeholder="-- Select --" autoWidth="false" [disabled]="true"
                            class="clsControl form-control-placeholder" optionLabel="name" [tabindex]="5" name="Role"
                            formControlName="Role" [options]="roles">
                        </p-dropdown>
                        <div class="error"
                            *ngIf="myProfileForm.controls['Role'].invalid && myProfileForm.controls['Role'].dirty">
                            <span *ngIf="myProfileForm.controls['Role'].errors['required']">Role
                                Required</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="dateOfBirth">Date of Birth<span class="star">*</span></label>:
                        <p-calendar [showIcon]="true" formControlName="dateOfBirth" class="clsControl" id="dob"
                            placeholder="Select DOB" name="dateOfBirth" [monthNavigator]="true" [yearNavigator]="true">
                        </p-calendar>
                        <div class="error"
                            *ngIf="myProfileForm.controls['dateOfBirth'].invalid && myProfileForm.controls['dateOfBirth'].dirty">
                            <span *ngIf="myProfileForm.controls['dateOfBirth'].errors['required']">Date of Birth
                                Required</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="lefa">LEFA Id<span class="star">*</span></label>:
                        <input pInputText name="lefa" id="lefa" type="text" autocomplete="off"
                            class="clsControl form-control" formControlName="lefa" placeholder="LEFA" disabled>
                        <div class="error"
                            *ngIf="myProfileForm.controls['lefa'].invalid && myProfileForm.controls['lefa'].dirty">
                            <span *ngIf="myProfileForm.controls['lefa'].errors['required']">LEFA
                                Required</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="emailid">Email Address<span class="star">*</span></label>:
                        <input pInputText name="emailid" id="emailid" type="text" autocomplete="off"
                            class="clsControl form-control" formControlName="emailid" placeholder="Email Address">
                        <div class="error"
                            *ngIf="myProfileForm.controls['emailid'].invalid && myProfileForm.controls['emailid'].dirty">
                            <span *ngIf="myProfileForm.controls['emailid'].errors['required']">Email Address
                                Required</span>
                                <span *ngIf="myProfileForm.controls['emailid'].errors['pattern']">Invalid Email Address
                                    </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="fs-6 py-2" for="phoneNumber">Phone Number<span class="star">*</span></label>:
                        <input pInputText name="phoneNumber" id="phoneNumber" type="text" autocomplete="off"
                            class="clsControl form-control" formControlName="phoneNumber" placeholder="Phone Number">
                        <div class="error"
                            *ngIf="myProfileForm.controls['phoneNumber'].invalid && myProfileForm.controls['phoneNumber'].dirty">
                            <span *ngIf="myProfileForm.controls['phoneNumber'].errors['required']">Phone Number
                                Required</span>
                                <span *ngIf="myProfileForm.controls['phoneNumber'].errors['pattern']">Invalid Phone Number
                                    </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="fs-6 py-2" for="address">Address<span class="star">*</span></label>:
                        <textarea class="form-control d-inline" rows="3"  placeholder="Add Address"  formControlName="address"></textarea>
                        <div class="error"
                            *ngIf="myProfileForm.controls['address'].invalid && myProfileForm.controls['address'].dirty">
                            <span *ngIf="myProfileForm.controls['address'].errors['required']">Address
                                Required</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 justify-content-end p-0 py-3 d-flex align-items-center">
                    <p-button class="primeNgButton" label="Save Changes" type="submit"></p-button>
                    <p-progressSpinner *ngIf="saveDataLoader"
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
                </div>
            </form>
        </div>
        <div class="col-12 text-center" *ngIf="loader">
            <p-progressSpinner *ngIf="loader"
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>