<div class="container-fluid pb-5 mb-5">
    <div class="row mx-md-5">
        <div class="col-12   cursor-pointer mx-md-5 pt-3 d-flex align-items-center justify-content-end" (click)="navigateToUsers()">
          <i class="fa fa-arrow-left p-2 burgendy cursor-pointer"></i><span class="burgendy cursor-pointer">Back to users</span>
        </div>
        <div class="col-12 header">
            <h4 class="fw-semibold">{{mainHeading}}</h4>
            <p class="mb-0" *ngIf="showUploadExcelBlock">{{subHeading}}</p>
            <p *ngIf="showUploadExcelBlock">{{maxSizeIndicator}}</p>
        </div>
    </div>

    <div class="row custom_margin justify-content-center">
        <div [class.background_error]="!excelUploadedSuccessfully&&!loader&&!showUploadExcelBlock" class="col mx-md-5 p-0 background_error">
            <div class="border rounded p-3">
                <div class="rounded p-5 excel_upload_div" *ngIf="showUploadExcelBlock">
                    <img class="excel_upload__img" src="assets/images/excelupload.png" alt="upload excel">
                    <div (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                        <p class="h6">{{excelDropIndicator}}</p>
                        <p class="h6">or</p>
                    </div>
                    <div>
                        <input #fileInput type="file" id="fileInput" accept=".xlsx, .xls" style="display: none"
                            (change)="onFileSelected($event)">
                        <button type="button" class="btn btn-grape" (click)="browseFiles()">Browse</button>
                    </div>
                </div>
                <div *ngIf="!showUploadExcelBlock">
                    <div class="d-flex align-items-center justify-content-center flex-column p-5" *ngIf="loader">
                        <div class="spinner-border text-danger justify-content-center" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="p-3">
                            <p class="h6">{{parsingUserInfo}}</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center flex-column p-5"
                    *ngIf="excelUploadedSuccessfully&&!loader">
                        <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#168500}</style><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                        <h6>Users have been successfully added.</h6>
                        <h6>Emails have been sent.</h6>
                        <p style="color: #606060;">You are redirecting to 'Invited Users' page...</p>
                    </div>
                    <div class="d-flex align-items-center justify-content-center flex-column"
                        *ngIf="!excelUploadedSuccessfully&&!loader">  
                        <div class="bg-darkCherry border rounded p-3 d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg"  height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ff0000}</style><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg><span class="h6 m-0 px-2">{{fileUploadFailureErrorMessage}}</span>                      
                        </div>
                            <ul class="mt-4">
                                <li class="p-2" *ngFor="let error of errorList">{{error}}</li>
                            </ul>   
                            
                            <div class="row mx-md-5 justify-content-center" *ngIf="!showUploadExcelBlock&&!excelUploadedSuccessfully&&!loader">
                                <div class="col mx-md-5 pb-2" style="display: flex;
                                align-items: center;justify-content: start; cursor: pointer;">
                                 <button type="button" class="btn btn-grape" (click)="backToUploadExcel()">Upload Again</button>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row  custom_margin pt-3 justify-content-center" *ngIf="showUploadExcelBlock">
        <div class="col mx-md-5 border rounded bg-cloud">
            <div class="row align_inline">
                <div class="col-md-9 sample_excel d-flex align-items-center py-2">
                    <div>
                        <img class="excel_icon m-2" src="assets/images/excel.png" alt="excel image"/>
                    </div>
                    <div>
                        <h6 class="h6">Sample Table.CSV</h6>
                        <p class="d-inline-block fs-12 font-weight-normal lh-18 text_muted fs-12">You can download the attached sample template and use it as starting point for your own file </p>
                    </div>
                </div>
                <div class="col-md-3 sample_excel sample_excel__downloadBtn">
                   <button class="sample_excel_btn bg-white border rounded font-weight-bold" (click)="downloadSampleExcelTemplate()">Download</button>
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog key="toastMsg" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>