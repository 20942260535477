import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { user } from './users';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { stageUser } from '../stage-users/stage-users';
import { UploadExcelService } from 'src/app/services/upload-excel.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  usersList: user[] = [];
  stagedUsersList: stageUser[] = [];
  paginationValues: any;
  allUsersOptions: any;
  allStagedUsersOptions: any;
  activeTabIndex: number = 0;
  copyOfUserList:any;
  copyOfStagedUserList:any;
  loader: boolean = false;
  selectedUserOption: any;
  selectedStageOption: any;
  constructor(
    private userDetails: UserDetailsService,
    private titleService: Title,
    private router: Router,
    private loginService: LoginService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    private UploadExcel: UploadExcelService,
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.appinsightsService.logEvent('users screen visited')
    this.titleService.setTitle('Users');
    this.getUsersList();
    this.getStagedUsersList();
  }
  public getPaginationOptions(): any {
    return [5, 10, 15, 20];
  }
 async getUsersList() {
    try{
      this.loader = true;
    await this.loginService.tokenRenewal();
    await this.userDetails.getUsers().subscribe(
      (data) => {
        this.loader = false;
        this.usersList = data.result;
        this.allUsersOptions = this.usersList;
        this.allUsersOptions = Array.from(this.usersList.reduce((m, t) => m.set(t.status, t), new Map()).values())
        this.usersList.forEach((x) => {
          x.onboardedDate = this.datePipe.transform(
            x?.onboardedDate,
            'longDate'
          );
        });
        this.allUsersOptions.push({
          status:"All Users"
        });
        this.selectedUserOption = this.allUsersOptions[this.allUsersOptions.length-1]
        this.copyOfUserList = this.usersList;
        this.paginationValues = this.getPaginationOptions();
      },
      (error: any) => {
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting users list api", {error:JSON.stringify(error)})
        if (error.status == 401) {
          this.confirmationService.confirm({
            message:
              'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        }
      }
    );
    if (this.usersList.length > 20) {
      let length = Math.ceil(this.usersList.length / 10) * 10;
      this.paginationValues[this.getPaginationOptions().length] = length;
    }
  }
    catch(error:any){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }

 async getStagedUsersList(){
    try{
    await this.loginService.tokenRenewal();
    await this.UploadExcel.getStagedUsers().subscribe((data) => {
      this.loader = false;
     this.stagedUsersList = data.result;
     this.allStagedUsersOptions = this.usersList;
     this.allStagedUsersOptions = Array.from(this.stagedUsersList.reduce((m, t) => m.set(t.role, t), new Map()).values())
     this.stagedUsersList.forEach(x => {
      x.dateInvited = this.datePipe.transform(x?.dateInvited ,'longDate')
     })
     this.allStagedUsersOptions.push({
      role:"All Roles"
    });
    this.selectedStageOption = this.allStagedUsersOptions[this.allStagedUsersOptions.length-1]
     this.copyOfStagedUserList = this.stagedUsersList;
     this.paginationValues= this.getPaginationOptions();
    },(error:any)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting staged users list api", {error:JSON.stringify(error)})
      if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:"sessionExpiry",
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);;
          },
        })
      }
    })
    if(this.stagedUsersList.length>20)
    {
      let length = Math.ceil(this.stagedUsersList.length / 10) * 10;
      this.paginationValues[this.getPaginationOptions().length] = length;
    }
  }
  catch(error:any){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  }

  navigateToUploadUsers(){
    this.router.navigate(['/adminDashboard/uploadExcel']);
  }

  filterDataBasedOnSelectedStatus(event:any){
    this.usersList = this.copyOfUserList;
    if( event.value.status.toUpperCase() == 'ALL USERS'){
      this.usersList = this.copyOfUserList;
    }
    else{
      this.usersList = this.usersList.filter(x => x.status == event.value.status)
    }
  }
  filterDataBasedOnSelectedRole(event:any){
    this.stagedUsersList = this.copyOfStagedUserList
    if( event.value.role.toUpperCase() == 'ALL ROLES'){
      this.stagedUsersList = this.copyOfStagedUserList;
    }
    else{
      this.stagedUsersList = this.stagedUsersList.filter(x => x.role == event.value.role)
    }
    console.log(this.stagedUsersList)
  }

 async deleteUser(user){
    try{
      this.loader = true;
      await this.loginService.tokenRenewal();
      await this.userDetails.deleteUser(user.lefa).subscribe((data) => {
        this.loader = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'User deleted successfully',
          });
          this.getUsersList();
      },(error)=>{
        this.loader = false;
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in deleting a user api", {payload: JSON.stringify(user.lefa),error:JSON.stringify(error)})
          if (error.status == 401) {
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key:"sessionExpiry",
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          } else if (error.status == 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Something went wrong at server side!',
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.responseException[0],
            });
          }
      })
    }
    catch(error){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }
}
