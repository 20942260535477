import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-news-summary',
  templateUrl: './news-summary.component.html',
  styleUrls: ['./news-summary.component.css']
})
export class NewsSummaryComponent implements OnInit {
  newsName:string ='';
  constructor(
    private router: Router,
    private appinsightsService: LoggingService,
    ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('News summary screen visited')
    this.newsName = localStorage.getItem('SelectednewsName');
  }
  navigateTonews(){
    this.router.navigate(['/adminDashboard/news']);
  }
}
