import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalResources } from 'src/app/services/global-resource/global-resource';

@Component({
  selector: 'app-create-pw',
  templateUrl: './create-pw.component.html',
  styleUrls: ['./create-pw.component.css']
})
export class CreatePwComponent implements OnInit {
public notifyMsg: string =
  ' If you are not the intended user, Please notify us at';
public notifyMail: string = 'support@hbsseattle.com';
createPwForm: FormGroup;
heading: string = "Create Password";
public globalResource: any;
passwordType: string = 'password';
confirmPasswordType: string = 'password';
public showeye: boolean = false;
public hideeye: boolean = true;
public showConfirmeye: boolean = false;
public hideConfirmeye: boolean = true;

  constructor(private fb : FormBuilder) { }

  ngOnInit(): void {
    this.globalResource = GlobalResources.getResources().en;
    this.createPwForm = this.fb.group({
      newPassword: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      confirmPassword: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'),
      ])),
      termsAndConditions: new FormControl(false, Validators.required)
    })
  }
  public  togglepw(){
    if(this.passwordType != 'text'){
      this.passwordType = 'text';
      this.showeye = true
      this.hideeye = false;
    }
    else{
      this.passwordType = 'password';
      this.showeye = false
      this.hideeye = true;
    }
}
public  toggleConfirmpw(){
  if(this.confirmPasswordType != 'text'){
    this.confirmPasswordType = 'text';
    this.showConfirmeye = true
    this.hideConfirmeye = false;
  }
  else{
    this.confirmPasswordType = 'password';
    this.showConfirmeye = false
    this.hideConfirmeye = true;
  }
}
  onSubmit(formdata){

  }
}
