import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,private loginService: LoginService,
  ){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   if(localStorage.getItem('token')){
    if(JSON.parse(localStorage.getItem('loggedInUser')).roleId == 1 && state.url.includes('adminDashboard'))
    {
      return true;
    }
    else{
      this.router.navigate(['/home']);
      return false;
    }
   }
   else{
    localStorage.clear();
    this.router.navigate(['/home']);
    return false;
   }
  } 
}
