export interface blogcommen{
  userId: number,
  blogId:number,
  blogName: string,
  parentCommentId: number,
  commented: boolean,
  commentedAt: number,
  updatedAt: number,
  content:  string,
  isDeleted: boolean,
}

export interface restructuredComments{
  userId:number,
  userName: string,
  blogId: number,
  blogName: string,
  commentId: number,
  commentContent: string,
  hasChildren: boolean,
  commentedOn: Date,
  children:{
    userId:number,
    userName: string,
    blogId: number,
    blogName: string,
    commentId: number,
    commentContent: string,
    hasChildren: boolean,
    commentedOn: Date,
    children:{}[]
  }[];
}