import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-blog-summary',
  templateUrl: './blog-summary.component.html',
  styleUrls: ['./blog-summary.component.css']
})
export class BlogSummaryComponent implements OnInit {
blogName: string ='';
  constructor(private router: Router,
    private appinsightsService: LoggingService,) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Blog summary screen visited')
    this.blogName = localStorage.getItem('SelectedBlogName');
  }
  navigateToblogs(){
    this.router.navigate(['/adminDashboard/blogs']);
  }
}
