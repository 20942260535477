import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { roles, userRegistrationData, verifiedUser } from './userRegistrationForm';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-user-registration-form',
  templateUrl: './user-registration-form.component.html',
  styleUrls: ['./user-registration-form.component.css'],
  providers:[MessageService]
})
export class UserRegistrationFormComponent implements OnInit {
  userRegistrationForm: FormGroup;
  guid:any;
  verifiedUserData:verifiedUser;
  roles:roles[]=[];
  showPasswordValidationError:boolean = false;
  loader: boolean = false;
  registrationLoader: boolean = false;
  constructor(
    private fb: FormBuilder,  
    private loginService: LoginService, 
    private router: Router, 
    private loaderService: LoaderService, 
    private messageService: MessageService,
    private titleService: Title,
    private route: ActivatedRoute, 
    private userDetailsService:UserDetailsService,
    private appinsightsService: LoggingService,) {}

  ngOnInit(): void {
    this.appinsightsService.logEvent('user-registration screen visited')
    this.titleService.setTitle('User Registration');
    this.route.params.forEach((urlParams) => {
      this.guid = urlParams['guid'];
    });
    this.userRegistrationForm = this.fb.group({
      newPw: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
          ),
        ])
      ),
      confirmPw: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
          ),
        ])
      ),
      firstName: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z -]+'),
        ])
      ),
      lastName: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z -]+'),
        ])
      ),
      middleName: new FormControl(null),
      roleName: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      dob: new FormControl(null, Validators.compose([Validators.required])),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      ),
      lefa:new FormControl(null, 
        Validators.compose([
        Validators.required
      ]) 
      ),
      phoneNumber: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}'),
        ])
      ),
      address: new FormControl(null, Validators.compose([Validators.required])),
    });
    this.getRoles();
  }


  getRoles(){
    try{
      this.userDetailsService.getRoles().subscribe((data)=>{
        this.roles = data.result
        this.verifyUser(this.guid);
    },(error:any)=>{
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting roles api", {error:JSON.stringify(error)})
    })
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }

  verifyUser(guid){
    try{
      this.loader = true;
      this.userDetailsService.verifyUserWithGuid(guid).subscribe((data)=>{
        this.loader = false;
        this.verifiedUserData = data.result;
        this.autoFillUserData(this.verifiedUserData);
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in verifing user api", {error:JSON.stringify(error)})
      })
    }
    catch(error){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  autoFillUserData(userData){
    try{
      let rolesFiltered;
      this.roles.forEach(x =>  {
       if(x.id == userData.roleId){
         rolesFiltered = x;
       }
      })
     const lefa = this.userRegistrationForm.controls['lefa'];
     const roleName = this.userRegistrationForm.controls['roleName'];
     const personalEmail = this.userRegistrationForm.controls['email'];
     console.log(rolesFiltered)
     lefa.patchValue(userData.lefa);
     roleName.patchValue(rolesFiltered);
     personalEmail.patchValue(userData.email);
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }


  onSubmit(formdata) {
    try {
      if (this.userRegistrationForm.valid) {
        this.registrationLoader = true;
        let userRegistrationData: userRegistrationData = {
          userId: 0,
          firstName: formdata.firstName,
          lefa: formdata.lefa,
          email: formdata.email,
          phone: formdata.phoneNumber,
          password: formdata.confirmPw,
          isActive: true,
          isDeleted: false,
          roleId: formdata.roleName.id,
          createdDate: new Date(),
          lastName: formdata.lastName,
          middleName: formdata.middleName,
          isPasswordCreated: true,
          address: formdata.address,
          dateOfBirth: formdata.dob,
        };
        this.userDetailsService.registerUser(userRegistrationData,this.guid).subscribe(
          (data) => {
            this.registrationLoader = false;
            if (data) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'User registered successfully',
              });
              this.userRegistrationForm.reset();
              this.showPasswordValidationError = false;
              this.loginUser(formdata.lefa, formdata.confirmPw);
            }
          },
          (error: any) => {
            this.registrationLoader = false;
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: error in user registration api", {payload: JSON.stringify(userRegistrationData),error:JSON.stringify(error)})
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.title,
            });
          }
        );
      } else {
        for (const field in this.userRegistrationForm.controls) {
          this.userRegistrationForm.controls[field].markAsDirty();
        }
      }
    } catch (error) {
      this.registrationLoader = false;
      this.appinsightsService.logException(error,2)
    }
    
  }
  validatePassword(event:any){
    if(this.userRegistrationForm.controls['newPw'].value != this.userRegistrationForm.controls['confirmPw'].value)
    {
        this.showPasswordValidationError = true;
    }
    else{
      this.showPasswordValidationError = false;
    }
  }
  loginUser(lefaId:string,password:string){
    try{
      let loginDetails ={
        email: lefaId,
        password: password
      }
      this.registrationLoader = true;
      this.loginService.login(loginDetails).subscribe((data)=>{
        this.registrationLoader = false;
        localStorage.setItem('token', JSON.stringify(data.result));
        this.loginService.SelectedFieldID.next(data.result)
        setTimeout(() => {
          this.router.navigate(['/home']);
    }, 1000);
      },(error:any)=>{
        this.registrationLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in user login api", {payload: JSON.stringify(loginDetails),error:JSON.stringify(error)})
      })
    }
    catch(error){
      this.registrationLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }
}
