<div class="container-fluid">
    <div class="row mt-3 p-3 d-md-flex align-item-center">
        <div class="col-12 col-md-6">
            <h2 class="burgendy fw-semibold">{{newsName}}</h2>
        </div>
        <div class="col-12 col-md-6 px-md-5 text-md-end" (click)="navigateTonews()">
            <i class="fa fa-arrow-left p-2 burgendy cursor-pointer"></i><span class="burgendy cursor-pointer">Back to News</span>
        </div>
    </div>
    <hr class="m-0">
    <div class="row">
        <div class="col-2 custom_right_Border p-0">
            <app-news-submenu></app-news-submenu>
        </div>
        <div class="col-10">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
