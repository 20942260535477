
<div class="container-fluid p-0 pb-5">
    <p-toast position="top-center"></p-toast>
    <div class="row mx-md-3 p-0 m-0">
        <div class="col-12">
            <div class="row d-md-flex align-items-center justify-content-between px-md-5 pt-3">
                <div class="col-12 col-md-7 align_inline">
                    <h2 class="fw-semibold"> Edit Blogs</h2>
                </div>
                <div class="col-12 col-md-5 justify-content-end p-0  pb-4 ">
                      <p-button label="Delete" class="transparent_btn" (click)="showDeleteDialog = true"></p-button>
                      <p-button label="Preview" icon="fa fa-eye" iconPos="right" class="maroon_btn col-12"
                      (click)="showPreview()"></p-button>
                </div>
            </div>
        </div>
        <p-dialog [modal]="true" [draggable]="false"  [resizable]="false" class="addUserPopup" [(visible)]="showDeleteDialog"
    closable="false" [style]="{'width': '100%', 'max-width': '600px'}">
    <div class="row  ">
            <div class="col-12 col-md-12 pb-5">
                <h4 class="burgendy fw-semibold pl-2">Are you sure you want to delete this Blog?</h4>
            </div>
            <div class="col-12 col-md-12 d-flex align-item-center justify-content-end bg-light-ash ">
                <p-button class="m-2 cancel_Btn" label="Cancel" type="button" (click)="closeDelete()"></p-button>
                <p-button class="m-2 primeNgButton" label="Delete" type="button"  (click)="deleteBlog()"></p-button>
            </div>
    </div>
</p-dialog>
<p-dialog  [contentStyle]="{'overflow': 'auto', 'overflow-x': 'hidden', 
'max-height': '80vh','min-height':'250px'}" class="custom-dialog" header="Preview"   [(visible)]="displayPreviewDialog" [style]="{ width: '80%' , height:'70%' }">
    <div class="row pt-3 px-md-5">
        <div class="col-12 px-md-5">
            <div>
                <h3 class="burgendy fw-semibold">{{this.NewBlog['controls']['postName'].value}}</h3>
            </div>
            <div>
                <div class="d-md-flex align-items-center py-2">
                    <div class="py-1">
                        <i class="fa fa-user-circle-o creamy rounded-circle p-2"></i><span class="burgendy fw-semibold px-2 mr-2">{{ NewBlog.controls['author'].value }}</span>
                    </div>
                    <div class="py-1">
                        <i class="fa fa-calendar p-2"></i><span class="px-2">{{currentDate | date:'MMM dd yyyy'}}</span>
                    </div>
                </div>
                <div class="col-12 position-relative w-100 p-0">
                    <img alt="Card" [src]="postImage"  alt="Blog-image"
                    class="w-100 h-100 img-fixed-size" />
                </div>
                <div class="col-12 py-3">
                    <p-editor class="pEditor__Readonly w-100" [(ngModel)]="data"
                            [readonly]="true"></p-editor>
                </div>
                <div class="col-12">
                    <div class="d-inline pl-4" *ngFor="let tag of tags" >  
                        #{{this.tag}}
                      </div>
                </div>
            </div>
        </div>
     </div>
</p-dialog>
   
        </div>
        <div class="p-3 rounded-4 bg-white custom_border mx-md-5 pb-5 mt-3" *ngIf="!loader">
            <div class="row">
                <div class="col">      
                    <form [formGroup]="NewBlog"   >
                        <div class="row">
                            <div class="col">
                                <label class="fs-6 py-2" for="postName">Post Name<span class="star">*</span></label>:
                                <input pInputText
                                    name="postName"
                                    id="postName"
                                    type="text"
                                    autocomplete="off"
                                    class="clsControl form-control"       
                                    formControlName="postName"
                                    placeholder="Enter Post Name "
                                >
                                <div class="error" *ngIf="NewBlog.controls['postName'].invalid && NewBlog.controls['postName'].dirty">
                                    <span *ngIf="NewBlog.controls['postName'].errors['required']">Post Name is Required</span>
                                  </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fs-6 py-2" for="author">Author<span class="star">*</span></label>:
                                
                                <input pInputText
                                    name="author"
                                    id="author"
                                    type="text"
                                    autocomplete="off"
                                    class="clsControl form-control"       
                                    formControlName="author"
                                    placeholder="Enter author Name "
                                >
                                <div class="error" *ngIf="NewBlog.controls['author'].invalid && NewBlog.controls['author'].dirty">
                                    <span *ngIf="NewBlog.controls['author'].errors['required']">Author Name is Required</span>
                                  </div>
                            </div>
                            <div class="col-md-6 pt-0">
                                <label class="fs-6 py-2" for="roleName">Author type<span class="star">*</span></label>
                                <p-dropdown  id="roleName" [options]="roles" placeholder="-- Select --" autoWidth="false" class="clsControl"
                                  [options]="roles" optionLabel="name" [tabindex]="5" name="roleName" formControlName="authorType"
                                >
                                </p-dropdown>
                                <div class="error" *ngIf="NewBlog.controls['authorType'].invalid && NewBlog.controls['authorType'].dirty">
                                    <span *ngIf="NewBlog.controls['authorType'].errors['required']">Author Type is Required</span>
                                  </div>
                            </div>
                            <div class="col-md-6">
                                <label class="fs-6 py-2" for="tags">Tags<span class="star">*</span></label>:
                                <div class=" row ">
                                    <div class="col-12 d-flex">
                                <input pInputText
                                    name="tags"
                                    id="tags"
                                    type="text"
                                    autocomplete="off"
                                    class="clsControl form-control"       
                                    formControlName="tags"
                                    placeholder="Enter Tag"
                                > <span></span>  <i *ngIf="NewBlog.controls['tags'].valid  " class="fa fa-plus-circle add-icon burgendy pt-3 pl-2" (click)="addTag()"></i>
                                    </div>
                                </div>
                               <div class="display"><span *ngFor="let tag of tags">#{{ tag }} <i class="fa fa-times-circle delete-icon  burgendy" (click)="removeTag(tag)"></i><br></span></div>
                                <div class="error" *ngIf="NewBlog.controls['tags'].invalid && NewBlog.controls['tags'].dirty">
                                    <span *ngIf="NewBlog.controls['tags'].errors['required']">Tags is Required</span>
                                  </div>
                            </div>
                            <div class="col-12">
                                <label class="fs-6 py-2" for="description">Description</label>:
                                <p-editor  name="description" id="description" formControlName="description" [style]="{'height':'320px'}" ></p-editor>
                            </div>
                            <div class="col-12 mt-2">
                                <p >Choose or upload a banner image:<span class="star">*</span></p>
                                <input #fileInput type="file" id="fileInput" formControlName="image"  accept="image/png, image/jpeg" style="display: none"
                                (change)="onFileSelected($event)">
                                <div class="d-flex align-items-center flex-column justify-content-center custom_css_uploadImage" (click)="browseFiles()">
                                    <p class="mt-3">Upload <br>(Max 1MB) <br>600X400 Px</p>
                                </div>
                                <div class="row p-3">
                                    <div class="col-md-4 col-xs-2"><img *ngIf="postImage" [src]="postImage" class="img-fluid image-css" alt="Preview" /></div>
                                </div>
                            </div>
                            <div class="col d-flex justify-content-start  pt-3 cursor-pointer"(click)="revert()">
                                <i class="pi pi-refresh burgendy p-3"> Revert Changes</i>
                                </div>
                            <div class="col d-flex justify-content-end align-items-center pt-3" *ngIf="showActionButtons">
                                <p-button class="saveBtn mr-2 event__btn" (click)="onSubmit(NewBlog.value,false)" label="Save" type="button" ></p-button>
                                <p-button class="primeNgButton publishBtn ml-2" (click)="onSubmit(NewBlog.value,true)"     label="Publish" type="button" ></p-button>
                                <p-progressSpinner *ngIf="editLoader"
                                styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                            </div> 
                        </div>
                    </form>
                </div>
            </div>
        </div>
</div>

    <div class="text-center" *ngIf="loader">
        <p-progressSpinner *ngIf="loader"
        styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
        animationDuration=".5s"></p-progressSpinner>
    </div>
    <p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
        <ng-template pTemplate="footer">
            <a (click)="cd.reject()"><span
                class="text-danger d-inline-block p-3 cursor-pointer">Cancel</span></a>
            <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
        </ng-template>
    </p-confirmDialog>
