import { Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { blog } from './blog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
import { Title } from '@angular/platform-browser';
import { LoggingService } from 'src/app/services/logging.service';
@Component({
  selector: 'app-blog-edit',
  templateUrl: './blog-edit.component.html',
  styleUrls: ['./blog-edit.component.css']
})
export class BlogEditComponent implements OnInit {
  blogId: number;
  blog: any;
  try: boolean = true;
  displayDeleteDialog: boolean = true;
NewBlog: FormGroup;
showDeleteDialog : boolean = false;
postImage: any;
roles: any[] = [
  { name: 'Admin' },
  { name: 'Teacher' },
  { name: 'Student' },
  { name: 'Faculty' },
];
[x: string]: any;
  file: any;
  displayPreviewDialog: boolean = false;

@ViewChild('fileInput') fileInput: any;
data:string=''
currentDate = new Date();
loader: boolean = false;
editLoader: boolean = false;
showActionButtons: boolean = false;
  constructor(private route: ActivatedRoute,
    private BlogDetails:BlogDetailsService,
    private loaderService: LoaderService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private loginDetails: LoginService,
    private fb: FormBuilder, 
    private loginService: LoginService, 
    private titleService: Title, 
    private appinsightsService: LoggingService) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Blog edit screen visited')
    this.titleService.setTitle('Edit Blog');
    this.route.params.forEach((urlParams) => {
      this.blogId = urlParams['blogId'];
      this.loginService.SelectedblogID.next(this.blogId) 
    });
    this.NewBlog = this.fb.group({
      postName:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      author:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      authorType:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      tags:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      image:new FormControl(
        null,
       
      ),
      description:new FormControl(null, Validators.compose([Validators.required])),
    })

    if(this.blogId){
      this.editBlogs(this.blogId);
    }
  }
  navigateToblog(){
    this.router.navigate(['/adminDashboard/blogs']);
  }
  revert(){
  // this.removeTag(this.blog.tags)
  this.removeAllTags();
    this.editBlogs(this.blogId);
  }
  closeDelete(){
  this.showDeleteDialog = false;
  }
  closePreview() {
    this.displayPreviewDialog = false;
  }
  browseFiles() {
    this.fileInput.nativeElement.click();
  }
  tagInput: string = '';
  tags: string[] = [];
  addTag() {
    const tag = this.NewBlog['controls']['tags'].value;
    if (tag !== '') {
      this.tags = [...this.tags, tag];
    }
    this.NewBlog['controls']['tags'].reset();
  }
  removeTag(tag: string) {
    this.tags = this.tags.filter((t) => t !== tag);
  }
  removeAllTags(){
    this.tags = [];
  }


async  onSubmit(formdata: any,ShouldPublish: boolean) {
    try {
      this.NewBlog['controls']['tags'].patchValue(this.tags); 
        const formData: FormData = new FormData();
        formData.append("Title", formdata.postName);
        formData.append("AuthorName", formdata.author);
        formData.append("AuthorType", formdata.authorType.name);
        formData.append('IsPublished', ShouldPublish.toString());
        formData.append("Content", formdata.description);
        formData.append("Tags", this.NewBlog['controls']['tags'].value);
        if (this.file == undefined) {
          formData.append('Image','');  
        }
        else{
          formData.append('Image', this.file, this.file.name);  
        }
        await this.loginDetails.tokenRenewal();
        this.editLoader = true;
        await this.BlogDetails.postBlog(this.blogId ,formData).subscribe(
          (data) => {
            if (data.statusCode == 200) {
              this.editLoader = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Blog Edited Successfully',
            });
             }   this.NewBlog.reset();
             this.tags = [];
          },
          (error: any) => {
            this.editLoader = false;
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: error in  Blog edit api", {payload: JSON.stringify(this.blogId),error:JSON.stringify(error)})
            this.appinsightsService.logEvent("Error: error in  Blog edit api", {payload: JSON.stringify(formData),error:JSON.stringify(error)})
            if (error.status == 401) {
              this.confirmationService.confirm({
                message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                key:"sessionExpiry",
                accept: () => {
                  localStorage.clear();
                  this.loginService.SelectedFieldID.next(null);
                  this.router.navigate(['/home']);
                },
              });
            } else if (error.status == 500) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at server side!',
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: error.error.responseException[0],
              });
            }
         
          }
        );
        this.file = undefined;
        this.postImage = null;
        this.tags = [];
    } catch (error) {
      this.editLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }
  
 async editBlogs(blogId)
  {
    try{
    this.loader = true;
    await this.loginDetails.tokenRenewal();
    await this.BlogDetails.editBlog(blogId, true).subscribe((data) => {
      this.blog = data.result;
     this.loader = false;
      const Title = this.NewBlog.controls['postName'];
      const authorName = this.NewBlog.controls['author'];
      const authorType= this.NewBlog.controls['authorType'];
      const description= this.NewBlog.controls['description'];
      const tags =  this.NewBlog.controls['tags'];
      const  image = this.NewBlog.controls['image'];
      const matchingRole = this.roles.filter(role => role.name === this.blog.authorType);
      Title.patchValue(this.blog.title)
      authorName.patchValue(this.blog.authorName);
      authorType.patchValue(matchingRole[0]);
      description.patchValue(this.blog.description.replaceAll('"',''))
      tags.patchValue(this.blog.tags)
      this.addTag()
      this.postImage = this.blog.image;
      // image.patchValue(this.blog.image);
      this.showActionButtons = this.blog.status.toUpperCase() != 'PUBLISHED' || 'Draft'? true: false;
    },(error:any)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting Blog data api", {payload: JSON.stringify(blogId),error:JSON.stringify(error)})
      if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:"sessionExpiry",
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);
          },
        })
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }
    })
  }
  catch(error){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  } 

  onFileSelected(event: any) {
    try{
      const img = this.NewBlog.controls['image'];
      this.file = event.target.files[0];
      if (event.target.files && event.target.files.length > 0) {
        this.file = event.target.files[0];
        const reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
              const result = (<FileReader>event.target).result;
              this.postImage = result as string;
              img.patchValue(this.postImage);
            };
            reader.onloadend = () => {
              const postImage = reader.result as BinaryData;
              img.patchValue(postImage);
             };
             reader.readAsDataURL(event.target.files[0]);
             reader.readAsBinaryString(event.target.files[0]);
          }
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }
  editblogblogdelete(blogId){
    localStorage.setItem('SelectedblogID', blogId)
    this.router.navigate(['/adminDashboard/blogdelete',blogId]);
  }

  navigateToDelete(){
    this.router.navigate(['/adminDashboard/blogSummary/',this.blogId]);
  }
 async deleteBlog(){
    try {
       await this.loginDetails.tokenRenewal();
      await this.BlogDetails.deleteBlogDetails(this.blogId).subscribe(
        (data) => {
          try {
            if (data.statusCode == 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Blog deleted successfully'
              });
              setTimeout(() => {
                this.navigateToblog();
              }, 1000);
            }
          } catch (error) {
            console.error('Error occurred in API success response:', error);
          }
        },
        (error: any) => {
          try {
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: error in Blog delete api", {payload: JSON.stringify(this.blogId),error:JSON.stringify(error)})
            if (error.status == 401) {
              this.confirmationService.confirm({
                message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                key:"sessionExpiry",
                accept: () => {
                  localStorage.clear();
                  this.loginService.SelectedFieldID.next(null);
                  this.router.navigate(['/home']);
                },
              });
            } else if (error.status == 500) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at the server side!'
              });
            } else {
             
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: error.error.responseException.message
              });
            }
          } catch (error) {
            console.error('Error occurred in API error response:', error);
          }
        }
      );
    } catch (error) {
      this.appinsightsService.logException(error,2)
    }
  }

  showPreview(){
    this.data = this.NewBlog['controls']['description'].value
    this.displayPreviewDialog = true
  }
}
