import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { UploadExcelService } from 'src/app/services/upload-excel.service';

@Component({
  selector: 'app-upload-excel',
  templateUrl: './upload-excel.component.html',
  styleUrls: ['./upload-excel.component.css'],
})
export class UploadExcelComponent implements OnInit {
  mainHeading: string = 'Upload Bulk Users';
  subHeading: string = 'Upload a CSV to import user details';
  maxSizeIndicator: string = '(Max upload size 2MB)';
  excelDropIndicator: string = 'Drop CSV file here';
  showUploadExcelBlock: boolean = true;
  parsingUserInfo: string = 'Please wait. We are parsing user information from CSV file';
  loader:boolean = false;
  excelUploadedSuccessfully:boolean = false;
  fileUploadFailureErrorMessage:string = 'The CSV could not be submitted because errors where found.';
  errorList:string[]=[];
  @ViewChild('fileInput') fileInput: any;
  // imageDataArray:[{imageData:ArrayBuffer}];
  imageDataArray:any;

  constructor(
    private UploadExcel: UploadExcelService,
    private titleService: Title,
    private loginService: LoginService, 
    private confirmationService: ConfirmationService,
    private router: Router,
    private appinsightsService: LoggingService,) {}

  ngOnInit(): void {
    this.appinsightsService.logEvent('upload-excel screen visited')
    this.titleService.setTitle('Upload Users');
  }
  browseFiles() {
    this.fileInput.nativeElement.click();
  }

async  onFileSelected(event: any) {
    // Handle the selected file
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    if (selectedFile) {
      this.showUploadExcelBlock = false;
      this.loader = true;
      const formData: FormData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);
      // await this.loginService.tokenRenewal();
      await this.UploadExcel.uploadExcel(formData).subscribe((data) => {
        if(data){
          this.showUploadExcelBlock = false;
          this.loader = false;
          this.excelUploadedSuccessfully = true;
          setTimeout(()=>{
            this.router.navigate(['/adminDashboard/users']);
          },3500)
        }
      },(error)=>{
        if(error.status == 401){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);;
            },
          })
        }
        else{
        this.errorList=[];
        this.showUploadExcelBlock = false;
        this.loader = false;
        this.excelUploadedSuccessfully = false;
        if(error.status == 400){
          this.errorList = error?.error?.responseException
          console.log(this.errorList)
        }
        else{
          this.errorList.push(error?.error?.responseException?.exceptionMessage)
          // this.errorList = [...this.errorList]
        }
      }
      });
    }
  }
 
  backToUploadExcel(){
    this.showUploadExcelBlock = true;
    this.loader= false;
    this.excelUploadedSuccessfully=false;

  }
  async downloadSampleExcelTemplate(){
    this.imageDataArray=[]
    // this.imageDataArray=[{imageData:''}];
    // await this.loginService.tokenRenewal();
    await this.UploadExcel.sampleTemplateExcel().subscribe((data)=>{
      // this.imageDataArray.push({imageData:data,fileName:'abc'})
      const url = window.URL.createObjectURL(data);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'SampleTemplate.xlsx';
      link.click();
  
      window.URL.revokeObjectURL(url);
    },(error:any)=>{
      if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'toastMsg',
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);;
          },
        })
      }
    })
  }
  navigateToUsers(){
    this.router.navigate(['/adminDashboard/users']);
  }

  createImageDataUrl(imageData) {
    // const blob = new Blob([imageData], { type: 'application/pdf' });
    // return URL.createObjectURL(blob);
    // const blob = new Blob([imageData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = 'file.xlsx';
    // link.click();
  }
}
