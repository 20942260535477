<div class="container-fluid">
    <div class="row">
        <div class="col">
            <p-table #stageUserList [value]="stagedUsersList" sortMode="single" [responsive]="true" [rows]="10"
                sortOrder="-1" [paginator]="true" [rowsPerPageOptions]="paginationValues" [globalFilterFields]="['lefa','role','email','dateInvited','numberOfInvitationsSent']">
                <ng-template pTemplate="caption">
                    <div class="row align_inline">
                        <div  class="col d-flex align-items-center justify-content-start">
                            <i class="fa fa-user" style="color: black;font-size: 1.5em;padding-right: 5px;"></i>
                            <h2 style="margin:0px;color: black;font-size: 20px; font-weight: 600;">Invited Users</h2>
                        </div>
                        <div class="col input-group align_inline" style="text-align: right;justify-content: right;">
                              <input type="text" class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2" autocomplete="off" pInputText size="50" placeholder=" Ex: Search Invited users..."
                              (input)="stageUserList.filterGlobal($event.target.value, 'contains')" style="height: 2.25rem;font-size: 13px;
                              border-radius: 5px 0px 0px 5px;">
                              <div class="input-group-prepend" style="background: #99A7BA;
                              border-width: 0.666667px 0.666667px 0.666667px 0px;
                              border-style: solid;
                              height: 2.25rem;
                              border-color: rgba(153, 167, 186, 0.27);
                              border-radius: 0px 5px 5px 0px;">
                                <span class="input-group-text" style="background: #99A7BA;
                                border: none;">
                                  <i class="fa fa-search"  style="margin:4px 4px 0 0;color: white;"></i>
                                </span>
                              </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'lefa'">LEFA Id <p-sortIcon [field]="'lefa'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'role'">Role <p-sortIcon [field]="'role'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'email'">Personal Email<p-sortIcon
                                [field]="'email'"></p-sortIcon></th>
                        <th [pSortableColumn]="'dateInvited'">Date Uploaded <p-sortIcon [field]="'dateInvited'"></p-sortIcon></th>
                        <th [pSortableColumn]="'numberOfInvitationsSent'">NO. Invitations <p-sortIcon [field]="'numberOfInvitationsSent'"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-stagedUser let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                        <td>{{stagedUser?.lefa }}  </td>                  
                        <td>{{stagedUser?.role }}  </td>
                        <td>{{stagedUser?.email }}</td>
                        <td>{{stagedUser?.dateInvited }}  </td>
                        <td>{{stagedUser?.numberOfInvitationsSent }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog key="toastMsg" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>