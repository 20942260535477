import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { LoginService } from 'src/app/services/login.service';
import { editlist } from './edit-overview';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { Title } from '@angular/platform-browser';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-edit-overview',
  templateUrl: './edit-overview.component.html',
  styleUrls: ['./edit-overview.component.css']
})
export class EditOverviewComponent implements OnInit {
  eventId: number;
  editList: editlist;
  showMessage: boolean = false;
  tooltipVisible = false;
  tooltipMessage = '';
  loader: boolean = false;
  constructor(
    private EventDetails:EventDetailsService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private loginService: LoginService, 
    private titleService: Title,
    private appinsightsService: LoggingService,
    private loginDetails: LoginService
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event overview screen visited')
    this.titleService.setTitle('Event Overview');
    this.route.params.forEach((urlParams) => {
      this.eventId = urlParams['eventId'];
      this.loginService.SelectedEventId.next(this.eventId)
    });
    this.getEventOverviewDetails(this.eventId);
  }

 async getEventOverviewDetails(eventid) {
    try {
      this.loader = true;
      await this.loginDetails.tokenRenewal();
     await this.EventDetails.getEditoverview(eventid).subscribe((data) => {
      this.loader = false;
        if (data) {
          this.editList = data.result;
          console.log(this.editList);
        } 
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting Event overview details api", {payload: JSON.stringify(eventid),error:JSON.stringify(error)})
        if(error.status == 401){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: 'sessionExpiry',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException[0]
          });
        }
      });
    } catch (error) {
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }
  copyTextToClipboard() {
    try{
    this.showMessage = true;
    const textToCopy = document.getElementById("textToCopy").innerText;
    const tempInput = document.createElement("textarea");
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); 
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.tooltipMessage = '';
    this.tooltipVisible = true;
    setTimeout(() => {
      this.tooltipVisible = false;
      this.tooltipMessage = '';
    }, 1500);
    this.messageService.add({
      severity:'success', 
      detail: 'Link copied'
    });
  }
  catch(error:any){
    console.log(error)
  }
}
}
