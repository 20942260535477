<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row mt-3 mx-md-5 p-3">
        <div class="col-12 align_inline">
            <h2 class="burgendy fw-semibold">Add Blog</h2>
            <div (click)="navigateToBlogList()">
                <i class="fa fa-arrow-left p-2 burgendy cursor-pointer"></i><span class="burgendy cursor-pointer">Back
                    to Blogs</span>
            </div>
        </div>
    </div>
    <hr>
    <div class="row  mx-md-5 ">
        <span class="col-12  align_inline">
            <div class="col-md-10"></div>
            <div class="col-md-2 p-0  pb-4  text-end">
                <p-button label="Preview" icon="fa fa-eye" iconPos="left" class="maroon_btn col-12"
                    (click)="showPreview()"></p-button>
            </div>
        </span>
    </div>
    <p-dialog  [contentStyle]="{'overflow': 'auto', 'overflow-x': 'hidden',
'max-height': '80vh','min-height':'250px'}" class="custom-dialog " header="Preview"  (onHide)="close()" closable="true"  [(visible)]="displayPreviewDialog"  [style]="{ width: '80%' , height:'70%' }">
        <div class="row pt-3 px-md-5" *ngIf="createNewBlog.valid">
            <div class="col-12 px-md-5">
                <div>
                    <h3 class="burgendy fw-semibold">{{this.createNewBlog['controls']['postName'].value}}</h3>
                </div>
                <div>
                    <div class="d-md-flex align-items-center py-2">
                        <div class="py-1">
                            <i class="fa fa-user-circle-o creamy rounded-circle p-2"></i><span class="burgendy fw-semibold px-2 mr-2">{{createNewBlog.controls['author'].value }}</span>
                        </div>
                        <div class="py-1">
                            <i class="fa fa-calendar p-2"></i><span class="px-2">{{currentDate | date:'MMM dd yyyy'}}</span>
                        </div>
                    </div>
                    <div class="col-12 position-relative w-100 p-0">
                        <img alt="Card" [src]="postImage"  alt="Blog-image"
                        class="w-100 h-100 img-fixed-size" />
                    </div>
                    <div class="py-3" *ngIf="createNewBlog['controls']['description'].value">
                        <p-editor class="pEditor__Readonly w-100" [(ngModel)]="createNewBlog['controls']['description'].value"
                            [readonly]="true"></p-editor>
                    </div>
                    <div class="col-12">
                        <div class="display">
                            <span *ngFor="let tag of tags" class="p-1">#{{ tag }}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!createNewBlog.valid">
            <div class="bg-danger text-white p-3">Please fill the form!</div>
        </div>
</p-dialog>
    <div class="p-3 rounded-4 bg-white custom_border mx-md-5 my-md-5 py-5">
        <div class="row">
            <div class="col">
                <form [formGroup]="createNewBlog" >
                    <div class="row">
                        <div class="col-12">
                            <label class="fs-6 py-2" for="postName">Blog Name<span class="star">*</span></label>:
                            <input pInputText name="postName" id="postName" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="postName"
                                placeholder="Enter Blog Name ">
                            <div class="error"
                                *ngIf="createNewBlog.controls['postName'].invalid && createNewBlog.controls['postName'].dirty">
                                <span *ngIf="createNewBlog.controls['postName'].errors['required']">Blog Name Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="fs-6 py-2" for="author">Author<span class="star">*</span></label>:

                            <input pInputText name="author" id="author" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="author"
                                placeholder="Enter author Name ">
                            <div class="error"
                                *ngIf="createNewBlog.controls['author'].invalid && createNewBlog.controls['author'].dirty">
                                <span *ngIf="createNewBlog.controls['author'].errors['required']">Author Name Required</span>
                            </div>
                        </div>
                        <div class="col-md-6 pt-0">
                            <label class="fs-6 py-2" for="roleName">Author type<span class="star">*</span></label>
                            <p-dropdown id="roleName" placeholder="-- Select --" autoWidth="false" class="clsControl form-control-placeholder"
                                optionLabel="name" [tabindex]="5" name="roleName" formControlName="authorType"
                                [options]="roles">
                            </p-dropdown>
                            <div class="error"
                                *ngIf="createNewBlog.controls['authorType'].invalid && createNewBlog.controls['authorType'].dirty">
                                <span *ngIf="createNewBlog.controls['authorType'].errors['required']">Author Type Required</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="fs-6 py-2" for="tags">Tags<span class="star">*</span></label>:
                            <div class=" row ">
                                <div class="col-12 d-flex">
                                    <input pInputText name="tags" id="tags" type="text" autocomplete="off"
                                        class="clsControl form-control" formControlName="tags" placeholder="Enter Tag">
                                   <i *ngIf="createNewBlog.controls['tags'].valid"
                                        class="fa fa-plus-circle add-icon burgendy pt-3 pl-2 cursor-pointer" (click)="addTag()"></i>
                                </div>
                            </div>
                            <div class="display">
                                <span *ngFor="let tag of tags">#{{ tag }} <i
                                        class="fa fa-times-circle delete-icon  burgendy"
                                        (click)="removeTag(tag)"></i><br></span></div>
                            <div class="error"
                                *ngIf="createNewBlog.controls['tags'].invalid && createNewBlog.controls['tags'].dirty">
                                <span *ngIf="createNewBlog.controls['tags'].errors['required']">Tags Required</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <label class="fs-6 py-2" for="description">Description<span class="star">*</span></label>:
                            <p-editor name="description" id="description" formControlName="description"
                                [style]="{'height':'320px'}"></p-editor>
                                <div class="error"
                                *ngIf="createNewBlog.controls['description'].invalid && createNewBlog.controls['description'].dirty">
                                <span *ngIf="createNewBlog.controls['description'].errors['required']">Description Required</span>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <p>Choose or upload a banner image:<span class="star">*</span></p>
                            <input #fileInput type="file" id="fileInput" formControlName="image"
                                accept="image/png, image/jpeg" style="display: none" (change)="onFileSelected($event)">
                            <div class="d-flex align-items-center flex-column justify-content-center custom_css_uploadImage"
                                (click)="browseFiles()">
                                <p class="mt-3">Upload <br>(Max 1MB) <br>600X400 Px</p>
                            </div>
                            <div class="error" *ngIf="createNewBlog.controls['image'].invalid && createNewBlog.controls['image'].dirty">
                                <span *ngIf="createNewBlog.controls['image'].errors['required']">Image Required</span>
                              </div>
                            <div class="row p-3">
                                <div class="col-md-4 col-xs-2"  *ngIf="postImage !== null">
                                    <img [src]="postImage"
                                        class="img-fluid image-css"/></div>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-end align-items-center pt-3">
                            <p-button class="saveBtn mr-2 event__btn" (click)="onSubmit(createNewBlog.value,false)"
                                label="Save" type="button"></p-button>
                            <p-button class="primeNgButton    publishBtn ml-2"
                                (click)="onSubmit(createNewBlog.value,true)" label="Publish"
                                type="button"></p-button>
                            <p-progressSpinner *ngIf="loader"
                                styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                        </div>
                        <!-- (click)="onSubmit(createNewBlog.value,'save')" (click)="onSubmit(createNewBlog.value,'Publish')" -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>