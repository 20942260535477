<div class="container  pt-30   ">
    <h1 class="burgendy pt-2 pb-3"><b>Privacy Policies </b></h1 >
    <h4 class="burgendy">1. Personal information:</h4>
    <p class="ash">Proin eget tortor risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia in elementum id enim.
      Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl
      tempus convallis quis ac lectus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo eget malesuada. Donec
      rutrum congue leo eget malesuada. Pellentesque in ipsum id orci porta dapibus. Curabitur aliquet quam id ut lacinia in elementum id enim. Praesent sapien
      massa, convallis a pellentesque nec, egestas non nisi leo eget malesuada.</p>

    <h4 class="burgendy">2. Collecting personal information:</h4>
    <p class="ash">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, odio sequi! Nam consectetur, officiis ipsam explicabo tempore placeat! Voluptas
      molestiae pariatur necessitatibus consectetur, ullam aut esse amet debitis sint culpa. orem ipsum dolor sit amet consectetur adipisicing elit. Assumenda,
      odio Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae mollitia laborum assumenda beatae minima enim quis dolores, pariatur, at perferendis
      sit dolore modi nostrum eum ea voluptatem</p>

    <h4 class="burgendy">3. Using and disclosing your personal information:</h4>
    <p class="ash">Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero
      malesuada feugiat. Nulla porttitor accumsan tincidunt. Sed porttitor lectus nibh. Pellentesque in ipsum id orci porta dapibus.Nulla quis lorem ut libero
      malesuada feugiat. Proin eget tortor risus. Nulla porttitor accumsan tincidunt. Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam
      vehicula elementum sed sit amet dui. Vivamus magna justo. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Proin eget tortor risus Lorem
      ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. Vivamus magna justo, lacinia eget consectetur sed,
      convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>

    <h4 class="burgendy">4. Contact by the University:</h4>
    <p class="ash">Nulla quis lorem ut libero malesuada feugiat. Proin eget tortor risus. Nulla porttitor accumsan tincidunt. Donec rutrum congue leo eget malesuada.
      Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus libero
      malesuada feugiat. Proin eget tortor. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Proin eget tortor risus consectetur adipiscing elit.
      Curabitur aliquet quam id dui posuere blandit.</p>

    <h4 class="burgendy">5. Individual’s right of access:</h4>
    <p class="ash">Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero
      malesuada feugiat. Nulla porttitor accumsan tincidunt. Sed porttitor lectus nibh. Pellentesque in ipsum id orci porta dapibus.Nulla quis lorem ut libero
      malesuada feugiat. Proin eget tortor risus. Nulla porttitor accumsan tinci dunt. Donec rutrum congue leo eget malesuada. Vestibulum ac diam sit amet quam
      vehicula elementum sed sit amet dui. Vivamus magna justo. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Proin eget tortor risus Lorem
      ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. Vivamus magna justo, lacinia eget consectetur sed,
      convallis at tellus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>

    <h4 class="burgendy">6. The University and links to other web sites:</h4>
    <p class="ash">Proin eget tortor risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia in elementum id enim.
      Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl
      tempus convallis quis ac lectus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo eget malesuada. Donec
      rutrum congue leo eget malesuada. Pellentesque in ipsum id orci.</p>
  </div>