import { Component, OnInit } from '@angular/core';
import { overview } from './overview';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { Title } from '@angular/platform-browser';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-blog-overview',
  templateUrl: './blog-overview.component.html',
  styleUrls: ['./blog-overview.component.css']
})
export class BlogOverviewComponent implements OnInit {
overview:any;
blogId : number;
tooltipVisible = false;
tooltipMessage = 'Copy';
blogLink: string;
loader: boolean = false;
  constructor(private confirmationService: ConfirmationService, 
    private BlogDetails:BlogDetailsService,
    private loginService: LoginService,
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private titleService: Title,
    private appinsightsService: LoggingService, 
    private loginDetails: LoginService) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Blog overview screen visited')
    this.titleService.setTitle('Blog Overview');
    this.route.params.forEach((urlParams) => {
      this.blogId = urlParams['blogId'];
      this.loginService.SelectedblogID.next(this.blogId) 
    });
    this.getblogoverview(this.blogId);
  }

 async getblogoverview(blogId){
    try{
      this.loader = true;
      let location = window.location.origin;
      this.blogLink = location + "/blog/" + blogId
      localStorage.setItem("SelectedblogID",JSON.stringify(blogId));
      await this.loginDetails.tokenRenewal();
      await this.BlogDetails.getblogoverview(blogId).subscribe((data) => {
      if(data){
        this.loader = false;
        this.overview = data.result;
      }
        },(error:any)=>{
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in  Blog overview api", {payload: JSON.stringify(blogId),error:JSON.stringify(error)})
          this.loader = false;
          if(error.status == 401){
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key:"sessionExpiry",
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null)
                this.router.navigate(['/home']);
              },
            })
          }
          else if(error.status == 500){
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: "Something went wrong at server side!"
            });
          }
          else{
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: error.error.responseException.message
            });
          }
        });
      }
      catch(error){
       this.loader = false;
        this.appinsightsService.logException(error,2)
      }
  }
  copyTextToClipboard() {
    try{
    const textToCopy = document.getElementById("textToCopy").innerText;
    const tempInput = document.createElement("textarea");
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); 
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.tooltipMessage = 'Copied!';
    this.tooltipVisible = true;
    setTimeout(() => {
      this.tooltipVisible = false;
      this.tooltipMessage = 'Copied';
    }, 1500);
    this.messageService.add({
      severity:'success', 
      detail: 'Link copied'
    });
  }
  catch(error:any){
    this.appinsightsService.logException(error,2)
  }
}
}
