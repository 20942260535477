<div class="container-fluid px-5 pb-5">
    <div class="row mt-3 mx-6 px-6">
        <div class="col-12 pl-3">
            <div (click)="navigateToPublicEvents()">
                <i class="fa fa-arrow-left burgendy pt-2 pb-2 pr-2 pl-0 cursor-pointer"></i><span class="burgendy cursor-pointer">Back to Events</span>
            </div>
            <h2 class="burgendy fw-semibold">{{eventName}}</h2>
        </div>
    </div>

    <div class="row" *ngIf="!loader">
        <div class="col-12">
            <div class="row mx-6 px-6 align_inline bg-light-ash py-2">
                <div class="col-sm-3">
                    <p class="color-ash mb-2">Date</p>
                    <p class="h6 fw-semibold">{{eventDetails?.next_occurrence_date?.date | date:'MMMM dd'}}</p>
                </div>
                <div class="col-sm-3">
                    <p class="color-ash mb-2">Time</p>
                    <p class="h6 fw-semibold">{{eventDetails?.eventStartDateAndTime|date:'shortTime'}} - {{eventDetails?.eventEndDateAndTime|date:'shortTime'}}</p>
                </div>
                <div class="col-sm-3">
                    <p class="color-ash mb-2">Price($)</p>
                    <p class="h6 fw-semibold">{{eventDetails?.default_ticket_types[0]?.price}}</p>
                </div>
                <div class="col-sm-3">
                    <p class="color-ash mb-2">Location</p>
                    <p class="h6 fw-semibold">{{eventDetails?.venue?.name}}</p>
                </div>
            </div>
        
            <div class="row mt-3 mx-6 px-6">
                <div class="col-12 p-0">
                    <img  class="Custom_image_height w-100 " [src]="eventDetails?.images?.thumbnail" [alt]="eventName" />
                </div>
            </div>
        
            <div class="row mt-3 px-6 mx-6">
                <div class="col-12 d-flex align-item-center justify-content-center">
                    <p-button class="primeNgButton" label="Register Now" type="button"styleClass="p-button" (click)="loaderService.navigateToOtherSite(eventDetails?.url)"></p-button>
                </div>
            </div>
            
            <div class="row mt-3 px-6 mx-6">
                <div class="col-12 p-3 d-flex align-item-center justify-content-center pl-0">
                    <p-editor class="pEditor__Readonly w-100" [(ngModel)]="eventDescription"  [readonly]="true"></p-editor>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center" *ngIf="loader">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
