import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ForgotPwComponent } from "./components/forgot-pw/forgot-pw.component";
import { CreatePwComponent } from "./components/create-pw/create-pw.component";
import { LandingComponent } from "./components/landing/landing.component";
import { HomeComponent } from "./components/home/home.component";
import { UploadExcelComponent } from "./components/upload-excel/upload-excel.component";
import { StageUsersComponent } from "./components/stage-users/stage-users.component";
import { AuthGuard } from "./guards/auth-guard.service";
import { UserRegistrationFormComponent } from "./components/user-registration-form/user-registration-form.component";
import { AdminDashboardComponent } from "./components/admin-dashboard/admin-dashboard.component";
import { UsersComponent } from "./components/users/users.component";
import { CreateEventComponent } from "./components/create-event/create-event.component";
import { EventsListComponent } from "./components/events-list/events-list.component";
import { EventSummaryComponent } from "./components/event-summary/event-summary.component";
import { EditEventComponent } from "./components/edit-event/edit-event.component";
import { EditOverviewComponent } from "./components/edit-overview/edit-overview.component";
import { EventDeleteComponent } from "./components/event-delete/event-delete.component";
import { EventPublicComponent } from "./components/event-public/event-public.component";
import { EventPublicDetailViewComponent } from "./components/event-public-detail-view/event-public-detail-view.component";
import { BlogListComponent } from "./components/Blogs/blog-list/blog-list.component";
import { BlogCreateComponent } from "./components/Blogs/blog-create/blog-create.component";
import { NewsListComponent } from "./components/News/news-list/news-list.component";
import { NewsCreateComponent } from "./components/News/news-create/news-create.component";
import { ContactComponent } from "./contact/contact.component";
import { NewOverviewComponent } from "./components/News/new-overview/new-overview.component";
import { NewsDeleteComponent } from "./components/News/news-delete/news-delete.component";
import { NewsEditComponent } from "./components/News/news-edit/news-edit.component";
import { NewsSummaryComponent } from "./components/News/news-summary/news-summary.component";
import { BlogSummaryComponent } from "./components/Blogs/blog-summary/blog-summary.component";
import { BlogOverviewComponent } from "./components/Blogs/blog-overview/blog-overview.component";
import { BlogDeleteComponent } from "./components/Blogs/blog-delete/blog-delete.component";
import { BlogEditComponent } from "./components/Blogs/blog-edit/blog-edit.component";
import { BlogPublicComponent } from "./components/Blogs/blog-public/blog-public.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { NewsPublicViewComponent } from "./components/News/news-public-view/news-public-view.component";
import { TermConditionComponent } from "./components/term-condition/term-condition.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { EventIssuedticketComponent } from "./components/event-issuedticket/event-issuedticket.component";
import { NewsListPublicViewComponent } from "./components/News/news-list-public-view/news-list-public-view.component";
import { BlogListPublicComponent } from "./components/Blogs/blog-list-public/blog-list-public.component";
import { MyProfileComponent } from "./components/my-profile/my-profile.component";
import { MyEventsComponent } from "./components/my-events/my-events.component";

const appRoutes: Routes = [
   
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '',  component: LandingComponent,canActivate: [], 
    children: [
        { path: 'home',  component: HomeComponent },
        {path:'terms&condition',component:TermConditionComponent},
        {path:'privacy',component:PrivacyPolicyComponent},
        {path:'blog/:id',component:BlogPublicComponent},
        {path:'Blogs',component:BlogListPublicComponent},
        {path:'News/:id',component:NewsPublicViewComponent},
        {path:'News',component:NewsListPublicViewComponent},
        {path:'about-us',component:AboutUsComponent},
        {path:'My-profile',component:MyProfileComponent},
        {path:'My-events',component:MyEventsComponent},
        { path: 'forgotPassword/:guid',  component: ForgotPwComponent },
        { path: 'userRegistration/:guid',  component: UserRegistrationFormComponent },
        { path: 'Events',  component: EventPublicComponent },
        { path: 'contactus',  component: ContactComponent },
        { path: 'Event/:id',  component: EventPublicDetailViewComponent },
        { path:'adminDashboard', component: AdminDashboardComponent,canActivate: [AuthGuard],
      
        children:[
            { path: 'uploadExcel',  component: UploadExcelComponent,canActivate: [AuthGuard] },
            {path:'dashBoard', component:DashboardComponent,canActivate: [AuthGuard]},
            { path: 'stageUsers',  component: StageUsersComponent,canActivate: [AuthGuard] },
            { path: 'users',  component: UsersComponent,canActivate: [AuthGuard] },
            { path: 'eventDelete/:eventId',  component: EventDeleteComponent,canActivate: [AuthGuard] },
            { path: 'blogs',  component: BlogListComponent,canActivate: [AuthGuard] },
            { path: 'newsdelete/:newsId',  component:NewsDeleteComponent ,canActivate: [AuthGuard] },
            //   {path:'blogdelete/:blogId',component:BlogDeleteComponent,canActivate: [AuthGuard]},
            { path: 'createBlog',  component: BlogCreateComponent,canActivate: [AuthGuard] },
            {path:'blogSummary',component:BlogSummaryComponent,canActivate: [AuthGuard],
            children: [
                {path:'blogoverview/:blogId',component:BlogOverviewComponent,canActivate: [AuthGuard]},
                // {path:'blogdelete/:blogId',component:BlogDeleteComponent,canActivate: [AuthGuard]},
                {path:'blogedit/:blogId',component:BlogEditComponent,canActivate: [AuthGuard]},

            ]
        },
            { path: 'news',  component: NewsListComponent,canActivate: [AuthGuard],},
            { path: 'newssummary',  component: NewsSummaryComponent,canActivate: [AuthGuard],
            children: [
                { path: 'newsoverview/:newsId',  component: NewOverviewComponent,canActivate: [AuthGuard] },
                // { path: 'newsdelete/:newsId',  component:NewsDeleteComponent ,canActivate: [AuthGuard] },
                { path: 'newsedit/:newsId',  component: NewsEditComponent,canActivate: [AuthGuard] },

            ]
         },
            { path: 'createNews',  component: NewsCreateComponent,canActivate: [AuthGuard] },
            { path: 'createEvent',  component: CreateEventComponent,canActivate: [AuthGuard] },
            { path: 'eventsList',  component: EventsListComponent,canActivate: [AuthGuard] },
            { path: 'eventsSummary',  component: EventSummaryComponent,canActivate: [AuthGuard],
            children:[
                { path: 'editEvent/:eventId',  component: EditEventComponent,canActivate: [AuthGuard] },
                { path: 'eventOverView/:eventId',  component: EditOverviewComponent,canActivate: [AuthGuard] },
                {path:'issuedTicket/:eventId',component:EventIssuedticketComponent,canActivate: [AuthGuard]},

            ]},
        ]}
    ]},
    { path: 'createPassword',  component: CreatePwComponent },
]

export const routing = RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules,useHash: false });

export class AppRoutingModule {

}