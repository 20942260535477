import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
})
export class MyProfileComponent implements OnInit {
  myProfileForm: FormGroup;
  userInfo: any;
  selectedUserId: any;
  roles = [];
  loader: boolean = false;
  saveDataLoader: boolean = false;
  constructor(
    private appinsightsService: LoggingService,
    private titleService: Title,
    private fb: FormBuilder,
    private userDetails: UserDetailsService,
    private messageService: MessageService,
    private loginDetails: LoginService
  ) {}

  ngOnInit(): void {
    try {
      this.appinsightsService.logEvent('My profile screen visited');
      this.titleService.setTitle('My Profile');
      this.myProfileForm = this.fb.group({
        firstName: new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        lastName: new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        MiddleName: new FormControl(null),
        Role: new FormControl(null, Validators.compose([Validators.required])),
        dateOfBirth: new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
        lefa: new FormControl(null, Validators.compose([Validators.required])),
        emailid: new FormControl(
          null,
          Validators.compose([Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
        ),
        phoneNumber: new FormControl(
          null,
          Validators.compose([Validators.required,Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}')])
        ),
        address: new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
      });
      this.getRoles();
    } catch (error) {
      this.appinsightsService.logException(error, 2);
    }
  }


  getRoles(){
    try{
      this.userDetails.getRoles().subscribe((data)=>{
        this.roles =  data.result;
        this.getUserDetails();
      },(error)=>{
        this.appinsightsService.logException(error, 2);
      })
    }
    catch(error){
      this.appinsightsService.logException(error, 2);
    }
  }

  async getUserDetails() {
    try {
      await this.loginDetails.tokenRenewal();
      let userData = JSON.parse(localStorage.getItem('loggedInUser'));
      this.loader = true;
     await this.userDetails.getUserDetails(userData.lefa).subscribe(
        (data) => {
          if (data.statusCode == 200) {
            this.loader = false;
            this.userInfo = data.result;
            this.selectedUserId = this.userInfo.userId;
            this.prepopulateUserDetails(this.userInfo);
          }
        },
        (error) => {
          this.loader = false;
          this.appinsightsService.logException(error, 1);
          this.appinsightsService.logEvent("Error: Getting error in retreiving user data", {payload: JSON.stringify(userData.lefa),error:JSON.stringify(error)})
        }
      );
    } catch (error) {
      this.loader = false;
      this.appinsightsService.logException(error, 2);
    }
  }

  prepopulateUserDetails(userData){
    try{
     const roleSelected =  this.roles.filter((x)=> x.id == userData.roleId)
      const firstName = this.myProfileForm.controls['firstName'];
      const lastName = this.myProfileForm.controls['lastName'];
      const middleName = this.myProfileForm.controls['MiddleName'];
      const Role = this.myProfileForm.controls['Role'];
      const dateOfBirth = this.myProfileForm.controls['dateOfBirth'];
      const lefa = this.myProfileForm.controls['lefa'];
      const emailid = this.myProfileForm.controls['emailid'];
      const phoneNumber = this.myProfileForm.controls['phoneNumber'];
      const address = this.myProfileForm.controls['address'];

      firstName.patchValue(userData.firstName);
      lastName.patchValue(userData.lastName);
      middleName.patchValue(userData.middleName);
      Role.patchValue(roleSelected[0]);
      dateOfBirth.patchValue(new Date(userData.dateOfBirth));
      lefa.patchValue(userData.lefa);
      emailid.patchValue(userData.email);
      phoneNumber.patchValue(userData.phone);
      address.patchValue(userData.address);

    }
    catch(error){
      this.appinsightsService.logException(error, 2);
    }
  }

async  onSubmit(formData: any) {
    try {
      this.saveDataLoader = true;
      if (this.myProfileForm.valid) {
        let userObj = {
          firstName: formData.firstName,
          lastName:formData.lastName,
          email: formData.emailid,
          middleName: formData.MiddleName,
          phone: formData.phoneNumber,
          address: formData.address,
          dateOfBirth: formData.dateOfBirth,
        };
        await this.loginDetails.tokenRenewal();
       await  this.userDetails.updateUserInfo(this.selectedUserId,userObj).subscribe((data)=>{
          this.saveDataLoader = false;
          if(data.statusCode == 200){
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'User Details updated successfully',
            });
            this.myProfileForm.reset();
            this.getUserDetails();
          }
        },(error)=>{
          this.saveDataLoader = false;
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in updating user data", {payload: JSON.stringify(this.selectedUserId),error:JSON.stringify(error)})
          this.appinsightsService.logEvent("Error: Getting error in updating user data", {payload: JSON.stringify(userObj),error:JSON.stringify(error)})
        })
      } else {
        this.saveDataLoader = false;
        for (const field in this.myProfileForm.controls) {
          this.myProfileForm.controls[field].markAsDirty();
        }
      }
    } catch (error) {
      this.saveDataLoader = false;
      this.appinsightsService.logException(error, 2);
    }
  }
}
