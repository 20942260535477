
<div class="container-fluid p-0 bg-white">
    <p-dialog [modal]="true" [draggable]="false" [resizable]="false" class="addUserPopup" [(visible)]="showLoginPopUp"
        closable="false">
        <div>
            <button style="
                border-radius: 50px;
                border: none;
                background: none;
                float: right;
            " aria-label="Close" tabindex="4" type="button" class="p-dialog-titlebar-icon p-dialog-titlebar-close"
                (click)="closeDialog()" tabindex="4">
                <span class="p-dialog-titlebar-close-icon pi pi-times"></span>
            </button>
        </div>
        <div style="text-align: center;">
            <div class="alignment">
                <img class="hello_logo" src="../../../assets/images/hello.png" alt="hello">
                <h1 class="heading">Good to see you back!</h1>
            </div>
            <p class="subHeading">Please login to your account</p>
        </div>
        <div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
                <div class="row justify-content-center p-2">
                    <div class="col-md-10 pt-2">
                        <label for="email">Email</label>
                        <div class="input-container clsControl form-control">
                            <input pInputText name="email" id="email" type="text"
                                class="clsControl form-control inputField" formControlName="email" />
                        </div>
                        <div class="error"
                            *ngIf="!loginForm?.controls['email']?.valid&&loginForm?.controls['email']?.dirty">
                            <span
                                *ngIf="loginForm?.controls['email']?.errors['required']">{{globalResource.emailRequired}}</span>
                            <span
                                *ngIf="loginForm?.controls['email']?.errors['pattern']">{{globalResource.emailInValid}}</span>
                        </div>
                    </div>
                    <div class="col-md-10 pt-2">
                        <label for="password">Password</label>
                        <div class="input-container clsControl form-control">
                            <input pInputText name="password" id="password" [type]="passwordType"
                                class="clsControl form-control inputField" formControlName="password" />
                            <p-button class="eyeIcon" aria-label="Show Password" tabindex="0" *ngIf="showeye === true"
                                (click)="togglepw()">
                                <span class="p-viewer2">
                                    <i class="pi pi-eye eyeicon eye__color " style="cursor: pointer;padding-right: 10px;"
                                        aria-hidden="true"></i>
                                </span>
                            </p-button>
                            <p-button class="eyeIcon" aria-label="Hide Password" tabindex="0" *ngIf="hideeye === true"
                                (click)="togglepw()">
                                <span class="p-viewer2">
                                    <i class="pi pi-eye-slash eyeicon eye__color" style="cursor: pointer;padding-right: 10px;"
                                        aria-hidden="true"></i>
                                </span>
                            </p-button>
                        </div>
                        <div class="error"
                            *ngIf="!loginForm?.controls['password']?.valid&&loginForm?.controls['password']?.dirty">
                            <span
                                *ngIf="loginForm?.controls['password']?.errors['required']">{{globalResource.passwordRequired}}</span>
                        </div>
                    </div>
                    <div class="col-md-10 pt-2 forgot-password">
                        <a (click)="showForgotPassword()" style="color:#a41034;" class="cursor-pointer">Forgot password?</a>
                    </div>
                </div>

                <div class="row justify-content-center pt-3">
                    <div class="col-md-10 col-8 pt-2 text-center">
                        <button type="submit" class="submit_Btn d-flex align-items-center justify-content-center">
                            <span class="text-white">LOGIN</span>
                            <p-progressSpinner *ngIf="loader"
                            styleClass="custom-spinner spinner__in__landing" aria-label="Loading" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner></button>
                    </div>
                </div>
                <div class="error_msg">
                    <p id="redtext">{{errorMessage}}</p>
                </div>
            </form>
        </div>
    </p-dialog>
    <p-dialog [modal]="true" [draggable]="false" [resizable]="false" class="addUserPopup" [(visible)]="!showLoginPopUp&&showForgotPwPopUp"
    closable="false">
    <div class="px-md-3"  *ngIf="showForgotpasswordForm">
    <div class="row">
        <div class="col-12 text-end">
        <button style="
            border-radius: 50px;
            border: none;
            background: none;
            float: right;
        " aria-label="Close" tabindex="4" type="button" class="p-dialog-titlebar-icon p-dialog-titlebar-close"
            (click)="closeForgotPwDialog()" tabindex="4">
            <span class="p-dialog-titlebar-close-icon pi pi-times"></span>
        </button>   
    </div>
    </div>
        <div class="row px-3">
            <div class="col-12">
                <h5 class="text-dark">Forgot Password</h5>
                <p class="m-0 text__ash">
                    Please enter your email address to receive instructions for setting a new password.
                </p>
            </div>
        </div>
        <div class="row p-3 pb-0">
            <div class="col-12">
                <form [formGroup]="formgotPasswordForm" (ngSubmit)="onForgotPasswordFormSubmit(formgotPasswordForm.value)">
                    <div class="row">
                        <div class="col-12">
                            <label for="emailAddress" class="text-dark">Email:</label>
                            <div class="input-container clsControl form-control">
                                <input pInputText name="emailAddress" id="emailAddress" type="text"
                                    class="clsControl form-control inputField" formControlName="emailAddress" />
                            </div>
                            <div class="error"
                                *ngIf="!formgotPasswordForm?.controls['emailAddress']?.valid&&formgotPasswordForm?.controls['emailAddress']?.dirty">
                                <span
                                    *ngIf="formgotPasswordForm?.controls['emailAddress']?.errors['required']">{{globalResource.emailRequired}}</span>
                                <span
                                    *ngIf="formgotPasswordForm?.controls['emailAddress']?.errors['pattern']">{{globalResource.emailInValid}}</span>
                            </div>
                            <div class="error text-center">
                                <span>{{forgotPasswordError}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row py-3">
                        <div class="col-12 text-center">
                            <button type="submit" class="submit_Btn d-flex align-items-center justify-content-center">
                                <span class="text-white">SEND</span>
                                <p-progressSpinner *ngIf="loader"
                                styleClass="custom-spinner spinner__in__landing" aria-label="Loading" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row p-3 pt-0">
            <div class="col-12 text-center">
                <p class="text__ash">Remember your password? <span class="burgendy cursor-pointer" (click)="revertLoginFor()" >Login here</span></p>
            </div>        
        </div>
    </div>
    <div class="px-md-4 text-center" *ngIf="!showForgotpasswordForm">
        <div class="py-3">
            <svg xmlns="http://www.w3.org/2000/svg" height="3.25em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#37a575}</style><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
        </div>
        <h4 class="py-3 fw-bold">Your password reset link successfully sent to registered email address</h4>
        <div class="py-3 d-flex align-items-center justify-content-center">
            <div>
                <p-progressSpinner
                styleClass="custom-spinner" aria-label="Loading" strokeWidth="1"
                animationDuration=".5s"></p-progressSpinner>
                <p class="fw-semibold">Redirecting to Home Page in 5s</p>
            </div>
        </div>
    </div>
    </p-dialog>
    <p-toast position="top-center"></p-toast>
    <p-dialog [modal]="true" [draggable]="false" [resizable]="false" class="addUserPopup" [(visible)]="showChangePassword"  closable="false">
        <div class="row m-0 px-1">
            <div class="col-12 text-center">
                <div>
                    <button style="
                        border-radius: 50px;
                        border: none;
                        background: none;
                        float: right;
                    " aria-label="Close" tabindex="4" type="button" class="p-dialog-titlebar-icon p-dialog-titlebar-close"
                        (click)="closeDialog()" tabindex="4">
                        <span class="p-dialog-titlebar-close-icon pi pi-times"></span>
                    </button>
                </div>
                <h4 class="fw-semibold">Change Password</h4>
            </div>
            <div class="col-12 px-4">
                <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword(changePasswordForm.value)">
                    <div class="row">
                        <div class="col-12 pt-2">
                            <label class="fs-6 fw-semibold" for="newPassword">New Password:</label>
                            <div class="input-container clsControl form-control">
                                <input pInputText name="newPassword" id="newPassword" [type]="passwordType" (keyup)="validatePassword($event)"
                                    class="clsControl form-control inputField" formControlName="newPassword" />
                                <p-button class="eyeIcon" aria-label="Show Password" tabindex="0" *ngIf="showeye === true"
                                    (click)="togglepw()">
                                    <span class="p-viewer2">
                                        <i class="pi pi-eye eyeicon eye__color" style="cursor: pointer;padding-right: 10px;"
                                            aria-hidden="true"></i>
                                    </span>
                                </p-button>
                                <p-button class="eyeIcon" aria-label="Hide Password" tabindex="0" *ngIf="hideeye === true"
                                    (click)="togglepw()">
                                    <span class="p-viewer2">
                                        <i class="pi pi-eye-slash eyeicon eye__color" style="cursor: pointer;padding-right: 10px;"
                                            aria-hidden="true"></i>
                                    </span>
                                </p-button>
                            </div>
                            <div class="error"
                                *ngIf="!changePasswordForm?.controls['newPassword']?.valid&&changePasswordForm?.controls['newPassword']?.dirty">
                                <span class="small"
                                    *ngIf="changePasswordForm?.controls['newPassword']?.errors['required']">{{globalResource.passwordRequired}}</span>
                                    <span class="small"
                                    *ngIf="changePasswordForm?.controls['newPassword']?.errors['pattern']">Invalid Pattern</span>
                            </div>
                        </div>
                        <div class="col-12 pt-2">
                            <label class="fs-6 fw-semibold" for="confirmPassword">Confirm Password:</label>
                            <div class="input-container clsControl form-control">
                                <input pInputText name="confirmPassword" id="confirmPassword" [type]="confirmPasswordType"
                                    class="clsControl form-control inputField" formControlName="confirmPassword" (keyup)="validatePassword($event)" />
                                <p-button class="eyeIcon" aria-label="Show Password" tabindex="0" *ngIf="confirmPwShoweye === true"
                                    (click)="toggleConfirmPw()">
                                    <span class="p-viewer2">
                                        <i class="pi pi-eye eyeicon eye__color" style="cursor: pointer;padding-right: 10px;"
                                            aria-hidden="true"></i>
                                    </span>
                                </p-button>
                                <p-button class="eyeIcon" aria-label="Hide Password" tabindex="0" *ngIf="confirmPwHideeye === true"
                                    (click)="toggleConfirmPw()">
                                    <span class="p-viewer2">
                                        <i class="pi pi-eye-slash eyeicon eye__color" style="cursor: pointer;padding-right: 10px;"
                                            aria-hidden="true"></i>
                                    </span>
                                </p-button>
                            </div>
                            <div class="error"
                                *ngIf="!changePasswordForm?.controls['confirmPassword']?.valid&&changePasswordForm?.controls['confirmPassword']?.dirty">
                                <span class="small"
                                    *ngIf="changePasswordForm?.controls['confirmPassword']?.errors['required']">{{globalResource.passwordRequired}}</span>
                                    <span class="small"
                                    *ngIf="changePasswordForm?.controls['confirmPassword']?.errors['pattern']" class="text-danger">Invalid Pattern</span>
                            </div>
                            <span class="error small"
                                 *ngIf="showPasswordValidationError&&changePasswordForm.controls['confirmPassword'].value != null">
                            New Password and Confirm Password should be same</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h6 class="pt-3 fw-semibold burgendy m-0">Password requirements:</h6>
                            <div>
                                <p class="m-0 skyCloud small">Minimum 8 characters long-the more, the better</p>
                                <p class="m-0 skyCloud small">At least one lowercase character</p>
                                <p class="m-0 skyCloud small">At least one uppercase character</p>
                                <p class="m-0 skyCloud small">At least one number and one symbol</p>
                                <p class="m-0 skyCloud small">Password should not start with Number or _(underscore)</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center py-3">
                        <div class="col-md-10 col-8">
                            <button type="submit" class="submit_Btn d-flex align-items-center justify-content-center">
                                <span class="text-white">CHANGE</span>
                                <p-progressSpinner *ngIf="loader"
                                styleClass="custom-spinner spinner__in__landing" aria-label="Loading" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </p-dialog>
    <div class="row main_nav p-2 px-md-5 d-flex align-items-center justify-content-between">
        <div class="col">
            <p-menubar [model]="items">
                <ng-template pTemplate="start">
                    <img class="logo cursor-pointer" src="../../../assets/images/logo.png" alt="logo" (click)="navigateToHome()">
                </ng-template>
            </p-menubar>
        </div>
    </div>
    <div class="sub_nav">

    </div>
    <div class="h-100">
        <div class="container-fluid row p-0 m-0 containerBlock">
            <div class="col-12 p-0 mb-5 pb-5">
                <span *ngIf="showLoader" class="loading"> 
                    <span class="spinner"></span>
                </span>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <footer *ngIf="!isUserLoggedIn || this.loggedInUserData?.roleId != 1" class="pt-5 mt-5">
        <div class="row bg-dark m-0 ">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12 col-md-8 px-2 px-md-5 text-center text-md-start">
                        <div class="pt-5 pb-3">
                            <img src="assets/images/logo.png" alt="footer" class="img-fluid footer_logo cursor-pointer" (click)="navigateToHome()">
                        </div>
                        <div class="d-flex align-items-center justify-content-center  justify-content-md-start text-white">
                            <i class="fa fa-envelope"></i>
                            <a class="m-0 p-2 text-decoration-none text-white cursor-pointer" href="mailto:info@hbsseattle.com">info@hbsseattle.com</a>
                        </div>
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start text-white">
                            <i class="fa fa-phone"></i> 
                            <p class="m-0 p-2">800 388 80 90</p>
                        </div>
                        <div class="d-flex justify-content-center justify-content-md-start text-white">
                            <i class="fa fa-building py-3"></i>
                            <a class="m-0 p-2 cursor-pointer text-white text-decoration-none" href="https://www.bing.com/maps?cp=47.674349%7E-122.406582&lvl=15.9" target="_blank">6351 SeaView Avenue Seattle, WA 98107-2664</a>
                        </div>
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <ul class="list-unstyled d-flex">
                                <li>
                                    <a class="cursor-pointer" (click)="loaderService.navigateToOtherSite('https://www.linkedin.com/groups/14135627')">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512" class="d-block text-dark bg-white py-1 px-3"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                                    </a>
                                </li>
                                </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 text-center text-md-start">
                        <h5 class="fw-semibold text-light pt-5 pb-3 m-0">Quick Links</h5>
                        <ul class="list-unstyled">
                            <li><a class="text-white footer_menu text-decoration-none cursor d-block p-" routerLink="/home" >Home</a></li>
                            <li><a class="text-white footer_menu text-decoration-none cursor-pointer d-block py-1" routerLink="/Events">Events</a></li>
                            <li><a class="text-white footer_menu text-decoration-none cursor-pointer d-block py-1" routerLink="/News">News</a></li>
                            <li><a class="text-white footer_menu text-decoration-none cursor-pointer d-block py-1" routerLink="/Blogs">Blogs</a></li>
                            <!-- <li><a class="text-white footer_menu text-decoration-none cursor d-block py-1" routerLink="/landing/about-us">About US</a></li> -->
                            <li><a class="text-white footer_menu text-decoration-none cursor-pointer d-block py-1" routerLink="/contactus" >Contact Us</a></li>
                            <li><a class="text-white footer_menu text-decoration-none cursor-pointer d-block py-1" routerLink="/terms&condition" > Privacy Policy and T&C</a></li>
                        </ul>
                         <br>
                    </div>
                </div>
            </div>
            <div class=" col-12 col-md-6 d-flex align-items-center">
                <img src="assets/images/footerImage.png" alt="footer" class="img-fluid">
            </div>
            <div class="col-12 d-flex align-items-center justify-content-between p-2 poweredBy_footer">
                <div class="col-md-10 px-md-5 text-center text-md-start">
                  <small class="d-block lh-1.15 text-white"> © 2023 Harvard Business School Club of Seattle. All Rights Reserved
                  </small>
                </div>
                <div class=" col-md-2 text-center p-2 d-flex align-items-center justify-content-center">
                  <small class="p-2 poweredBy_text text-white">Powered by</small> <a href = "https://www.c2stechs.com" target="_blank"><img src="assets/images/c2slogo.png" alt="C2S Technologies" class="img-fluid poweredBy_c2s"/></a>
                </div>
            </div>
        </div>
    </footer>
    <footer *ngIf="isUserLoggedIn && this.loggedInUserData?.roleId == 1" class="fixed-bottom">
        <div class="row bg-burgendy m-0 mt-5">
            <div class="col-12 bg-burgendy ">
                <div class="col-md-12  col-6 d-flex   px-md-3 px-1   align-items-center bg-burgendy justify-content-between  poweredBy_footer">
            <div class="col-md-10 col-12 bg-burgendy px-md-3 px-0  py-0 text-center text-md-start">
              <small class="d-block  text-white bg-burgendy col-12 col-md-10"> © 2023 Harvard Business School Club of Seattle. All Rights Reserved
              </small>
            </div>
            <div class="col-12  col-md-2 text-center bg-burgendy  d-flex align-items-center justify-content-center">
              <small class=" bg-burgendy poweredBy_text text-white">Powered by</small>  <a href = "https://www.c2stechs.com" target="_blank"> <img src="assets/images/c2slogo.png" alt="C2S Technologies" class="img-fluid poweredBy_c2s"/></a>
            </div>
        </div> 
    </div>
    </div> 
    </footer>
</div>
                  
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

  

