<div class="container-fluid pb-5">
    <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
            <h2 class="py-2 pt-5 burgendy fw-semibold">My Events</h2>
        </div>
        <div class="col-12 col-md-10 offset-md-1" *ngIf="!loader">
            <div class="shadow p-3 my-2" *ngFor="let eventDetails of myEventsList">
                <div class="row text-center">
                    <div class="col-12 col-md-3">
                        <img [src]="eventDetails.eventImage" alt=""  class="w-100 img-fluid event__image"/>
                    </div>
                    <div class="col-12 col-md-9 text-start position-relative">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-12">
                                        <h4 class="fw-semibold">{{eventDetails.eventName}}</h4>
                                    </div>
                                    <div class="col-12" *ngIf="eventDetails?.eventDescription">
                                        <p-editor class="pEditor__Readonly w-100" [(ngModel)]="eventDetails.eventDescription"
                                        [readonly]="true"></p-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row text-end">
                                            <div class="col-12 d-flex align-items-center">
                                                <i class="fa fa-calendar p-2"></i><small>{{eventDetails.startDate | date:'MMMM d, y'}}</small>
                                            </div>
                                            <div class="col-12 d-flex align-items-center">
                                                <i class="fa fa-clock-o p-2"></i> <small>{{eventDetails.startDate | date:'h:mm a'}} - {{eventDetails.endDate | date: 'h:mm a'}} </small>
                                            </div>
                                            <div class="col-12 d-flex align-items-center">
                                                <i class="fa fa-map-marker p-2"></i><small>{{eventDetails.venue}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 position-absolute bottom-0">
                                <div class="row d-flex align-items-center">
                                    <div class="col-12 col-md-6 py-2">
                                        <h5 class="burgendy">Total: ${{eventDetails.total}}</h5>
                                    </div>
                                    <div class="col-12 col-md-3 py-2">
                                        <a (click)="loaderService.navigateToOtherSite(eventDetails?.barCodeUrl)" class="p-2 "><span class="burgendy text-decoration-underline cursor-pointer">View BarCode</span></a>
                                    </div>
                                    <div class="col-12 col-md-3 py-2">
                                        <a (click)="loaderService.navigateToOtherSite(eventDetails?.qrCodeUrl)"  class="burgendy p-2"><span class="burgendy text-decoration-underline cursor-pointer">View QRCode</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shadow p-3 my-2" *ngIf="myEventsList.length == 0">
                <p class="burgendy">No Tickets Found!</p>
            </div>
            <div class="py-5 my-5"  *ngIf="myEventsList.length == 0">

            </div>
        </div>
        <div class="col-12 text-center" *ngIf="loader">
            <p-progressSpinner *ngIf="loader"
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>