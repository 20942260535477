import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { Title } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
@Component({
  selector: 'app-blog-delete',
  templateUrl: './blog-delete.component.html',
  styleUrls: ['./blog-delete.component.css']
})
export class BlogDeleteComponent implements OnInit {
  displayDeleteDialog: boolean = true;
blogId:number;
  constructor(  
    private route: ActivatedRoute,
    private router: Router,
    private BlogDetails:BlogDetailsService,
    private loginService: LoginService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, 
    private titleService: Title, 
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
    private loginDetails: LoginService) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Blog delete screen visited')
    this.titleService.setTitle('Blog Delete');
    this.route.params.forEach((urlParams) => {
      this.blogId = urlParams['blogId'];
      this.loginService.SelectedblogID.next(this.blogId) 
     
    });
  }
  showDeleteDialog(): void {
    this.displayDeleteDialog = true;
  }
  navigateToDelete(){
    this.router.navigate(['/adminDashboard/blogSummary/blogoverview/',this.blogId]);
  }
  navigateToblog(){
    this.router.navigate(['/adminDashboard/blogs']);
  }

 async deleteBlog(){
    try {
      this.loaderService.display(true);
      await this.loginDetails.tokenRenewal();
      await this.BlogDetails.deleteBlogDetails(this.blogId).subscribe(
        (data) => {
          try {
            this.loaderService.display(false);
            if (data.statusCode == 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Blog deleted successfully'
              });
              setTimeout(() => {
                this.navigateToblog();
              }, 1000);
            }
          } catch (error) {
            this.loaderService.display(false);
            console.error('Error occurred in API success response:', error);
          }
        },
        (error: any) => {
          try {
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: Getting error in Blog deete api", {payload: JSON.stringify(this.blogId),error:JSON.stringify(error)})
            if (error.status == 401) {
              this.loaderService.display(false);
              this.confirmationService.confirm({
                message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                key: 'toastMsg',
                accept: () => {
                  localStorage.clear();
                  this.loginService.SelectedFieldID.next(null);
                  this.router.navigate(['/home']);
                },
              });
            } else if (error.status == 500) {
              this.loaderService.display(false);
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at the server side!'
              });
            } else {
              this.loaderService.display(false);
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: error.error.responseException.message
              });
            }
          } catch (error) {
            this.appinsightsService.logException(error,2)
          }
        }
      );
    } catch (error) {
      this.appinsightsService.logException(error,2)
    }
  }
}
