import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NewsService } from 'src/app/services/news.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { newslist } from '../news-list/newslist';
import { LoaderService } from 'src/app/services/loader.service';
import { Title } from '@angular/platform-browser';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.css']
})
export class NewsEditComponent implements OnInit {
  file: any;
  displayDeleteDialog: boolean = true;
  newslist : newslist[] = [];
  newsedit: FormGroup;
  postImage: any;
  showDeleteDialog : boolean = false;
  displayPreviewDialog: boolean = false;
  newsId:any;
  isImageVisible = true;
  @ViewChild('fileInput') fileInput: any;
  fileSizeWithinLimit: boolean;
  newseditData: any;
  currentDate = new Date();
  loader: boolean = false;
  editLoader: boolean = false;
  showActionButtons: boolean = false;
  constructor(private route: ActivatedRoute,
    private datePipe: DatePipe,
    private News: NewsService ,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private fb: FormBuilder, 
    private loginService: LoginService,
    private loaderService: LoaderService,   
     private titleService: Title,
     private appinsightsService: LoggingService) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('News Edit screen visited')
    this.titleService.setTitle('News Edit');
    this.route.params.forEach((urlParams) => {
      this.newsId = urlParams['newsId'];
      this.loginService.SelectednewsID.next(this.newsId)
    });
    this.newsedit = this.fb.group({
      newsHeading:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      department:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      image:new FormControl(
        null,
      
      ),
      description:new FormControl(null,
        Validators.compose([Validators.required])),
    })
    this.editNews(this.newsId)
  
  }
  closePreview() {
    this.displayPreviewDialog = false;
  }
  revert(){
    this.newsedit.reset()
    this.editNews(this.newsId)
  }
  navigateToNewsoverview(){
    this.router.navigate(['/adminDashboard/news']);
  }
  browseFiles() {
    this.fileInput.nativeElement.click();
  }
 async onSubmit(formdata: any,ShouldPublish) {
      if (this.newsedit.valid) {
        const formData: FormData = new FormData();
        formData.append("Heading", formdata.newsHeading);
        formData.append("Department", formdata.department);
        formData.append("Description", formdata.description);
        formData.append("IsPublished", ShouldPublish);
        if (this.file == undefined) {
          formData.append('Image','');  
        }
        else{
          formData.append('Image', this.file, this.file.name);  
        }
        this.editLoader = true;
        await this.loginService.tokenRenewal();
        await this.News.postnewslist(this.newsId,formData).subscribe(
          (data) => {
            this.editLoader = false;
            if (data.statusCode == 200){
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'News edit successfully',
            });
            
            this.file = undefined;
            this.postImage = null;
            this.newsedit.reset();
            // this.fileSizeWithinLimit = true;
            this.editLoader = false;
            }  },
          (error: any) => {
            this.editLoader = false;
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: error in news edit api", {payload: JSON.stringify(this.newsId),error:JSON.stringify(error)})
            this.appinsightsService.logEvent("Error: error in news edit api", {payload: JSON.stringify(formData),error:JSON.stringify(error)})
            if (error.status == 401) {
              this.confirmationService.confirm({
                message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                key:'sessionExpiry',
                accept: () => {
                  localStorage.clear();
                  this.loginService.SelectedFieldID.next(null);
                  this.router.navigate(['/home']);
                },
              });
            } else if (error.status == 500) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at server side!',
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: error.error.responseException[0],
              });
            }
            this.postImage = null;
            this.postImage.reset();
          }
        );
      } else {
        for (const field in this.newsedit.controls) {
          this.newsedit.controls[field].markAsDirty();
        }
      }
 
  }
  private showMessage(severity: string, summary: string, detail: string) {
    this.messageService.add({ severity, summary, detail });
  }
  selectedNewsStatus:any;
 async editNews(newsId)
  {
    try{
    await this.loginService.tokenRenewal();
    this.loader = true;
    await this.News.editNews(newsId,true).subscribe((data) => {
     this.newseditData = data.result;
     this.selectedNewsStatus = data.result.status;
    //  if (this.selectedNewsStatus === 'Published') {
    //   this.showMessage('warn', 'News Published', 'The news cannot be edited.');
    // }
   
     this.loader = false;
      const heading = this.newsedit.controls['newsHeading'];
      const department= this.newsedit.controls['department'];
      const description= this.newsedit.controls['description'];
      const  image  = this.newsedit.controls['image'];
      heading.patchValue(this.newseditData.heading )
      department.patchValue(this.newseditData.department)
      description.patchValue(this.newseditData.description.replaceAll('"',''))
      this.postImage = this.newseditData.image
      // image.patchValue(this.newseditData.image)
      this.currentDate = this.newseditData.createdDate
      this.showActionButtons = this.newseditData.status.toUpperCase() != 'PUBLISHED' || 'Draft' ? true: false;
    },(error:any)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting news details api", {payload: JSON.stringify(newsId),error:JSON.stringify(error)})
        if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'sessionExpiry',
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);
          },
        })
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }

    })
  } 
  catch(error){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
}


  onFileSelected(event: any) {
    const image = this.newsedit.controls["image"];
    this.file = event.target.files[0];
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const maxSize = 1000000 ;
  
      if (file.size >= maxSize) {
        console.log("greater than 1mb")
        this.fileSizeWithinLimit = false;
        return;
      }
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        const result = (<FileReader>event.target).result;
        this.postImage = result as string;
        image.patchValue(this.postImage);
      };
      reader.readAsDataURL(file);
    }
  }
 async deleteNews(){
    try {
      await this.loginService.tokenRenewal();
     await this.News.deleteNewsDetails(this.newsId).subscribe((data) => {
        if (data.statusCode == 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'News deleted successfully',
          });
          setTimeout(() => {
            this.navigateToNewsList();
          }, 1000);
        }
      }, (error: any) => {   
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in news delete api", {payload: JSON.stringify(this.newsId),error:JSON.stringify(error)})
        if (error.status == 401) {
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'sessionExpiry',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        } else if (error.status == 500) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Something went wrong at the server side!',
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: error.error.responseException.message,
          });
        }
      });
    } catch (error) {
      this.appinsightsService.logException(error,2)
    }
  }
  navigateToNewsList(){
    this.router.navigate(['/adminDashboard/news']);
  }

  closeDelete(){
    this.displayDeleteDialog = false;
    }
}
