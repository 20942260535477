import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  constructor(private http: DataService) { }

  verifyUserWithGuid(guid){
    let url = `api/StagedUsers/${guid}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  verifyUserWithGuidForPasswordReset(guid){
    let url = `api/Users/guid/${guid}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  getRoles(){
    let url = 'api/Roles';
    const headers = new HttpHeaders({'Content-Type': 'application/json'
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  registerUser(userRegistrationData,guid){
    let url = `api/Users?guid=${guid}`;
    const requestHeader = new HttpHeaders({'Content-Type': 'application/json'})
    return this.http.post(url,userRegistrationData,{headers: requestHeader}).pipe(map(data => data))       
  }
  getUsers(){
    let url = 'api/Users';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  deleteUser(lefa){
    let url = `api/Users/${lefa}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.delete(url, { headers: headers})
    .pipe(map(data => data));
  }

  getUserDetails(lefa){
    let url = `api/Users/${lefa}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  updateUserInfo(userId,userObject){
    let url = `api/Users/${userId}`;
    const requestHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.put(url,userObject,{headers: requestHeader}).pipe(map(data => data))       
  }
}
