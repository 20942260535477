<div class="container-fluid py-5 mb-5">
    <p-toast position="top-center"></p-toast>
    <div class="row" *ngIf="!showErrorPanel&&!showSucessMessage">
        <div class="col-12 col-md-4 offset-md-4 px-md-4 shadow py-3">
            <div class="row py-2">
                <div class="col-12 text-center">
                    <h4 class="burgendy fw-semibold">Create Password</h4>
                    <p class="m-0 text_muted">Just one more step before you log in</p>
                </div>
            </div>
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit(forgotPasswordForm.value)">
                <div class="row">
                    <div class="col-12 pt-2">
                        <label class="fs-6" for="newPassword">New Password:</label>
                        <div class="input-container clsControl form-control">
                            <input pInputText name="newPassword" id="newPassword" [type]="passwordType" (keyup)="validatePassword($event)"
                                class="clsControl form-control inputField" formControlName="newPassword" />
                            <p-button class="eyeIcon" aria-label="Show Password" tabindex="0" *ngIf="showeye === true"
                                (click)="togglepw()">
                                <span class="p-viewer2">
                                    <i class="pi pi-eye eyeicon pr-2 cursor-pointer" 
                                        aria-hidden="true"></i>
                                </span>
                            </p-button>
                            <p-button class="eyeIcon" aria-label="Hide Password" tabindex="0" *ngIf="hideeye === true"
                                (click)="togglepw()">
                                <span class="p-viewer2">
                                    <i class="pi pi-eye-slash eyeicon pr-2 cursor-pointer"
                                        aria-hidden="true"></i>
                                </span>
                            </p-button>
                        </div>
                        <div class="error"
                            *ngIf="!forgotPasswordForm?.controls['newPassword']?.valid&&forgotPasswordForm?.controls['newPassword']?.dirty">
                            <span class="small"
                            *ngIf="forgotPasswordForm?.controls['newPassword']?.errors['pattern']">Password Invalid</span>
                            <span class="small"
                                *ngIf="forgotPasswordForm?.controls['newPassword']?.errors['required']">{{globalResource.passwordRequired}}</span>
                        </div>
                    </div>
                    <div class="col-12 pt-2">
                        <label class="fs-6" for="confirmPassword">Confirm Password:</label>
                        <div class="input-container clsControl form-control">
                            <input pInputText name="confirmPassword" id="confirmPassword" [type]="confirmPasswordType"
                                class="clsControl form-control inputField" formControlName="confirmPassword" (keyup)="validatePassword($event)" />
                            <p-button class="eyeIcon" aria-label="Show Password" tabindex="0" *ngIf="confirmPwShoweye === true"
                                (click)="toggleConfirmPw()">
                                <span class="p-viewer2">
                                    <i class="pi pi-eye eyeicon pr-2 cursor-pointer" 
                                        aria-hidden="true"></i>
                                </span>
                            </p-button>
                            <p-button class="eyeIcon" aria-label="Hide Password" tabindex="0" *ngIf="confirmPwHideeye === true"
                                (click)="toggleConfirmPw()">
                                <span class="p-viewer2">
                                    <i class="pi pi-eye-slash eyeicon pr-2 cursor-pointer"
                                        aria-hidden="true"></i>
                                </span>
                            </p-button>
                        </div>
                        <div class="error"
                            *ngIf="!forgotPasswordForm?.controls['confirmPassword']?.valid&&forgotPasswordForm?.controls['confirmPassword']?.dirty">
                            <span class="small"
                                *ngIf="forgotPasswordForm?.controls['confirmPassword']?.errors['pattern']">Password Invalid</span>
                            <span class="small"
                                *ngIf="forgotPasswordForm?.controls['confirmPassword']?.errors['required']">{{globalResource.passwordRequired}}</span>
                        </div>
                        <span class="error small"
                             *ngIf="showPasswordValidationError&&forgotPasswordForm.controls['confirmPassword'].value != null">New
                        New Password and Confirm Password should be same</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h6 class="pt-3 fw-semibold burgendy m-0">Password requirements:</h6>
                        <div>
                            <p class="m-0 skyCloud small">Minimum 8 characters long-the more, the better</p>
                            <p class="m-0 skyCloud small">At least one lowercase character</p>
                            <p class="m-0 skyCloud small">At least one uppercase character</p>
                            <p class="m-0 skyCloud small">At least one number, symbol</p>
                            <p class="m-0 skyCloud small">Password should not start with Number or _(underscore)</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex align-items-center">
                        <p-checkbox formControlName="terms" value="New York" inputId="ny"></p-checkbox>
                        <label for="ny" class="p-1"><small>I agree to <a routerLink="/terms&condition" class="burgendy text-decoration-none">Terms and conditions</a> and <a routerLink="/privacy" class="burgendy text-decoration-none">Privacy Policy</a></small></label>
                    </div>
                    <div class="error"
                    *ngIf="!forgotPasswordForm?.controls['terms']?.valid&&forgotPasswordForm?.controls['terms']?.dirty">
                    <span class="small"
                        *ngIf="forgotPasswordForm?.controls['terms']?.errors['required']">Check Required</span>
                </div>
                </div>
                <div class="row justify-content-center pt-3">
                    <div class="col-12 text-center">
                        <button aria-label="Continue" style="display: flex;
                        align-items: center;width: 100%;height: 35px;
                     " tabindex="0" type="submit" class="submit_Btn">
                            <div class="row" style="width:100%">
                                <div class="col-12">
                                    <span style="font-size: 16px;">Continue</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row"  *ngIf="showErrorPanel&&!showSucessMessage">
        <div class="col-12 col-md-10 offset-md-1 shadow text-center pb-5 mb-5">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 text-centerpx-5 py-4">
                    <div>
                        <img src="assets/images/timeout.png" alt="Timeout" class="img-fluid" style="height: 130px; width: 130px;">
                    </div>
                    <div class="px-md-5">
                        <h4 class="fw-semibold text-dark py-2">Forgot Password link has expired</h4>
                        <p class="text__ash px-md-5">The link you are trying to access has expired. Regenerate the link by clicking <span class="burgendy fw-semibold cursor-pointer" (click)="openForgotPasswordPopUp()">ForgotPassword</span> or
                            Please contact our  <span class="burgendy fw-semibold cursor-pointer" (click)="navigateToContactUs()">Support Team</span> for more information.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="showSucessMessage">
        <div class="col-12 col-md-4 offset-md-4 px-md-4 shadow py-3 text-center">
            <div class="py-3">
                <svg xmlns="http://www.w3.org/2000/svg" height="3.25em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#37a575}</style><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
            </div>
            <h4 class="py-3">Your password has been successfully changed!</h4>
            <div class="py-3 d-flex align-items-center justify-content-center">
                <div>
                    <p-progressSpinner
                    styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                    animationDuration=".5s"></p-progressSpinner>
                </div>
                <div class="p-2">
                    <p class="text__ash m-0">Redirecting to <span class="burgendy">Home page</span> in {{timerCount}}</p>
                </div>
            </div>
        </div>
    </div>
</div>