import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private http: DataService,
    private loginDetails: LoginService
  ) { }
  public selectedNewsId = new BehaviorSubject(0);
  private headers = new Headers({
    'Content-Type': 'application/json',
  });
  getnewslists(count,includeDescription){
    let url = `api/News?top=${count}&includeDescription=${includeDescription}`;
   const headers = this.loginDetails.setHeaders();
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  postnewslists(newscreate){
    let url = 'api/News';
    const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
    'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.post(url,newscreate,{headers: requestHeader}).pipe(map(data => data))       
  }
  postnewslist(newsid, newscreate){
    let url = `api/News/${newsid}`;
    const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
    'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
   
    return this.http.put(url,newscreate,{headers: requestHeader}).pipe(map(data => data))   

  }
  
  deleteNewsDetails(newsid){
    let url = `api/News/${newsid}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.delete(url, { headers: headers})
    .pipe(map(data => data));
  }
  getnewsoverview(newsid){
    let url = `api/News/NewsOverview/${newsid}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  getSingleNews(newsid,includeDescription)
  {
    let url = `api/News/${newsid}?includeDescription=${includeDescription}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  editNews(newsid,includeDescription){
    let url = `api/News/${newsid}?includeDescription=${includeDescription}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  updateViews(api,id,isViewed){
    let url = `api/${api}/${id}/Views?isViewed=${isViewed}`;
    const requestHeader = this.loginDetails.setHeaders();
    return this.http.putWithoutBody(url,{headers: requestHeader}).pipe(map(data => data))  
  }

}
