import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class EventDetailsService {
  ticketStatusOptions=[
    {
      id:'0', value:'--Select--'
    },{
    id:'ON_SALE', value:'ON_SALE'
  },{
    id:'SOLD_OUT', value:'SOLD_OUT'
  },{
    id:'UNAVAILABLE', value:'UNAVAILABLE'
  },{
    id:'HIDDEN', value:'HIDDEN'
  },{
    id:'ADMIN_ONLY', value:'ADMIN_ONLY'
  },{
    id:'LOCKED', value:'LOCKED'
  }]
  timeValue=[{
    id:'00:00:00',value:'00:00'
  },{
    id:'00:30:00',value:'00:30'
  },{
    id:'01:00:00',value:'01:00'
  },{
    id:'01:30:00',value:'01:30'
  },{
    id:'02:00:00',value:'02:00'
  },{
    id:'02:30:00',value:'02:30'
  },{
    id:'03:00:00',value:'03:00'
  },{
    id:'03:30:00',value:'03:30'
  },{
    id:'04:00:00',value:'04:00'
  },{
    id:'04:30:00',value:'04:30'
  },{
    id:'05:00:00',value:'05:00'
  },{
    id:'05:30:00',value:'05:30'
  },{
    id:'06:00:00',value:'06:00'
  },{
    id:'06:30:00',value:'06:30'
  },{
    id:'07:00:00',value:'07:00'
  },{
    id:'07:30:00',value:'07:30'
  },{
    id:'08:00:00',value:'08:00'
  },{
    id:'08:30:00',value:'08:30'
  },{
    id:'09:00:00',value:'09:00'
  },{
    id:'09:30:00',value:'09:30'
  },{
    id:'10:00:00',value:'10:00'
  },{
    id:'10:30:00',value:'10:30'
  },{
    id:'11:00:00',value:'11:00'
  },{
    id:'11:30:00',value:'11:30'
  },{
    id:'12:00:00',value:'12:00'
  },{
    id:'12:30:00',value:'12:30'
  },{
    id:'13:00:00',value:'13:00'
  },{
    id:'13:30:00',value:'13:30'
  },{
    id:'14:00:00',value:'14:00'
  },{
    id:'14:30:00',value:'14:30'
  },{
    id:'15:00:00',value:'15:00'
  },{
    id:'15:30:00',value:'15:30'
  },{
    id:'16:00:00',value:'16:00'
  },{
    id:'16:30:00',value:'16:30'
  },{
    id:'17:00:00',value:'17:00'
  },{
    id:'17:30:00',value:'17:30'
  },{
    id:'18:00:00',value:'18:00'
  },{
    id:'18:30:00',value:'18:30'
  },{
    id:'19:00:00',value:'19:00'
  },{
    id:'19:30:00',value:'19:30'
  },{
    id:'20:00:00',value:'20:00'
  },{
    id:'20:30:00',value:'20:30'
  },{
    id:'21:00:00',value:'21:00'
  },{
    id:'21:30:00',value:'21:30'
  },{
    id:'22:00:00',value:'22:00'
  },{
    id:'22:30:00',value:'22:30'
  },{
    id:'23:00:00',value:'23:00'
  },{
    id:'23:30:00',value:'23:30'
  }]
  constructor(
    private http: DataService,
    private loginDetails: LoginService
  ) { }
  private headers = new Headers({
    'Content-Type': 'application/json'
  });

  createEvent(eventDetails){
    let url = 'api/Events';
    const requestHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.post(url,eventDetails,{headers: requestHeader}).pipe(map(data => data))       
  }
  editEvent(eventDetails){
    let url = 'api/Events';
    const requestHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.put(url,eventDetails,{headers: requestHeader}).pipe(map(data => data))       
  }
  getEvents(count){
    let url = `api/Events?top=${count}`;
    const headers = this.loginDetails.setHeaders();
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }


  getEditoverview(eventId){
    let url = `api/Events/EventOverview/${eventId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  getAllEventsForPublic(){
    let url = 'api/Events/grouped';
    const headers = this.loginDetails.setHeaders();
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  getEventDetails(eventId){
    let url = `api/Events/${eventId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  deleteEventDetails(eventId){
    let url = `api/Events/${eventId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.delete(url, { headers: headers})
    .pipe(map(data => data));
  }
  getEventDateAndTimeDetails(eventId){
    let url = `api/Events/EventOccurence/${eventId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }


  getTicket(eventId,eventSeriesId){
    let url = `api/Events/IssuedTickets?eventId=${eventId}&eventSeriesId=${eventSeriesId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  getMyTickets(){
    let url = 'api/Events/MyTickets';
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
}
