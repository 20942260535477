<div class="container-fluid pb-5">
    <div class="row pt-3 px-md-5">
        <div class="col-12">
            <div (click)="navigateToNews()">
                <i class="fa fa-arrow-left p-2 burgendy cursor-pointer"></i><span class="burgendy cursor-pointer">Back to News</span>
            </div> 
        </div>
        <div class="col-12 col-md-8 px-md-5">
            <div class="row" *ngIf="!detailNewsLoader">
                <div class="col-12">
                    <div>
                        <h3 class="burgendy fw-semibold">{{selectedNews?.heading}}</h3>
                    </div>
                    <div>
                        <div class="d-md-flex align-items-center py-2">
                            <div class="py-1">
                                <i class="fa fa-user-circle-o creamy rounded-circle p-2"></i><span class="burgendy fw-semibold px-2 mr-2">{{ selectedNews?.department}}</span>
                            </div>
                            <div class="py-1">
                                <i class="fa fa-calendar p-2"></i><span class="px-2">{{selectedNews?.publishedDate | date:'MMM dd yyyy'}}</span>
                            </div>
                        </div>
                        <div class="col-12 position-relative image-container w-100 p-0">
                            <img alt="Card" src="{{ selectedNews?.image}}" alt="News-image"
                                class="w-100 h-100 img-fixed-size bg-black"/>
                        </div>
                        <div class="py-3">
                            <p-editor class="pEditor__Readonly w-100" [(ngModel)]="selectedNews.description"
                                [readonly]="true"></p-editor>
                        </div>
                        <div class="py-2 text-end" >
                            <small class="fw-semibold"><span class="p-1">Share this News</span><i
                                    class="pi pi-share-alt burgendy border rounded-circle p-3 cursor-pointer" (click)="copyNews()"></i></small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="detailNewsLoader">
                <div class="col-12 text-center d-flex align-items-center justify-content-center">
                    <p-progressSpinner
                    styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                    animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-4">
            <div class="row" *ngIf="!mostRecentNewsLoader">
                <div class="col-12">
                    <div class="bg-burgendy p-2 d-flex align-item-center">
                        <h4 class="text-white m-0">Most Recent</h4>
                    </div>
                    <div class="row py-3 m-0" *ngFor="let news of newsList; let i = index" [ngClass]="{'bg-ashDark': i%2 == 0}">
                        <div class="col-12 cursor-pointer" (click)="onSelectNews(news)">
                            <div class="row">
                                <div class="col-9">
                                    <h5 class="fw-semibold custom_width__ForTextWrap text-truncate">{{news?.heading}}</h5>
                                    <p-editor class="pEditor__Readonly text-wrap   w-100 " [ngClass]="{'editor_alter': i%2 == 0}"
                                        [(ngModel)]="news.description" [readonly]="true"></p-editor>
                                </div>
                                <div class="col-3 d-flex align-item-center justify-content-center flex-column">
                                    <img src="{{ news?.image}}" alt="News Image" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="mostRecentNewsLoader">
                <div class="col-12 text-center">
                    <p-progressSpinner
                    styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                    animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
    </p-confirmDialog>