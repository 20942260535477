<div class="container-fluid pb-5">
    <div class="row mt-3 d-md-flex align-item-center">
        <div class="col-12 col-md-6 px-5">
            <h1 class="burgendy fw-semibold">Events</h1>
        </div>
        <div class="col-12 col-md-6 px-5 text-md-end">
            <p-button label="Create Event" class="primeNgButton" type="submit" icon="pi pi-plus" iconPos="left" (click)="navigateToCreateEvent()"></p-button>
        </div>
    </div>
    <div class="row" *ngIf="!loader">
        <div class="col px-5">
            <p-table #eventsListData [value]="eventsList" sortMode="single" [responsive]="true" [rows]="10" class="pTable"
            sortOrder="-1" [paginator]="true"  [rowsPerPageOptions]="paginationValues" [globalFilterFields]="['name','location','eventDate','eventCreatedDate','status','createdBy']">
            <ng-template pTemplate="caption">
                <div class="row align_inline">
                    <div class="col-12 col-md-4">
                       <p-dropdown [options]="allEventListOptions" placeholder="-- Select --" [(ngModel)]="selectedOption" optionLabel="status" (onChange)="filterDataBasedOnSelectedStatus($event)"></p-dropdown>
                    </div>
                    <div class="col-12 col-md-4 d-flex" style="text-align: right">
                        <div class="form-group has-search text-center d-inline">
                            <span class="fa fa-search form-control-feedback text-center text-black mt-1 d-block"></span>
                            <input  type="text"    (input)="eventsListData.filterGlobal($event.target.value, 'contains')"  class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2 text-black placeHolder-color" autocomplete="off" pInputText size="50" placeholder=" Search..." > 
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'name'" class="fw-semibold">Event Name<p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'Location'" class="fw-semibold">Location <p-sortIcon [field]="'location'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'next_occurrence_date'" class="fw-semibold">Event Date<p-sortIcon [field]="'next_occurrence_date'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'eventCreatedDate'" class="fw-semibold">Created Date<p-sortIcon [field]="'eventCreatedDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'status'" class="text-center fw-semibold">Status<p-sortIcon [field]="'status'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'createdBy '" class="fw-semibold">Created By<p-sortIcon [field]="'createdBy'"></p-sortIcon>
                    </th>

                    <th class="text-center" class="fw-semibold" >Actions
                    </th>
                </tr> 
            </ng-template>
            <ng-template pTemplate="body" let-eventList let-rowData let-columns="columns" let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                    <td><a class="text-decoration-underline text-primary cursor-pointer text__wrap" (click)="eventOverView(eventList)">{{eventList?.name }} </a> </td>    
                    <td >{{eventList?.location }}  </td>    
                    <td >{{eventList?.eventStartDateAndTime | date:'dd MMM yyyy' }}  </td>    
                    <td >{{eventList?.eventCreatedDate  |  date:'dd MMM yyyy'}}  </td>    
                    <td >{{eventList?.status }}  </td>    
                    <td > {{eventList?.createdBy}} </td>    
                    <td class="d-flex align-items-center justify-content-center">
                        <a (click)="editEvent(eventList)" >
                            <span class="fa fa-pencil cursor-pointer m-2"></span>
                        </a>
                        <a  (click)="editDelete(eventList)">
                            <span class="fa fa-trash cursor-pointer m-2"></span>
                        </a>
                    </td>    
                </tr>
            </ng-template>
        </p-table>
        </div>
    </div>
    <div class="row" *ngIf="loader">
        <div class="col-12 text-center">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>

<p-confirmDialog key="toastMsg" [style]="{width: '50vw'}"></p-confirmDialog>