import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NewsService } from 'src/app/services/news.service';
import { Title } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
@Component({
  selector: 'app-news-delete',
  templateUrl: './news-delete.component.html',
  styleUrls: ['./news-delete.component.css']
})
export class NewsDeleteComponent implements OnInit {
  newsId:any;
  displayDeleteDialog: boolean = true;
  constructor(   private route: ActivatedRoute,
    private router: Router,
    private News: NewsService ,
    private loginService: LoginService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, 
    private loaderService: LoaderService, 
    private titleService: Title,
    private appinsightsService: LoggingService,) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('News Delete screen visited')
    this.titleService.setTitle('News Delete');
    this.route.params.forEach((urlParams) => {
      this.newsId = urlParams['newsId'];
      this.loginService.SelectednewsID.next(this.newsId)
    });
  }
  showDeleteDialog(): void {
    this.displayDeleteDialog = true;
  }
  navigateToDelete(){
    this.router.navigate(['/adminDashboard/newssummary/newsoverview/',this.newsId]);
  }
  navigateToNewsList(){
    this.router.navigate(['/adminDashboard/news']);
  }
 async deleteNews(){
    try {
      this.loaderService.display(true);
      // await this.loginService.tokenRenewal();
      await this.News.deleteNewsDetails(this.newsId).subscribe((data) => {
        if (data.statusCode == 200) {
          this.loaderService.display(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'News deleted successfully',
          });
          setTimeout(() => {
            this.navigateToNewsList();
          }, 1000);
        }
      }, (error: any) => {   
        this.loaderService.display(false);
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting news delete api", {payload: JSON.stringify(this.newsId),error:JSON.stringify(error)})
        if (error.status == 401) {
          this.loaderService.display(false);
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: 'toastMsg',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        } else if (error.status == 500) {
          this.loaderService.display(false);
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Something went wrong at the server side!',
          });
        } else {
          this.loaderService.display(false);
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: error.error.responseException.message,
          });
        }
      });
    } catch (error) {
      this.loaderService.display(false);
      this.appinsightsService.logException(error,2)
    }
  }

}
