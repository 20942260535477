<div class="container-fluid p-0 ">
    <div class="row m-0 justify-content-center pt-3">
        <div class="col p-0">
                <ul class="text-black list-unstyled">
                        <li class="menu cursor-pointer p-3" [routerLink]="'/adminDashboard/eventsSummary/eventOverView/'+ eventId" routerLinkActive="activeClass"><i
                                class="fa fa-th-large" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Overview</span></li>              
                                <li class="menu cursor-pointer p-3"  [routerLink]="'/adminDashboard/eventsSummary/issuedTicket/'+ eventId" routerLinkActive="activeClass"><i
                                        class="fa fa-calendar-times-o" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Tickets Issued </span></li>
                        <li class="menu cursor-pointer p-3 " [routerLink]="'/adminDashboard/eventsSummary/editEvent/'+ eventId" routerLinkActive="activeClass"><i
                                class="fa fa-file-text" style="padding-right: 10px;"></i><span class="hide_textMenu p-0">Edit Event</span></li>
                </ul>
        </div>
</div>
</div>