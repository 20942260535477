import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output,  ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { LoginService } from 'src/app/services/login.service';
import { BlogList } from '../blog-list/blog-list';
import jwt_decode from 'jwt-decode';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { blogcommen, restructuredComments } from './blogcommen';
import { Title } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { NestedLoopDirective } from 'src/app/directives/nested-loop.directive';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-blog-public',
  templateUrl: './blog-public.component.html',
  styleUrls: ['./blog-public.component.css']
})
export class BlogPublicComponent implements OnInit {
  createNewsCommentForm: FormGroup;
  createReplyCommentForm: FormGroup;
  blogsList: BlogList[] = [];
  blogcommen: blogcommen[]=[];
  selectedOption: number = 0;
  blogid: any;
  selectedBlogs:BlogList;
  Comment:FormGroup;
  isUserLoggedIn: boolean = false;
  loggedInUserData: any;
  decodedToken: any;
  blogName: string ='';
  commentForm: FormGroup;
  submitted: Boolean = false;
  public id = 0;
  selectedblog:any;

  isLiked: boolean = false;
 childCommentId: Array<object> = [];
  @Output() userReplycomment = new EventEmitter();
  @Output() deletNo = new EventEmitter();
  currentParentCommentId: number = null;
  comments: Comment[] = [];
  likes  :boolean =  false;
  lefa: any;
  @Output() usercomment = new EventEmitter();
  @Output() countComments = new EventEmitter();
  public loadComponent = false;
  public showChildForm: boolean = false;
  public commentIndex = 0;
  public postComment: Array<{ content: string,  commentedAt: Date, }> = [];
  public reply: Array<object> = [];
  public  replyComment: Array<object> = [];

  tooltipMessage = 'Copy';
  showMessage: boolean = false;
  tooltipVisible = false;
  selectedBlogId:any;
  copyText=window.location.origin;
  likedBlogId: any[] = [];
  commentsList:any;
  showReply: boolean = false;
  detailedBlogLoader: boolean = false;
  mostRecentBlogLoader: boolean = false;
  constructor( 
    private confirmationService: ConfirmationService, 
    private BlogDetails:BlogDetailsService,
    private messageService: MessageService,
    private loginService: LoginService,
    private router: Router,
    private fb: FormBuilder, 
    private titleService: Title,
    private NewsDetails: NewsService,
    public viewContainerRef: ViewContainerRef,
    private appinsightsService: LoggingService,
    private route: ActivatedRoute, ) { }

  ngOnInit(): void {  
    try {
      this.appinsightsService.logEvent('Blog Detail view screen visited')
      this.titleService.setTitle('Blog details');
      this.route.params.forEach((urlParams) => {
        this.selectedBlogId = urlParams['id'];
      });
      this.getLoggedInUserData();
      this.likedBlogId = JSON.parse(localStorage.getItem('likedBlogId'))?JSON.parse(localStorage.getItem('likedBlogId')):[]
      this.getBlogsList();
      this.sendViewData();
      this.createNewsCommentForm = this.fb.group({
        comment: new FormControl(
          null,
          Validators.compose([Validators.required])
        ),
      })
      this.createReplyCommentForm =  this.fb.group({
        replyComment: new FormControl(null, Validators.compose([Validators.required]))
      })
      if(this.selectedBlogId != null){
        this.getSingleBlog(this.selectedBlogId);
      }
    } catch (error) { 
      console.log(error);
    }
  }
  
  sendViewData(){
    try{
      this.NewsDetails.updateViews("Blogs", this.selectedBlogId , true).subscribe((data)=>{
        console.log(data)
      },(error:any)=>{
        if(error.status >= 400 && error.status < 500 ){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else{

        }
      })
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }
  getLoggedInUserData(){
    try{
      if(JSON.parse(localStorage.getItem('loggedInUser'))){
        this.isUserLoggedIn =  true;
        this.loggedInUserData = JSON.parse(localStorage.getItem('loggedInUser'));
      }
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }

  getSingleBlog(id){
    try{
     this.detailedBlogLoader = true;
      this.BlogDetails.getSingleBlog(id,true).subscribe((data)=>{
          if(data.statusCode == 200){
            this.detailedBlogLoader = false;
            this.selectedBlogs = data.result;
            this.selectedBlogs.description =  this.selectedBlogs?.description.replaceAll('"','')
             this.selectedBlogs.tagsList = this.selectedBlogs.tags.split(',');
             this.selectedBlogs.isLiked = false;
             if(this.likedBlogId.length>0){
              this.likedBlogId.map((x)=>{
                if(x == this.selectedBlogs.id){
                  this.selectedBlogs.isLiked = true;
                }
               })
             }
              this.getComments();
          }
      },(error:any)=>{
        this.detailedBlogLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in Blogs api", {payload: JSON.stringify(id),error:JSON.stringify(error)})
        if(error.status == 401){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'sessionExpiry',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
  
      })
    }
    catch(error){
      this.detailedBlogLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }
  getBlogsList(){
    try{
      this.mostRecentBlogLoader = true;
      this.BlogDetails.getBlogs(5,false).subscribe((data)=>{
      if(data){
        this.mostRecentBlogLoader = false;
        this.blogsList = data.result
      } 
    },(error:any)=>{
      this.mostRecentBlogLoader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in Blogs list api", {payload: JSON.stringify(0),error:JSON.stringify(error)})
      if (error.status >= 400 && error.status < 500) {
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:"sessionExpiry",
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null);
            this.router.navigate(['/home']);
          },
        });
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }
    })
  }
  catch(error){
    this.mostRecentBlogLoader = false;
    this.appinsightsService.logException(error,2)
  }
  }

  navigateToBlogs(){
    this.router.navigate(['/Blogs']);
  }

  onSelectBlogs(blog:any){
    try{
      this.selectedBlogId = blog.id;
      this.getSingleBlog(blog.id)
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }

  copyBlogs() {
    try{
    this.showMessage = true;
    const textToCopy = this.copyText+ '/blog/' +this.selectedBlogId;
    const tempInput = document.createElement("textarea");
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); 
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.tooltipMessage = 'Copied!';
    this.tooltipVisible = true;
    this.messageService.add({
      severity:'success', 
      detail: 'Link copied'
    });
    setTimeout(() => {
      this.tooltipVisible = false;
      this.tooltipMessage = 'Copied';
    }, 1500);
  }
  catch(error:any){
    this.appinsightsService.logException(error,2)
  }
}


onLike(selectedBlogData,isliked){
  try{
    if(this.likedBlogId.indexOf(selectedBlogData.id) == -1){
      this.likedBlogId.push(selectedBlogData.id)
      selectedBlogData.isLiked = isliked;
    }
    else{
      const index = this.likedBlogId.indexOf(selectedBlogData.id);
      selectedBlogData.isLiked = isliked;
      this.likedBlogId.splice(index,1);
    }
    this.BlogDetails.BlogLike(selectedBlogData.id,  selectedBlogData.isLiked ).subscribe((data)=>{
        if(data.statusCode == 200){
          localStorage.setItem('likedBlogId',JSON.stringify(this.likedBlogId))
        }
    },(error)=>{
      localStorage.removeItem('liked');
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in Blogs like api", {payload: JSON.stringify(selectedBlogData.id),error:JSON.stringify(error)})
      this.appinsightsService.logEvent("Error: error in Blogs like api", {payload: JSON.stringify(selectedBlogData.isLiked),error:JSON.stringify(error)})
      if (error.status >= 400 && error.status < 500) {
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:"sessionExpiry",
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null);
            this.router.navigate(['/home']);
          },
        });
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }
    })
  }
  catch(error){
    this.appinsightsService.logException(error,2)
  }
}
onSubmit(formData:any,isComment,parentDetails?){
  try{
    if(isComment? this.createNewsCommentForm.valid  : this.createReplyCommentForm.valid){
      let commentObj = 
        {
          "userId": this.loggedInUserData.userId,
          "blogId": this.selectedBlogs.id,
          "blogName": this.selectedBlogs.title,
          "parentCommentId": !isComment? parentDetails.id : null,
          "commented": true,
          "commentedAt": new Date(),
          "updatedAt": new Date(),
          "content": !isComment?formData.replyComment :formData.comment,
          "isDeleted": false
        }
        this.BlogDetails.comments(commentObj).subscribe((data)=>{
          if(data.statusCode == 201){
            this.messageService.add({
              severity:'success', 
              summary: 'Success', 
              detail: data.result
            });
          }
          this.createNewsCommentForm.reset();
          this.createReplyCommentForm.reset();
          this.getComments();
        },(error)=>{
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in Blog comments api", {payload: JSON.stringify(commentObj),error:JSON.stringify(error)})
          if (error.status >= 400 && error.status < 500) {
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key:"sessionExpiry",
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          }
          else if(error.status == 500){
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: "Something went wrong at server side!"
            });
          }
          else{
            this.messageService.add({
              severity:'warn', 
              summary: 'Warning', 
              detail: error.error.responseException.message
            });
          }
        })
    }
  }
  catch(error){
    this.appinsightsService.logException(error,2)
  }
}

getComments(){
  try{
    this.commentsList = [];
    this.detailedBlogLoader = true;
    this.BlogDetails.getCommentsList(this.selectedBlogs.id).subscribe((data)=>{
      if(data.statusCode == 200){
        this.detailedBlogLoader = false;
        this.commentsList = data.result
      }
    },(error)=>{
      this.detailedBlogLoader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in Blogs api", {payload: JSON.stringify(this.selectedBlogs.id),error:JSON.stringify(error)})
      if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'sessionExpiry',
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);
          },
        })
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else if(error.status == 400){

      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.title
        });
      }
    })
  }
  catch(error){
    this.detailedBlogLoader = false;
    this.appinsightsService.logException(error,2)
  }
}
showReplies(item,list){
  // item.showReply = true;
  list.map((x)=>{
    if(x.id == item.id){
      x.showReply = true;
    }
    else{
      x.showReply= false;
      if(x.children && x.children.length > 0){
        this.showReplies(item,x.children)
      }
    }
  })
  console.log("commentsList",this.commentsList)
}
addReply(item,list){
  let foundItem = false;
  list.map((x)=>{
    if(x.id == item.id){
      foundItem = true;
      x.addReply = true;
    }
    else{
      x.addReply= false;
      if(x.children && x.children.length > 0){
        this.addReply(item,x.children)
      }
    }
  })
}
}
