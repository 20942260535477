import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { LoginService } from 'src/app/services/login.service';
import * as _ from 'lodash';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { TimeZoneService } from 'src/app/services/time-zone.service';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})
export class EditEventComponent implements OnInit {
  createNewEvent: FormGroup;
  eventId:any;
  dateAndTimeData:any;
  ticketIdsForTicketInfo:any;
  loader: boolean = false;
  editLoader: boolean = false;
  eventStatusOptions=[
    {id:'0', value:'--Select--'},
    {id:'DRAFT', value:'DRAFT'},
    {id:'PUBLISHED', value:'PUBLISHED'},
    {id:'CLOSE_SALES', value:'CLOSE_SALES'}
  ]
  timeZone:any[];
  minEndDate = new Date();
  ticketStatusOptions:any;
  startTimeValue:any;
  endTimeValue: any;
  minStartDate = new Date();
  showSaveButton: boolean = false;
  constructor(
    private fb: FormBuilder, 
    private titleService: Title,
    private EventDetails:EventDetailsService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private loginService: LoginService, 
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
    private timeZon : TimeZoneService,
    private loginDetails: LoginService
  ) { this.timeZone = timeZon.timeZone; }

  items = new FormArray([], this.customGroupValidation);
  arrayItems: FormArray;

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event edit screen visited')
    this.titleService.setTitle('Edit Event');
    this.route.params.forEach((urlParams) => {
      this.eventId = urlParams['eventId'];
      this.loginService.SelectedEventId.next(this.eventId)
    });
    this.ticketStatusOptions = this.EventDetails.ticketStatusOptions;
    this.startTimeValue =  JSON.parse(JSON.stringify(this.EventDetails.timeValue));
    this.endTimeValue = JSON.parse(JSON.stringify(this.EventDetails.timeValue));
    this.createNewEvent = this.fb.group({
      eventName: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventStartsOnCalendar:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventStartsOnTime:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventEndsOnCalendar:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      timeZone:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      eventEndsOnTime:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      venue:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      postcode:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      description:new FormControl(null,Validators.compose([Validators.required])),
      eventStatus:new FormControl(null,Validators.compose([Validators.required])),
      items: new FormArray([], this.customGroupValidation),
    });
    this.addItem();

    if(this.eventId){
      this.editEvent(this.eventId);
      this.getEventDateTimeDetails(this.eventId);
    }
  }
  get TicketDetailsArr(): FormArray {
    return this.createNewEvent.get('items') as FormArray;
  }

  addItem(): void {
    // this.sysmbol=0;
    // this.TicketDetailsArr.push(this.createItem());
    this.arrayItems = this.createNewEvent.get('items') as FormArray;
    this.arrayItems.push(this.createItem());
  }
  createItem(): FormGroup {
    return this.fb.group({
      ticketName: new FormControl(null, Validators.compose([Validators.required])),
      price: new FormControl(null, Validators.compose([Validators.required, Validators.pattern("[0-9]+(\.[0-9]+)?")])),
      bookingprice:new FormControl(null, Validators.compose([Validators.pattern("[0-9]+(\.[0-9]+)?")])),
      quantity: new FormControl(null, Validators.compose([Validators.required, Validators.pattern("[0-9]+")])),
      status: new FormControl(null, Validators.compose([Validators.required])),
      ticketId:new FormControl(null),
    });
  }
  deleteItem(index: number) {
    const control = <FormArray>this.createNewEvent.controls['items'];
    if (control.length !== 1) {
      control.removeAt(index);
    }
  }

  customGroupValidation (formArray) {
    let isError = false;
    const result = _.groupBy( formArray.controls , c => {
      return [c.value.ticketName];
    });

    for (const prop in result) {
        if (result[prop].length > 1 && result[prop][0].controls['ticketName'].value !== null) {
          isError = true;
            _.forEach(result[prop], function (item: any, index) {
             
              item._status = 'INVALID';
            });
        } else {
            result[prop][0]._status = 'VALID';
            
        }
    }
    if (isError) { 
      return {'duplicate': 'duplicate entries'};
     }
     return null;
}

 async onSubmit(formdata: any) {
    try {
      if (this.createNewEvent.valid) {
        this.editLoader = true;
        let eventDetailsObj = {
          eventSeriesId: this.eventId,
          eventOccurenceId: this.dateAndTimeData.id,
          currency: "usd",
          name: formdata.eventName,
          description: formdata.description,
          postalCode: formdata.postcode,
          venue: formdata.venue,
          timeZone: formdata.timeZone.name,
          startDate: this.datePipe.transform(formdata.eventStartsOnCalendar, 'yyyy-MM-dd'),
          startTime: formdata.eventStartsOnTime.id,
          endDate: this.datePipe.transform(formdata.eventEndsOnCalendar, 'yyyy-MM-dd'),
          endTime: formdata.eventEndsOnTime.id,
          eventStatus: formdata.eventStatus.id,
          ticketTypeRequests: [],
        };
    
        this.TicketDetailsArr.controls.forEach((element: any, index) => {
          eventDetailsObj.ticketTypeRequests.push({
            ticketName: element.value.ticketName,
            ticketPrice: parseInt(element.value.price),
            bookingFee:  element.value.bookingprice != null? parseInt(element.value.bookingprice) : 0,
            ticketDescription: " ",
            ticketStatus: element.value.status.value,
            ticketTypeId: element.value.ticketId,
            quantity: parseInt(element.value.quantity),
          });
        });
         await this.loginDetails.tokenRenewal();
        await this.EventDetails.editEvent(eventDetailsObj).subscribe(
          (data) => {
           this.editLoader = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Event updated successfully',
            });
            this.createNewEvent.reset();
            setTimeout(() => {
              this.router.navigate(['/adminDashboard/eventsList']);
            }, 1500);
          },
          (error: any) => {
            this.editLoader = false;
            if (error.status == 401) {
              this.appinsightsService.logException(error,1)
              this.appinsightsService.logEvent("Error: error in Event edit api", {payload: JSON.stringify(eventDetailsObj),error:JSON.stringify(error)})
              this.confirmationService.confirm({
                message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                key: 'sessionExpiry',
                accept: () => {
                  localStorage.clear();
                  this.loginService.SelectedFieldID.next(null);
                  this.router.navigate(['/home']);
                },
              });
            } else if (error.status == 500) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at server side!',
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: error.error.responseException[0],
              });
            }
          }
        );
      } else {
        for (const field in this.createNewEvent.controls) {
          this.createNewEvent.controls[field].markAsDirty();
        }
      }
    } catch (error) {
      this.editLoader = false;
      this.appinsightsService.logException(error,2)
    }
    
  }

 async editEvent(eventId){
    try{
    this.loader = true;
    await this.loginDetails.tokenRenewal();
    await this.EventDetails.getEventDetails(eventId).subscribe((data) => {
      this.loader = false;
      let eventEditData = data.result;
      const timeZone = this.createNewEvent.controls['timeZone'];
      const matchingRole = this.timeZone.filter(time => time.name === eventEditData.timezone);
      timeZone.patchValue(matchingRole[0])
      this.ticketIdsForTicketInfo = data.result.default_ticket_types
      let ticketInformationLength = data.result.default_ticket_types.length;
      if(ticketInformationLength > 1){
        for(let i=1; i<= ticketInformationLength-1; i++){
          this.addItem();
        }
      }
      var itemlist = this.createNewEvent.get('items')['controls'];
      const eventName = this.createNewEvent.controls['eventName'];
      const venue = this.createNewEvent.controls['venue'];
      const postcode = this.createNewEvent.controls['postcode'];
      const description = this.createNewEvent.controls['description'];
      const eventStatus = this.createNewEvent.controls['eventStatus'];
      for(let i=0 ;i<= itemlist.length-1; i++){
        for(let j=0 ; j<= ticketInformationLength-1; j++){
          if(i==j){
            itemlist[i].controls['ticketName'].patchValue(eventEditData?.default_ticket_types[i]?.name)
            itemlist[i].controls['price'].patchValue(eventEditData?.default_ticket_types[i]?.price)
            itemlist[i].controls['bookingprice'].patchValue(eventEditData?.default_ticket_types[i]?.booking_fee)
            itemlist[i].controls['quantity'].patchValue(eventEditData?.default_ticket_types[i]?.quantity_total)
            this.ticketStatusOptions.forEach(x =>{
              if(x.value == eventEditData?.default_ticket_types[i]?.status.toUpperCase()){
                itemlist[i].controls['status'].patchValue(x)
              }
            })
            itemlist[i].controls['ticketId'].patchValue(eventEditData?.default_ticket_types[i]?.id
              )
          }
        }
      }
      eventName.patchValue(eventEditData.name);
      venue.patchValue(eventEditData.venue?.name);
      postcode.patchValue(eventEditData.venue?.postal_code);
      description.patchValue(eventEditData.description);
      this.eventStatusOptions.forEach(x =>{
          if(x.id == eventEditData.status.toUpperCase()){
            eventStatus.patchValue(x)
          }
      })
      if(eventEditData.status.toUpperCase() != 'PUBLISHED'){
        this.showSaveButton = true
      }
      else{
        this.showSaveButton = false
      }
    },(error:any)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting Event details api", {payload: JSON.stringify(eventId),error:JSON.stringify(error)})
      if(error.status == 401){
       this.confirmationService.confirm({
         message: 'Apologies, but your session has timed out. To proceed, please log in again.',
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         key:'sessionExpiry',
         accept: () => {
           localStorage.clear();
           this.loginService.SelectedFieldID.next(null)
           this.router.navigate(['/home']);
         },
       })
     }
     else if(error.status == 500){
       this.messageService.add({
         severity:'warn', 
         summary: 'Warning', 
         detail: "Something went wrong at server side!"
       });
     }
     else{
       this.messageService.add({
         severity:'warn', 
         summary: 'Warning', 
         detail: error.error.responseException.message
       });
     }
    })
  }
  catch(error:any){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  }
 async getEventDateTimeDetails(eventId){
    try{
      this.loader = true;
       await this.loginDetails.tokenRenewal();
    await this.EventDetails.getEventDateAndTimeDetails(eventId).subscribe((data) => {
      this.loader = false;
      this.dateAndTimeData = data.result.data[0]
      const eventStartsOnCalendar = this.createNewEvent.controls['eventStartsOnCalendar'];
      const eventStartsOnTime = this.createNewEvent.controls['eventStartsOnTime'];
      const eventEndsOnCalendar = this.createNewEvent.controls['eventEndsOnCalendar'];
      const eventEndsOnTime = this.createNewEvent.controls['eventEndsOnTime'];
      eventStartsOnCalendar.patchValue(this.dateAndTimeData?.start?.date);
      this.onDateSelection(this.dateAndTimeData?.start?.date);
      // eventStartsOnTime.patchValue(dateAndTimeData?.start?.time);
      eventEndsOnCalendar.patchValue(this.dateAndTimeData?.end?.date);
      eventEndsOnTime.patchValue(this.dateAndTimeData?.end?.time)
      this.startTimeValue.forEach(x =>{
        if(x.value == this.dateAndTimeData?.start?.time){
          eventStartsOnTime.patchValue(x)
          this.onSelectingStartTime(x)
        }
    })
    this.endTimeValue.forEach(x =>{
      if(x.value == this.dateAndTimeData?.end?.time){
        eventEndsOnTime.patchValue(x)
      }
  })
    },(error:any)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting Event time & date details api", {payload: JSON.stringify(eventId),error:JSON.stringify(error)})
      if(error.status == 401){
       this.confirmationService.confirm({
         message: 'Apologies, but your session has timed out. To proceed, please log in again.',
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         key:'sessionExpiry',
         accept: () => {
           localStorage.clear();
           this.loginService.SelectedFieldID.next(null)
           this.router.navigate(['/home']);
         },
       })
     }
     else if(error.status == 500){
       this.messageService.add({
         severity:'warn', 
         summary: 'Warning', 
         detail: "Something went wrong at server side!"
       });
     }
     else{
       this.messageService.add({
         severity:'warn', 
         summary: 'Warning', 
         detail: error.error.responseException.message
       });
     }
    })
  }
  catch(error:any){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
}

onDateSelection(event){
  this.createNewEvent.controls['eventEndsOnCalendar'].reset();
  const yesterdayDate = new Date(event);
  this.minEndDate = yesterdayDate;
}
onSelectingStartTime(event){
  let index = 0;
  this.createNewEvent.controls['eventEndsOnTime'].reset();
  this.startTimeValue.map((x,i)=>{
    if((x.id == event.value.id) || x.id == event.id){
     index = i;
    }
  })
this.endTimeValue.map((x,i)=>{
  if(index&& index != 0){
    x.disabled = true;
  }
  else{
    x.disabled = false;
  }
})
}
}
