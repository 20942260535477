<div class="container-fluid pb-5">
    <div class="row pt-3 px-md-5">
        <div class="col-12">
            <div (click)="navigateToBlogs()">
                <i class="fa fa-arrow-left p-2 burgendy cursor-pointer"></i><span class="burgendy cursor-pointer">Back to Blogs</span>
            </div> 
        </div>
        <div class="col-12 col-md-8 px-md-5">
            <div *ngIf="!detailedBlogLoader">
                <h3 class="burgendy fw-semibold">{{selectedBlogs?.title}}</h3>
            </div>
            <div *ngIf="!detailedBlogLoader">
                <div class="d-md-flex align-items-center py-2">
                    <div class="py-1">
                        <i class="fa fa-user-circle-o creamy rounded-circle p-2"></i><span class="burgendy fw-semibold px-2 mr-2">{{ selectedBlogs?.authorName}}</span>
                    </div>
                    <div class="py-1">
                        <i class="fa fa-calendar p-2"></i><span class="px-2">{{selectedBlogs?.publishedAt | date:'MMM dd yyyy'}}</span>
                    </div>
                </div>
                <div class="col-12 position-relative image-container w-100 p-0">
                    <img src="{{ selectedBlogs?.image}}" alt="Blogs-image"
                        class="w-100 h-100 img-fixed-size bg-black"/>
                </div>
                <div class="py-3" *ngIf="selectedBlogs?.description">
                    <p-editor class="pEditor__Readonly w-100" [(ngModel)]="selectedBlogs.description"
                        [readonly]="true"></p-editor>
                </div>
                <div class="col-12 p-0">
                    <div class="row text-end d-flex align-items-center justify-content-between">
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            <p class="m-0">Was this blog helpful?</p>
                            <i class="fa fa fa-thumbs-o-up cursor-pointer text-dark px-1" aria-hidden="true" (click)="onLike(selectedBlogs,true)" *ngIf="!selectedBlogs?.isLiked"></i>
                            <i class="fa fa-thumbs-up cursor-pointer text-primary px-1" aria-hidden="true"  *ngIf="selectedBlogs?.isLiked" (click)="onLike(selectedBlogs,false)"></i>
                        </div>
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            <small class="fw-semibold"><span class="p-1">Share this Blog</span><i
                                class="pi pi-share-alt burgendy border rounded-circle p-3 cursor-pointer" (click)="copyBlogs()"></i></small>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0" >
                    <span *ngFor="let tag of selectedBlogs?.tagsList">#{{tag}}  </span>
                </div>
                    <hr>
                <div class="col-12">
                    <h3 class="fw-semibold">{{commentsList?.length}} comments</h3>
                    <div class="row">
                        <div class="col-12 light_skyBlue p-2" *ngIf="isUserLoggedIn && loggedInUserData?.roleId != 1">
                            <form [formGroup]="createNewsCommentForm" >
                                <div class="row">
                                    <div class="col">
                                        <input pInputText name="comment" id="comment" type="text" class="clsControl form-control border-0 comment__box light_skyBlue"
                                        formControlName="comment" placeholder="Say something here" />
                                    </div>
                                </div>
                                <div class="row pt-3">
                                    <div class="col text-right">
                                        <p-button class="transparent" label="Send" type="button" (click)="onSubmit(createNewsCommentForm.value,true)"></p-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-4">
                            <ng-container *ngTemplateOutlet="recursiveListTmpl;context:{list:commentsList}"></ng-container>

                            <ng-template #recursiveListTmpl let-list="list">
                                <div *ngFor="let item of list" class="py-3">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#bababa}</style><path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg><p class="fw-semibold m-0 px-2"> {{item.username}}</p>
                                        </div>
                                        <div>
                                            <p class="text__ash m-0">{{item.commentedAt | date:"dd-MMM-YYYY"}}</p>
                                        </div>
                                    </div>
                                    <div class="py-2">
                                       <h6 class="text__ash">{{item.content}}</h6> 
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-chat-left-fill text__ash" *ngIf="item.children.length>0"></i>
                                        <small class="text__ash p-1"><span *ngIf="item.children.length>0">{{item.children.length }} </span>
                                            <a  class="cursor-pointer" *ngIf="isUserLoggedIn && loggedInUserData?.roleId != 1" (click)="addReply(item,list)">Reply</a></small>
                                            <small class="text__ash p-1"><a (click)="showReplies(item,list)" class="cursor-pointer" *ngIf="item.children.length>0">View Reply</a></small>
                                    </div>
                                    <div>
                                        <div class="row"  *ngIf="item.addReply">
                                            <div class="col-12 light_skyBlue p-2">
                                                <form  [formGroup]="createReplyCommentForm">
                                                    <div class="row">
                                                        <div class="col">
                                                            <input pInputText name="replyComment" id="replyComment" type="text" class="clsControl form-control border-0 comment__box light_skyBlue"
                                                            formControlName="replyComment" placeholder="Say something here" />
                                                        </div>
                                                    </div>
                                                    <div class="row pt-3">
                                                        <div class="col text-right">
                                                            <p-button class="transparent" label="Send" type="button" (click)="onSubmit(createReplyCommentForm.value,false,item)"></p-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <ul *ngIf="item.children.length > 0 && item.showReply">
                                        <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: item.children }"></ng-container>
                                    </ul>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="detailedBlogLoader">
                <p-progressSpinner
                styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="bg-burgendy p-2 d-flex align-item-center" *ngIf="!mostRecentBlogLoader">
                <h4 class="text-white m-0">Most Recent</h4>
            </div>
            <div class="row py-3 m-0" *ngFor="let blog of blogsList; let i = index" [ngClass]="{'bg-ashDark': i%2 == 0}" >
                <div class="col-12 cursor-pointer" (click)="onSelectBlogs(blog)" *ngIf="!mostRecentBlogLoader">
                    <div class="row">
                        <div class="col-9">
                            <h5 class="fw-semibold custom_width__ForTextWrap text-truncate">{{blog?.title}}</h5>
                            <p-editor class="pEditor__Readonly text-wrap   w-100 " [ngClass]="{'editor_alter': i%2 == 0}"
                                [(ngModel)]="blog.description" [readonly]="true"></p-editor>
                        </div>
                        <div class="col-3 d-flex align-item-center justify-content-center flex-column">
                            <img src="{{ blog?.image}}" alt="Blog Image" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="mostRecentBlogLoader">
                <p-progressSpinner
                styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>
