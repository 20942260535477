import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-list-public-view',
  templateUrl: './news-list-public-view.component.html',
  styleUrls: ['./news-list-public-view.component.css']
})
export class NewsListPublicViewComponent implements OnInit {
  newsList: any;
  newsOriginalList: any;
  loader:boolean = false;
  constructor(
    private titleService: Title,
    private NewsDetails: NewsService,
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private appinsightsService: LoggingService,
    private confirmationService: ConfirmationService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    try{
      this.appinsightsService.logEvent('News list public view screen visited')
      this.titleService.setTitle('News list');
      this.getNewsList();
    }
    catch(error){
      console.log(error)
    }
  }

  getNewsList() {
    try {
      this.loader = true;
      this.NewsDetails.getnewslists(0,false).subscribe((data) => {
        if(data){
        this.newsList = data.result;
        this.newsOriginalList = [...this.newsList];
        this.loader = false;
        }
      },(error)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in news list api", {payload: JSON.stringify(0),error:JSON.stringify(error)})
        if(error.status >= 400 && error.status < 500 ){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      });
    } catch (error) {
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  navigateToDetailView(id){
    this.router.navigate(['/News/',id]);
  }

    filterSearchedNews(value){
      try{
        this.newsList = this.newsOriginalList;
        if(value.length != 0){
          let eventsFilteredArray=[];
          eventsFilteredArray = this.newsList.filter(news => news['heading']?.toLowerCase( ).includes(value.toLowerCase( )) || news['description']?.toLowerCase( ).includes(value.toLowerCase( )))
          this.newsList = eventsFilteredArray;
        }
        else{
          this.newsList = this.newsOriginalList;
        }
      }
      catch(error){
        this.appinsightsService.logException(error,2)
      }
    }
}
