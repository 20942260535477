import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  constructor() { }
  timeZone:any[] = [
    { name: 'America/Anchorage' },
    { name: 'America/Argentina/Buenos_Aires' },
    { name: 'America/Bogota' },
    { name: 'America/Caracas' },
    { name: 'America/Chicago' },
    { name: 'America/Chihuahua' },
    { name: 'America/Denver' },
    { name: 'America/Godthab' },
    { name: 'America/Halifax' },
    { name: 'America/Indiana/Indianapolis' },
    { name: 'America/La_Paz' },
    { name: 'America/Lima' },
    { name: 'America/Los_Angeles' },
    { name: 'America/Manaus' },
    { name: 'America/Mazatlan' },
    { name: 'America/Mexico_City' },
    { name: 'America/Monterrey' },
    { name: 'America/Montevideo' },
    { name: 'America/New_York' },
    { name: 'America/Phoenix' },
    { name: 'America/Regina' },
    { name: 'America/Rio_Branco' },
    { name: 'America/Santiago' },
    { name: 'America/Santo_Domingo' },
    { name: 'America/Sao_Paulo' },
    { name: 'America/St_Barthelemy' },
    { name: 'America/St_Johns' },
    { name: 'America/Tegucigalpa' },
    { name: 'America/Tijuana' },
];

}
