<div class="container-fluid pb-5">
    <div class="row mt-3 d-md-flex align-item-center">
        <div class="col-12 col-md-6 px-5">
            <h1 class="burgendy fw-semibold">Users</h1>
        </div>
        <div class="col-12 col-md-6 px-5 text-md-end">
            <p-button label="Upload users" class="primeNgButton"  icon="pi pi-plus" iconPos="left" (click)="navigateToUploadUsers()"></p-button>
        </div>
    </div>

    <div class="row mt-3" *ngIf="!loader">
        <div class="col-12 px-5">
            <p-tabView>
                <p-tabPanel header="Users"  >
                    <div class="row">
                        <div class="col">
                            <p-table #userList [value]="usersList" sortMode="single" [responsive]="true" [rows]="10"
                                sortOrder="-1" [paginator]="true" [rowsPerPageOptions]="paginationValues" [globalFilterFields]="['lefa','userName','roleName','email','onboardedDate','status']">
                                <ng-template pTemplate="caption" >
                                    <div class="row align_inline">
                                        <div class="col-12 col-md-4">
                                           <p-dropdown [options]="allUsersOptions" placeholder="-- Select --" [(ngModel)]="selectedUserOption" optionLabel="status" (onChange)="filterDataBasedOnSelectedStatus($event)"></p-dropdown>
                                        </div>
                                        <div class="col-12 col-md-4 d-flex" style="text-align: right">
                                            <div class="form-group has-search text-center d-inline">
                                                <span class="fa fa-search form-control-feedback text-center text-black mt-1 d-block"></span>
                                                <input  type="text"  (input)="userList.filterGlobal($event.target.value, 'contains')"  class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2 text-black placeHolder-color" autocomplete="off" pInputText size="50" placeholder=" Search..." > 
                                            </div>
                                        </div>
                                    </div>
                                  </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [pSortableColumn]="'lefa'" class="fw-semibold">LEFA Id <p-sortIcon [field]="'lefa'"></p-sortIcon>
                                        </th>
                                        <th [pSortableColumn]="'userName'" class="fw-semibold">Name<p-sortIcon
                                            [field]="'userName'" class="fw-semibold"></p-sortIcon></th>
                                        <th [pSortableColumn]="'roleName'" class="fw-semibold">Role<p-sortIcon [field]="'roleName'"></p-sortIcon>
                                        </th>
                                        <th [pSortableColumn]="'email'" class="fw-semibold">Personal Email<p-sortIcon
                                                [field]="'email'"></p-sortIcon></th>
                                        <th [pSortableColumn]="'uploadedDate'" class="fw-semibold">Uploaded<p-sortIcon [field]="'onboardedDate'"></p-sortIcon></th>
                                        <th [pSortableColumn]="'onboardedDate'" class="fw-semibold">Onboarded <p-sortIcon [field]="'onboardedDate'"></p-sortIcon></th>

                                        <th [pSortableColumn]="'numberOfInvitationsSent'" class="fw-semibold">Status <p-sortIcon [field]="'numberOfInvitationsSent'"></p-sortIcon></th>
                                        <th class="fw-semibold " >Actions</th>
                                       
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body"  let-user let-rowData let-columns="columns" let-rowIndex="rowIndex" >
                                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                                        <td>{{user?.lefa }}  </td>                  
                                        <td>{{user?.userName }}  </td>
                                        <td>{{user?.roleName }}  </td>
                                        <td>{{user?.email }}</td>
                                        <td>{{user?. uploadedDate | date :'dd MMM yyyy'}} </td>
                                        <td>{{user?.onboardedDate | date :'dd MMM yyyy'}}  </td>
                                        <td [ngClass]="{'success_Class':user?.status == 'Onboarded'}">{{user?.status }}</td>
                                        <td class="align_inline d-flex align-item-center justify-content-center">
                                            <a (click)="deleteUser(user)">
                                                <span class="fa fa-trash m-2 cursor-pointer"></span>
                                            </a>
                                        </td>   
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Invited Users" >
                    <p-table #stageUserList [value]="stagedUsersList" sortMode="single" [responsive]="true" [rows]="10"
                    sortOrder="-1" [paginator]="true" [rowsPerPageOptions]="paginationValues" [globalFilterFields]="['lefa','role','email','dateInvited','numberOfInvitationsSent']">
                    <ng-template pTemplate="caption">
                        <div class="row align_inline">
                            <div class="col-12 col-md-4">
                               <p-dropdown [options]="allStagedUsersOptions" placeholder="All Roles" optionLabel="role"  [(ngModel)]="selectedStageOption" (onChange)="filterDataBasedOnSelectedRole($event)"></p-dropdown>
                            </div>
                            <div class="col-12 col-md-4 d-flex" style="text-align: right">
                                <div class="form-group has-search text-center d-inline">
                                    <span class="fa fa-search form-control-feedback text-center text-black mt-1 d-block"></span>
                                    <input  type="text"  (input)="stageUserList.filterGlobal($event.target.value, 'contains')"  class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2 text-black placeHolder-color" autocomplete="off" pInputText size="50" placeholder=" Search..." > 
                                </div>
                                <!-- <input type="text" class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2" autocomplete="off" pInputText size="50" placeholder=" Ex: Search Invited users..."
                                (input)="stageUserList.filterGlobal($event.target.value, 'contains')" style="height: 2.25rem;font-size: 13px;
                                border-radius: 5px 0px 0px 5px;">
                                <div class="input-group-prepend" style="background: #99A7BA;
                                border-width: 0.666667px 0.666667px 0.666667px 0px;
                                border-style: solid;
                                height: 2.25rem;
                                border-color: rgba(153, 167, 186, 0.27);
                                border-radius: 0px 5px 5px 0px;">
                                <span class="input-group-text" style="background: #99A7BA;
                                border: none;">
                                <i class="fa fa-search"  style="margin:4px 4px 0 0;color: white;"></i>
                                </span>
                            </div> -->
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="fw-semibold">S.No</th>
                            <th [pSortableColumn]="'lefa'" class="fw-semibold">LEFA Id <p-sortIcon [field]="'lefa'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'role'" class="fw-semibold">Role <p-sortIcon [field]="'role'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'email'" class="fw-semibold">Personal Email<p-sortIcon
                                    [field]="'email'"></p-sortIcon></th>

                            <th [pSortableColumn]="'dateInvited'" class="fw-semibold" >Date Uploaded <p-sortIcon [field]="'dateInvited'"></p-sortIcon></th>
                            <th [pSortableColumn]="'numberOfInvitationsSent'" class="fw-semibold">No. Invitations <p-sortIcon [field]="'numberOfInvitationsSent'"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-stagedUser let-rowData let-columns="columns" let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                            <td>{{rowIndex+1}}</td>
                            <td>{{stagedUser?.lefa }}  </td>                  
                            <td>{{stagedUser?.role }}  </td>
                            <td>{{stagedUser?.email }}</td>
                            <td>{{stagedUser?.dateInvited }}  </td>
                            <td class="text-center">{{stagedUser?.numberOfInvitationsSent }}</td>
                        </tr>
                    </ng-template>
                </p-table>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
    <div class="row" *ngIf="loader">
        <div class="col-12 text-center">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>


</div><p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>
