<div class="container-fluid pb-5">
    <p-toast position="top-center"></p-toast>
    <div *ngIf="!loader">
        <form [formGroup]="userRegistrationForm" (ngSubmit)="onSubmit(userRegistrationForm.value)">
            <div class="row">
                <div class="col burgendy font-weight-bold fs-4 lh-1 pt-3 pb-3 px-md-5">
                    Create Password
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="newPw">Create password<span class="star">*</span></label>
                    <p-password class="passwordBlock" (keyup)="validatePassword($event)" name="newPw" id="newPw"
                        formControlName="newPw" [toggleMask]="true" placeholder="Create New Password"></p-password>
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['newPw'].valid&&userRegistrationForm.controls['newPw'].dirty">
                        <span *ngIf="userRegistrationForm.controls['newPw'].errors['required']">Password
                            Required</span>
    
                        <span *ngIf="userRegistrationForm.controls['newPw'].errors['pattern']">Invalid Password</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="confirmPw">Confirm password<span class="star">*</span></label>
                    <p-password (keyup)="validatePassword($event)" class="passwordBlock" name="confirmPw" id="confirmPw"
                        formControlName="confirmPw" [toggleMask]="true" placeholder="Confirm Password"></p-password>
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['confirmPw'].valid&&userRegistrationForm.controls['confirmPw'].dirty">
                        <span *ngIf="userRegistrationForm.controls['confirmPw'].errors['required']">Password
                            Required</span>
                    </div>
                    <span class="error"
                        *ngIf="showPasswordValidationError&&userRegistrationForm.controls['confirmPw'].value != null">New
                        Password and Confirm Password should be same</span>
                </div>
                <div class="col-12 col-md-6 px-md-5">
                    <h6 class="pt-3">Password requirements:</h6>
                    <p class="mb-2">Ensure that these requirements are met:</p>
                    <div class="pt-2">
                        <p class="m-0 skyCloud">Minimum 8 characters long-the more, the better</p>
                        <p class="m-0 skyCloud">At least one lowercase character</p>
                        <p class="m-0 skyCloud">At least one uppercase character</p>
                        <p class="m-0 skyCloud">At least one number, symbol</p>
                        <p class="m-0 skyCloud">Password should not start with Number or _(underscore)</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col burgendy font-weight-bold fs-4 lh-1 pt-3 pb-3 px-md-5">
                    Applicant Details
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-bold" for="firstName">First Name<span class="star">*</span></label>
                    <input pInputText name="firstName" id="firstName" type="text" class="clsControl form-control"
                        formControlName="firstName" placeholder="Enter first name" />
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['firstName'].valid&&userRegistrationForm.controls['firstName'].dirty">
                        <span *ngIf="userRegistrationForm.controls['firstName'].errors['required']">First Name
                            Required</span>
                        <span *ngIf="userRegistrationForm.controls['firstName'].errors['pattern']">Invalid Name</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="lastName">Last Name<span class="star">*</span></label>
                    <input pInputText name="lastName" id="lastName" type="text" class="clsControl form-control"
                        formControlName="lastName" placeholder="Enter last name" />
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['lastName'].valid&&userRegistrationForm.controls['lastName'].dirty">
                        <span *ngIf="userRegistrationForm.controls['lastName'].errors['required']">Last Name
                            Required</span>
                        <span *ngIf="userRegistrationForm.controls['lastName'].errors['pattern']">Invalid Name</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="middleName">Middle Name</label>
                    <input pInputText name="middleName" id="middleName" type="text" class="clsControl form-control"
                        formControlName="middleName" placeholder="Enter middle name" />
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['middleName'].valid&&userRegistrationForm.controls['middleName'].dirty">
                        <span *ngIf="userRegistrationForm.controls['middleName'].errors['pattern']">Invalid Name</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="roleName">Role Name<span class="star">*</span></label>
                    <p-dropdown filter="true" [disabled]="true" id="roleName" placeholder="-- Select --" autoWidth="false" class="clsControl"
                        [options]="roles" optionLabel="name" [tabindex]="4" name="roleName" formControlName="roleName">
                    </p-dropdown>
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['roleName'].valid&&userRegistrationForm.controls['roleName'].dirty">
                        <span *ngIf="userRegistrationForm.controls['roleName'].errors['required']">Role
                            Required</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="dob">Date of birth<span class="star">*</span></label>
    
                    <p-calendar [showIcon]="true" formControlName="dob" class="clsControl" id="dob" placeholder="Select DOB"
                        name="dob" [monthNavigator]="true" [yearNavigator]="true" yearRange="1800:4030">
                    </p-calendar>
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['dob'].valid&&userRegistrationForm.controls['dob'].dirty">
                        <span *ngIf="userRegistrationForm.controls['dob'].errors['required']">Date of birth
                            Required</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="lefa">Lefa<span class="star">*</span></label>
                    <input pInputText name="lefa"  pInputText pTooltip="Lifelong E-mail Forwarding Address" 
                    tooltipPosition="top"  readonly id="lefa" type="text" class="clsControl form-control disableField"
                        formControlName="lefa" placeholder="Enter lefa" />
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['lefa'].valid&&userRegistrationForm.controls['lefa'].dirty">
                        <span *ngIf="userRegistrationForm.controls['lefa'].errors['required']">LEFA
                            Required</span>
                        <!-- <span *ngIf="userRegistrationForm.controls['lefa'].errors['pattern']">Invalid Email</span> -->
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="email">Preferred Email Address<span class="star">*</span></label>
                    <input pInputText name="email" id="email" type="text" class="clsControl form-control"
                        formControlName="email" placeholder="Enter email address" />
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['email'].valid&&userRegistrationForm.controls['email'].dirty">
                        <span *ngIf="userRegistrationForm.controls['email'].errors['required']">Email
                            Required</span>
                        <span *ngIf="userRegistrationForm.controls['email'].errors['pattern']">Invalid Email</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="phoneNumber">Phone number<span class="star">*</span></label>
                    <input pInputText name="phoneNumber" id="phoneNumber" type="text" class="clsControl form-control"
                        formControlName="phoneNumber" placeholder="Enter phone number" />
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['phoneNumber'].valid&&userRegistrationForm.controls['phoneNumber'].dirty">
                        <span *ngIf="userRegistrationForm.controls['phoneNumber'].errors['required']">Phone Number
                            Required</span>
                        <span *ngIf="userRegistrationForm.controls['phoneNumber'].errors['pattern']">Invalid Phone
                            Number</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 px-md-5 pt-2">
                    <label class="fs-6 fw-semibold" for="address">Address<span class="star">*</span></label>
                    <input pInputText name="address" id="address" type="text" class="clsControl form-control"
                        formControlName="address" placeholder="Enter address" />
                    <div class="error"
                        *ngIf="!userRegistrationForm.controls['address'].valid&&userRegistrationForm.controls['address'].dirty">
                        <span *ngIf="userRegistrationForm.controls['address'].errors['required']">Address
                            Required</span>
                    </div>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col px-md-5 d-flex align-items-center">
                    <p-button label="Submit Application" class="primeNgButton" type="submit" icon="pi pi-arrow-right" iconPos="right"></p-button>
                    <p-progressSpinner *ngIf="registrationLoader"
                    styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                    animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="loader" class="text-center">
        <p-progressSpinner *ngIf="loader"
        styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
        animationDuration=".5s"></p-progressSpinner>
    </div>

</div>