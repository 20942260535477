import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { routing } from './app.routing';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {ButtonModule} from 'primeng/button';
import { TreeModule } from 'primeng/tree';
import { LoginService } from './services/login.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import {MenubarModule} from 'primeng/menubar';
import {DialogModule} from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {SlideMenuModule} from 'primeng/slidemenu';
import {TableModule} from 'primeng/table';
import { DatePipe } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {EditorModule} from 'primeng/editor';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { TooltipModule } from "primeng/tooltip";
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { ForgotPwComponent } from './components/forgot-pw/forgot-pw.component';
import { CreatePwComponent } from './components/create-pw/create-pw.component';
import { UploadExcelComponent } from './components/upload-excel/upload-excel.component';
import { AdminpanelMenuComponent } from './components/adminpanel-menu/adminpanel-menu.component';
import { StageUsersComponent } from './components/stage-users/stage-users.component';
import { UserRegistrationFormComponent } from './components/user-registration-form/user-registration-form.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { UsersComponent } from './components/users/users.component';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { EventSummaryComponent } from './components/event-summary/event-summary.component';
import { EventSubmenuComponent } from './components/event-submenu/event-submenu.component';
import { EditEventComponent } from './components/edit-event/edit-event.component';
import { EditOverviewComponent } from './components/edit-overview/edit-overview.component';
import { EventDeleteComponent } from './components/event-delete/event-delete.component';
import { EventPublicComponent } from './components/event-public/event-public.component';
import { EventPublicDetailViewComponent } from './components/event-public-detail-view/event-public-detail-view.component';
import { BlogListComponent } from './components/Blogs/blog-list/blog-list.component';
import { BlogCreateComponent } from './components/Blogs/blog-create/blog-create.component';
import { NewsListComponent } from './components/News/news-list/news-list.component';
import { NewsCreateComponent } from './components/News/news-create/news-create.component';
import { ContactComponent } from './contact/contact.component';
import { NewOverviewComponent } from './components/News/new-overview/new-overview.component';
import { NewsDeleteComponent } from './components/News/news-delete/news-delete.component';
import { NewsEditComponent } from './components/News/news-edit/news-edit.component';
import { NewsSubmenuComponent } from './components/News/news-submenu/news-submenu.component';
import { NewsSummaryComponent } from './components/News/news-summary/news-summary.component';
import { BlogSummaryComponent } from './components/Blogs/blog-summary/blog-summary.component';
import { BlogSubmenuComponent } from './components/Blogs/blog-submenu/blog-submenu.component';
import { BlogEditComponent } from './components/Blogs/blog-edit/blog-edit.component';
import { BlogDeleteComponent } from './components/Blogs/blog-delete/blog-delete.component';
import { BlogOverviewComponent } from './components/Blogs/blog-overview/blog-overview.component';
import { BlogPublicComponent } from './components/Blogs/blog-public/blog-public.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { NewsPublicViewComponent } from './components/News/news-public-view/news-public-view.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './components/term-condition/term-condition.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventIssuedticketComponent } from './components/event-issuedticket/event-issuedticket.component';
import { NewsListPublicViewComponent } from './components/News/news-list-public-view/news-list-public-view.component';
import { BlogListPublicComponent } from './components/Blogs/blog-list-public/blog-list-public.component';
import { NestedLoopDirective } from './directives/nested-loop.directive';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MyEventsComponent } from './components/my-events/my-events.component'




@NgModule({
  declarations: [
    AppComponent,
    ForgotPwComponent,
    CreatePwComponent,
    LandingComponent,
    HomeComponent,
    UploadExcelComponent,
    AdminpanelMenuComponent,
    StageUsersComponent,
    UserRegistrationFormComponent,
    AdminDashboardComponent,
    UsersComponent,
    CreateEventComponent,
    EventsListComponent,
    EventSummaryComponent,
    EventSubmenuComponent,
    EditEventComponent,
    EditOverviewComponent,
    EventDeleteComponent,
    EventPublicComponent,
    EventPublicDetailViewComponent,
    BlogListComponent,
    BlogCreateComponent,
    NewsListComponent,
    NewsCreateComponent,
    ContactComponent,
    NewOverviewComponent,
    NewsDeleteComponent,
    NewsEditComponent,
    NewsSummaryComponent,
    NewsSubmenuComponent,
    BlogSummaryComponent,
    BlogSubmenuComponent,
    BlogEditComponent,
    BlogDeleteComponent,
    MyProfileComponent,
    BlogOverviewComponent,
    BlogPublicComponent,
    AboutUsComponent,
    NewsPublicViewComponent,
    PrivacyPolicyComponent,
    TermConditionComponent,
    DashboardComponent,
    EventIssuedticketComponent,
    NewsListPublicViewComponent,
    BlogListPublicComponent,
    NestedLoopDirective,
    MyEventsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    InputTextareaModule,
    BrowserAnimationsModule,
    AccordionModule,
    ButtonModule,
    TreeModule,
    CheckboxModule,
    MenubarModule,
    DialogModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    SlideMenuModule,
    TableModule,
    DropdownModule,
    CalendarModule,
    ToastModule,
    PasswordModule,
    EditorModule,
    TabViewModule,
    DataViewModule,
    CardModule,
    routing,
    TooltipModule
  ],
  providers: [LoginService,DatePipe,ConfirmationService,
    MessageService,Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
