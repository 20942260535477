import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { GlobalResources } from 'src/app/services/global-resource/global-resource';
import { LoginService } from 'src/app/services/login.service';
import { MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-forgot-pw',
  templateUrl: './forgot-pw.component.html',
  styleUrls: ['./forgot-pw.component.css']
})
export class ForgotPwComponent implements OnInit {
  guid:any;
  forgotPasswordForm: FormGroup;
  userId = 0;
  showPasswordValidationError:boolean = false;
  passwordType: string = 'password';
  public showeye: boolean = false;
  public hideeye: boolean = true;
  confirmPasswordType: string ='password';
  confirmPwShoweye:boolean = false;
  confirmPwHideeye: boolean =  true;
  public globalResource: any;
  public showErrorPanel: boolean = false;
  showSucessMessage: boolean = false;
  timerCount: number = 1;
  loader: boolean = false;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private userDetailsService:UserDetailsService,
    private fb: FormBuilder,
    private loginService: LoginService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private router: Router,
    private appinsightsService: LoggingService, ) { }

  ngOnInit(): void {
    try{
      this.appinsightsService.logEvent('Forgot password reset screen visited')
      this.titleService.setTitle('Forgot Password');
      this.route.params.forEach((urlParams) => {
        this.guid = urlParams['guid'];
      });
      this.globalResource = GlobalResources.getResources().en;
      this.forgotPasswordForm = this.fb.group({
        newPassword: new FormControl(null,  Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
          ),
        ])),
        confirmPassword: new FormControl(null,  Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
          ),
        ])),
        terms: new FormControl(null,  Validators.required)
      })
      this.verifyUser(this.guid);
    }
    catch(error){
      console.log(error)
    }
  }

  verifyUser(guid){
    try{
     this.loader = true;
      this.userDetailsService.verifyUserWithGuidForPasswordReset(guid).subscribe((data)=>{
        this.loader = false;
        this.showErrorPanel  = false;
        this.userId = data.result.userId;
       },(error:any)=>{
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in verifing user api", {error:JSON.stringify(error)})
        this.loader = false;
         this.showErrorPanel  = true;
       })
    }
    catch(error){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }
  validatePassword(event:any){
    if(this.forgotPasswordForm.controls['newPassword'].value != this.forgotPasswordForm.controls['confirmPassword'].value)
    {
        this.showPasswordValidationError = true;
    }
    else{
      this.showPasswordValidationError = false;
    }
  }
  public togglepw() {
    if (this.passwordType != 'text') {
      this.passwordType = 'text';
      this.showeye = true;
      this.hideeye = false;
    } else {
      this.passwordType = 'password';
      this.showeye = false;
      this.hideeye = true;
    }
  }
  
  public toggleConfirmPw() {
    if (this.confirmPasswordType != 'text') {
      this.confirmPasswordType = 'text';
      this.confirmPwShoweye = true;
      this.confirmPwHideeye = false;
    } else {
      this.confirmPasswordType = 'password';
      this.confirmPwShoweye = false;
      this.confirmPwHideeye = true;
    }
  }

  onSubmit(formData:any){
    try{
      if(this.forgotPasswordForm.valid){
        let forgotPasswordObj = {
          "password": formData.newPassword,
          "confirmPassword": formData.confirmPassword
        }
        this.loaderService.display(true);
        this.loginService.forgotPasswordUpdate(forgotPasswordObj,this.userId).subscribe((data)=>{
          this.loaderService.display(false);
          if(data.statusCode == 200){
            this.showSucessMessage = true;
            this.forgotPasswordForm.reset();
            this.timerCount = 1;
          let timer = setInterval(()=>{
            if(this.timerCount === 5){
              clearInterval(timer);
              this.router.navigate(['/home'])
          }
          ++this.timerCount;
          },1000)
          }
        },(error:any)=>{
          this.loaderService.display(false);
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in creating  new password api", {payload: JSON.stringify(forgotPasswordObj),error:JSON.stringify(error)})
          if (error.status == 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Something went wrong at server side!',
            });
          } 
          else if (error.status > 400 && error.status< 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Link is expired, please generate new link!',
            });
          } 
          else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.responseException[0],
            });
          }
          this.forgotPasswordForm.reset();
        })
      }
      else{
        this.forgotPasswordForm.controls['newPassword'].markAsDirty();
        this.forgotPasswordForm.controls['confirmPassword'].markAsDirty();
        this.forgotPasswordForm.controls['terms'].markAsDirty();
      }
    }
    catch(error:any){
      this.loaderService.display(false);
      this.appinsightsService.logException(error,2)
    }
  }

  navigateToContactUs(){
    this.router.navigate(['/contactus']);
  }
  openForgotPasswordPopUp(){
    this.loginService.showForgotPasswordPopUp.next(true);
  }
  loginUser(lefaId:string,password:string){
    try{
      let loginDetails ={
        email: lefaId,
        password: password
      }
      this.loaderService.display(true);
      this.loginService.login(loginDetails).subscribe((data)=>{
        this.loaderService.display(false);
        localStorage.setItem('token', JSON.stringify(data.result));
        this.loginService.SelectedFieldID.next(data.result)
        setTimeout(() => {
          this.router.navigate(['/home']);
    }, 1000);
      },(error:any)=>{
        this.loaderService.display(false);
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in user login api", {payload: JSON.stringify(loginDetails),error:JSON.stringify(error)})
      })
    }
    catch(error){
      this.loaderService.display(false);
      this.appinsightsService.logException(error,2)
    }
  }
}
