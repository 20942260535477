import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { ticketissued } from './ticketissued';
import { LoginService } from 'src/app/services/login.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-event-issuedticket',
  templateUrl: './event-issuedticket.component.html',
  styleUrls: ['./event-issuedticket.component.css']
})
export class EventIssuedticketComponent implements OnInit {
  ticketissued: any;
  eventSeriesId: any;
  eventId: any;
  roles: any[] = [
    { name: 'Admin' },
    { name: 'Teacher' },
    { name: 'Student' },
    { name: 'Faculty' },
  ];
  loader: boolean = false;
  constructor( private titleService: Title,
    private EventDetails:EventDetailsService,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService,
    private router: Router,
    private loaderService: LoaderService,
    private loginService: LoginService, 
    private route: ActivatedRoute,
    private appinsightsService: LoggingService,
   ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event issued tickets screen visited')
    this.titleService.setTitle('Event ticket Issued');
    this.route.params.forEach((urlParams) => {
      this.eventSeriesId = urlParams['eventId'];
      this.loginService.SelectedEventId.next(this.eventSeriesId)
    });
    if(this.eventSeriesId){
      this.getEventData(this.eventSeriesId);
    }
  }

  async getEventData(eventSeriesId){
    try{
      await this.loginService.tokenRenewal();
      this.loader = true;
      await this.EventDetails.getEventDetails(eventSeriesId).subscribe((data)=>{
        this.loader = false;
        if(data){
         this.eventId = data.result?.eventId;
         this.getTicket(this.eventId,eventSeriesId);
        }
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting Event details api", {payload: JSON.stringify(this.eventId),error:JSON.stringify(error)})
         if(error.status == 401){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      })
    }
    catch(error){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }

 async getTicket(eventId,eventSeriesId){
    try{
    await this.loginService.tokenRenewal();
    this.loader = true;
    await this.EventDetails.getTicket(eventId,eventSeriesId).subscribe((data)=>{
      this.loader = false;
      if(data){
        console.log(data)
        this.ticketissued = data.result;
      }
    },(error:any)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting Event issued ticket details api", {payload: JSON.stringify(eventSeriesId),error:JSON.stringify(error)})
      this.appinsightsService.logEvent("Error: error in getting Event issued ticket details api", {payload: JSON.stringify(eventId),error:JSON.stringify(error)})
       if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'sessionExpiry',
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);
          },
        })
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }
    })
  }
  catch(error:any){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  }
  navigateBactToList(){
    this.router.navigate(['/adminDashboard/eventsList']);
  }
}
