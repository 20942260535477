import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsService } from 'src/app/services/news.service';
import { newslist } from './newslist';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { Title } from '@angular/platform-browser';
import { LoggingService } from 'src/app/services/logging.service';
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {
newslist : newslist[] = [];
allnewsOptions: any;
newsId:any;
selectNewsForDelete:any;
showDeleteDialog : boolean = false;
copyOfEventsList:any;
loader: boolean = false;
selectedOption: any;
  constructor(
    private News: NewsService ,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private titleService: Title,
    private appinsightsService: LoggingService
  ) { }
  ngOnInit(): void {
    this.appinsightsService.logEvent('News list screen visited')
    this.titleService.setTitle('News List');
    this.getnewslist();
  }
  navigateToAddNews(){
    this.router.navigate(['/adminDashboard/createNews']);
  }
  onDelete(news){
    this.showDeleteDialog = true;
    this.selectNewsForDelete = news;
  }
  async getnewslist() {
    try{
    await this.loginService.tokenRenewal();
    this.loader = true;
    await this.News.getnewslists(0,false).subscribe(
      (data) => {
        this.loader = false;
        try {
          if (data.statusCode == 200) {
            this.newslist = data.result;
            this.copyOfEventsList = this.newslist;
            this.allnewsOptions = this.newslist;
            this.allnewsOptions = Array.from(this.newslist.reduce((m, t) => m.set(t.status, t), new Map()).values());
            this.allnewsOptions.push({
              status:"All News"
            })
            this.selectedOption = this.allnewsOptions[this.allnewsOptions.length-1]
            
            this.loader = false;
          }
        } catch (error) {
          this.loader = false;
        }
      },
      (error) => {
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in news list api", {payload: JSON.stringify(0),error:JSON.stringify(error)})
        if(error.status >= 400 && error.status < 500){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'sessionExpiry',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
  
      }
    );
  }
  
  catch(error:any){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
}
closeDelete(){
  this.showDeleteDialog = false;
  }
 async deleteNews(){
    try {
      await this.loginService.tokenRenewal();
      await this.News.deleteNewsDetails(this.selectNewsForDelete.id).subscribe((data) => {
        if (data.statusCode == 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'News deleted successfully',
          });
          this.showDeleteDialog =  false;
          this.getnewslist();
        }
      }, (error: any) => {   
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in news delete api", {payload: JSON.stringify(this.selectNewsForDelete.id),error:JSON.stringify(error)})
        if (error.status == 401) {
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key: 'toastMsg',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        } else if (error.status == 500) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Something went wrong at the server side!',
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: error.error.responseException.message,
          });
        }
      });
    } catch (error) {
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }
  
  editNews(news){
    localStorage.setItem('SelectednewsName', news.heading)
    this.router.navigate(['/adminDashboard/newssummary/newsedit', news.id]);
  }
  newsOverView(news){
    localStorage.setItem('SelectednewsID', news.id)
    localStorage.setItem('SelectednewsName', news.heading)
    this.router.navigate(['/adminDashboard/newssummary/newsoverview/', news.id]);
  }
  navigateToNewsList(){
    this.router.navigate(['/adminDashboard/news']);
  }
  filterDataBasedOnSelectedStatus(event:any){
    this.newslist = this.copyOfEventsList;
    if( event.value.status.toUpperCase() == 'ALL NEWS'){
      this.newslist = this.copyOfEventsList;
    }
    else{
    this.newslist = this.newslist.filter(x => x.status == event.value.status)  
    }
  }
}
