import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.css']
})
export class MyEventsComponent implements OnInit {
  myEventsList: any ;
  loader: boolean = false;
  constructor(
    private appinsightsService: LoggingService,
    private titleService: Title,
    private eventDetails: EventDetailsService,
    private loginDetails: LoginService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    try{
      this.appinsightsService.logEvent('My Events screen visited');
      this.titleService.setTitle('My Events');
      this.getMyEvents();
    }
    catch(error){
      this.appinsightsService.logException(error, 2);
    }
  }

  async getMyEvents(){
    try{
      this.myEventsList = [];
      await this.loginDetails.tokenRenewal();
      this.loader = true;
      await this.eventDetails.getMyTickets().subscribe((data)=>{
        this.loader = false;
        if(data.statusCode == 200){
          this.myEventsList = data.result;
        }
      },(error)=>{
        this.loader = false;
        this.appinsightsService.logException(error, 1);
        this.appinsightsService.logEvent("Error: Getting error in getting user event tickets", {error:JSON.stringify(error)})
      })
    }
    catch(error){
      this.loader = false;
      this.appinsightsService.logException(error, 2);
    }
  }
}
