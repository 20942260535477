import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { NewsService } from 'src/app/services/news.service';
import { HomeService } from 'src/app/services/home.service';
import { Title } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
import { LoginService } from 'src/app/services/login.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  eventsList:any=[];
  blogList:any=[];
  count:any = 3;
  showRegister:boolean=false;
  summaryData:any=[];
  newsList:any=[];
  isLoading: boolean = true;
  eventsLoader: boolean = false;
  newsLoader: boolean = false;
  blogsloader: boolean = false;
  summaryLoader: boolean = false;
  constructor( private titleService: Title,
    private router: Router,
    private homeDetails: HomeService,
    private newsDetails: NewsService,
    private messageService: MessageService,
    private appinsightsService: LoggingService,
    private BlogDetails:BlogDetailsService,
    private eventDetails: EventDetailsService,
    private confirmationService: ConfirmationService,
    private loaderService: LoaderService,
    private loginDetails: LoginService,
    private meta: Meta) { }

  ngOnInit(): void {
    try{
      this.appinsightsService.logEvent('Home screen visited')
      this.titleService.setTitle('Home');
      this.getEventsList(this.count);
      this.getSummary();
      this.getNews(this.count);
      this.getBlogs(this.count);
    }
    catch(error){
      console.log(error)
    }


    const dynamicImageUrl = 'https://hbsseattle.com/assets/images/logo-img.png';
    this.meta.updateTag({ property: 'og:image', content: dynamicImageUrl });
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.showRegister = true;
    },1000)
  }
 async getSummary(){
    try{
        this.summaryLoader = true;
       await this.loginDetails.tokenRenewal();
      await this.homeDetails.gethome().subscribe((data)=>{
        this.summaryLoader = false;
        this.summaryData = data.result;
      },(error:any)=>{
        this.summaryLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting summary api in home screen", {error:JSON.stringify(error)})
        if (error.status >= 400 && error.status < 500) {
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginDetails.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        } else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.title
          });
        }
      })
    }
    catch(error){
      this.summaryLoader = false;
      this.appinsightsService.logException(error,2)
    }
   

  }

 async getNews(count){
    try{
      this.newsLoader = true;
      await this.loginDetails.tokenRenewal();
      await this.newsDetails.getnewslists(count,false).subscribe((data)=>{
        this.newsLoader = false;
        this.newsList = data.result;       
      },(error:any)=>{
        this.newsLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting top 3 news api", {payload: JSON.stringify(count),error:JSON.stringify(error)})
        if (error.status >= 400 && error.status < 500) {
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginDetails.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        } else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      })
    }
    catch(error){
      this.newsLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  async getEventsList(count){
    try{
        this.eventsLoader = true;
       await this.loginDetails.tokenRenewal();
      await  this.eventDetails.getEvents(count).subscribe((data)=>{
        this.eventsLoader = false;
        this.eventsList = data.result.data;
      },(error:any)=>{
        this.eventsLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting top 3 events api", {payload: JSON.stringify(count),error:JSON.stringify(error)})
        if (error.status >= 400 && error.status < 500) {
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginDetails.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        } else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      })
    }
    catch(error){
      this.eventsLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }

 async getBlogs(count){
    try{
      this.blogsloader = true;
       await this.loginDetails.tokenRenewal();
     await this.BlogDetails.getBlogs(count,false).subscribe((data)=>{
      this.blogsloader = false;
        this.blogList = data.result;
      },(error:any)=>{
        this.blogsloader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting top 3 blogs api", {payload: JSON.stringify(count),error:JSON.stringify(error)})
        if (error.status >= 400 && error.status < 500) {
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginDetails.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        } else  if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      })
    }
    catch(error){
      this.blogsloader = false;
      this.appinsightsService.logException(error,2)
    }
  }

  navigateToEventDetails(){
    this.router.navigate(['/Events']);
  }
  navigateToaboutus(){
    this.router.navigate(['/about-us']);
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  navigateToNewsScreen(){
    this.router.navigate(['/News']);
  }
  navigateToBlogsScreen(){
    this.router.navigate(['/Blogs']);
  }
}
