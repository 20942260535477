import { Component, OnInit } from '@angular/core';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  constructor(private appinsightsService: LoggingService,) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Admin dashboard screen visited')
  }

}
