import { Component, OnInit } from '@angular/core';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-adminpanel-menu',
  templateUrl: './adminpanel-menu.component.html',
  styleUrls: ['./adminpanel-menu.component.css']
})
export class AdminpanelMenuComponent implements OnInit {
  heading:string = 'ADMIN PANEL';
  usersHeading:string = 'USERS';
  eventHeading:string = 'EVENTS';
  constructor(private appinsightsService: LoggingService,) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('adminpanel-menu visited')
  }

}
