import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(   
    private router: Router,
    private route: ActivatedRoute,  
    private titleService: Title,    
    private appinsightsService: LoggingService, ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('About us screen visited')
   this.titleService.setTitle('About-Us');
  }
  readMore() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = ""; 
      moreText.style.display = "inline";
    }
  }

}
