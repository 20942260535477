
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NewsService } from 'src/app/services/news.service';
import { newscreate } from './newscreate';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.css']
})
export class NewsCreateComponent implements OnInit {
  addNewNews: FormGroup;
  postImage: any;
  displayPreviewDialog: boolean = false;
  newscreateobj: any;
  currentDate = new Date();
file:any;
  @ViewChild('fileInput') fileInput: any;
  selectedFile: any;
  fileSizeWithinLimit: boolean;
  loader: boolean = false;
  constructor(
    private datePipe: DatePipe,
    private News: NewsService ,
    private titleService: Title,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private loginService: LoginService,
    private fb: FormBuilder, 
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('News creation screen visited')
    this.titleService.setTitle('Add News');
    this.addNewNews = this.fb.group({
      newsHeading:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      department:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      ShouldPublish:new FormControl(
        null,
      ),
      image:new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      description:new FormControl(null,
        Validators.compose([Validators.required])),
    })
  }
  closePreview() {
    this.displayPreviewDialog = false;
  }


  navigateToNewsList(){
    this.router.navigate(['/adminDashboard/news']);
  }
  browseFiles() {
    this.fileInput.nativeElement.click();
  }

async  onSubmit(formdata: any,ShouldPublish) {
    try {
      if (this.addNewNews.valid) {
      this.loader = true;
        const formData: FormData = new FormData();
        formData.append("Heading", formdata.newsHeading);
        formData.append("Department", formdata.department);
        formData.append("Description", formdata.description);
        formData.append("IsPublished", ShouldPublish);
        formData.append('Image', this.file, this.file.name);
      await this.loginService.tokenRenewal();
       await this.News.postnewslists(formData).subscribe(
          (data) => {
            this.loader = false;
            if (data.statusCode == 201){
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'News created successfully',
            });
            this.addNewNews.reset();
           } },
          (error: any) => {
            this.loader = false;
            this.appinsightsService.logException(error,1)
            this.appinsightsService.logEvent("Error: error in getting news creation api", {payload: JSON.stringify(formData),error:JSON.stringify(error)})
            if (error.status == 401) {
              this.confirmationService.confirm({
                message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                key: 'toastMsg',
                accept: () => {
                  localStorage.clear();
                  this.loginService.SelectedFieldID.next(null);
                  this.router.navigate(['/home']);
                },
              });
            } else if (error.status == 500) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at server side!',
              });
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: error.error.responseException[0],
              });
            }
          }
        );
        this.file = undefined;
        this.postImage = null;
        this.fileSizeWithinLimit = true;
      } else {
        for (const field in this.addNewNews.controls) {
          this.addNewNews.controls[field].markAsDirty();
        }
      }
    } catch (error) {
      this.loader = false;
      this.appinsightsService.logException(error,2)
    } 
  }
  onFileSelected(event: any) {
    const img = this.addNewNews.controls["image"];
    this.file = event.target.files[0];
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const maxSize = 1000000 ;
      if (file.size >= maxSize) {
        console.log("greater than 1mb")
        this.fileSizeWithinLimit = false;
        return;
      }
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        const result = (<FileReader>event.target).result;
        this.postImage = result as string;
        img.patchValue(this.postImage);
      };
      reader.readAsDataURL(file);
    }
  }
}