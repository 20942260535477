import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appNestedLoop]'
})
export class NestedLoopDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input() set appNestedLoop(items: any[]) {
    this.viewContainer.clear();

    if (items && items.length) {
      this.renderItems(items);
    }
  }
  private renderItems(items: any[]) {
    items.forEach((item) => {
      this.viewContainer.createEmbeddedView(this.templateRef, {
        $implicit: item,
      });

      if (item.children && item.children.length) {
        this.renderItems(item.children);
      }
    });
  }
}
