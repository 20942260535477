<div class="container-fluid px-md-5 pb-5">
    <div class="row mt-3  pb-2 px-3 mx-md-5">
        <div class="col-12 align_inline px-md-5">
            <h1 class="burgendy fw-bolder">Events</h1>
        </div>
    </div>
    <div class="row mt-3  pb-5 px-md-5 mx-md-5 " *ngIf="!loader">
        <div class="col-12 col-md-3 d-flex justify-content-start align-items-center">
            <p-dropdown [options]="eventsSearch" placeholder="All Events" class="publicView-dropDown"  optionLabel="value"  (onChange)="filterDataBasedOnSelectedStatus($event)"></p-dropdown>    
        </div>
        <div class="col-12 mt-1 col-md-9 mt-3 d-flex justify-content-end align-items-center ">
            <div class="row  d-flex justify-content-end ">
                <div class="col-12 col-md-8 d-flex  d-flex justify-content-end" >   
                    <div class="form-group has-search d-inline text-center"> 
                        <span class="fa fa-search form-control-feedback d-block text-center"></span> 
                        <input type="text" class="global_search h-2 form-control border-right-0 h-2.25rem border border-1 border-muted p-2 bg-light-ash" autocomplete="off" pInputText size="50" placeholder=" Ex: Search Event..." (keyup)="filterSearchedEvent($event.target.value)" > 
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-0 px-md-5 mx-md-5" *ngIf="!loader" >
        <div class="col-xs-12 col-md-4  mt-2 pb-5"  *ngFor="let event of eventsList" >
            <div class="bottom-line h-100">
            <p-card class="shadow cursor-pointer" (click)="navigateToEventDetails(event)">
                <ng-template pTemplate="header">
                    <div class="image-container position-relative">
                        <div class="image-text position-absolute pl-3 pt-3 ">
                            <div class="text-content p-1 bg-burgendy rounder_rightBottom__border text-white "> <span class="p-4 fs-6"> {{event?.eventStartDateAndTime
                                | date: 'MMM dd' }} </span><br> <span class="p-4 fs-6"> {{event?.eventStartDateAndTime
                                    |date: 'yyyy' }}</span></div>
                          </div>
                      <img alt="Card" [src]="event?.eventImage" class="Custom_image_height img-thumbnail p-3 pb-0" />
                    </div>
                  </ng-template>
                
                <h3 class="custom_width__ForTextWrap1 text-truncate col-12 col-md-12 p-0 pb-1 text-black fw-semibold">{{event?.eventName}}</h3>
                <p class="m-0"><i class="fa fa-calendar text__ash"><span class="p-2 text__ash">{{event?.eventStartDateAndTime|date:'MMM dd YYYY'}} - {{event?.eventEndDateAndTime|date:'MMM dd YYYY'}}</span></i></p>
                <p class="m-0"><i class="fa fa-clock-o text__ash"><span class="p-2 text__ash">{{event?.eventStartDateAndTime|date:'shortTime'}} - {{event?.eventEndDateAndTime|date:'shortTime'}}</span></i></p>
                <p class="m-0"><i class="fa fa-map-marker text__ash"><span class="p-2 text__ash">{{event?.eventVenue}}</span></i></p>
                <!-- <ng-template pTemplate="footer"> -->
                    <p-editor  class="pEditor__Readonly text-wrap  w-100 p-3" [(ngModel)]="event.eventDescription"  [readonly]="true"></p-editor>
                <!-- </ng-template>       -->
            </p-card>
        </div>
        </div>
        
    </div>
    <div class="row mt-0 px-md-5 mx-md-5" *ngIf="loader">
        <div class="col-12 text-center">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div><p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
