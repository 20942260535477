import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-event-delete',
  templateUrl: './event-delete.component.html',
  styleUrls: ['./event-delete.component.css']
})
export class EventDeleteComponent implements OnInit {
  eventId:any;
  displayPreviewDialog: boolean = true;
  constructor(
    private titleService: Title,
    private EventDetails:EventDetailsService,
    private route: ActivatedRoute,
    private loginService: LoginService, 
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
    private loginDetails: LoginService
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event delete screen visited')
    this.titleService.setTitle('Delete Event');
    this.route.params.forEach((urlParams) => {
      this.eventId = urlParams['eventId'];
      this.loginService.SelectedEventId.next(this.eventId)
    });
    this.deleteEvent();
  }

 async deleteEvent(){
    try {
       await this.loginDetails.tokenRenewal();
      await this.EventDetails.deleteEventDetails(this.eventId).subscribe(
        (data) => {
          if (data.statusCode == 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Event deleted successfully',
            });
            setTimeout(() => {
              this.navigateBackToList();
            }, 1000);
          }
        },
        (error: any) => {
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in getting Event delete api", {payload: JSON.stringify(this.eventId),error:JSON.stringify(error)})
          if (error.status == 401) {
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key: 'toastMsg',
              accept: () => {
                localStorage.clear();
                this.loginService.SelectedFieldID.next(null);
                this.router.navigate(['/home']);
              },
            });
          } else if (error.status == 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Something went wrong at server side!',
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.responseException.message,
            });
          }
        }
      );
    } catch (error) {
      console.error('An error occurred:', error);
    }
    
  }
  navigateBackToList(){
    this.router.navigate(['/adminDashboard/eventsList']);
  }
}
