
<div class="container-fluid pb-5">
    <div class="row mt-3 d-md-flex align-item-center">
        <div class="col-12 col-md-6 px-5">
            <h1 class="burgendy fw-semibold">News</h1>
        </div>
        <div class="col-12 col-md-6 px-5 text-md-end">
            <p-button label="Add News" class="primeNgButton" type="submit" icon="pi pi-plus" iconPos="left" (click)="navigateToAddNews()"></p-button>
        </div>
    </div>
    <div class="row" *ngIf="!loader">
        <div class="col px-5">
            <p-table #NewsData value="news" sortMode="single"  [responsive]="true" [rows]="10" [value]="newslist" class="pTable"
            sortOrder="-1" [paginator]="true"  [globalFilterFields]="['heading','department','createdDate','publishedDate','status']">

                <ng-template pTemplate="caption">
                    <div class="row align_inline">
                        <div class="col-12 col-md-4">
                            <p-dropdown  placeholder="-- Select --" [(ngModel)]="selectedOption" optionLabel="status" [options]="allnewsOptions" (onChange)="filterDataBasedOnSelectedStatus($event)"></p-dropdown>
                         </div>
                         <div class="col-12 col-md-4 d-flex" style="text-align: right">
                            <div class="form-group has-search text-center d-inline">
                                <span class="fa fa-search form-control-feedback text-center text-black mt-1 d-block"></span>
                                <input  type="text"     (input)="NewsData.filterGlobal($event.target.value, 'contains')"   class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2 text-black placeHolder-color" autocomplete="off" pInputText size="50" placeholder=" Search..." > 
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" >
                    <tr>
                        <th [pSortableColumn]="'heading'" class="fw-semibold">Post Name<p-sortIcon [field]="'heading'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'department'" class="fw-semibold">Department<p-sortIcon [field]="'department'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'createdDate'" class="fw-semibold">Created Date<p-sortIcon [field]="'createdDate'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'publishedDate'" class="fw-semibold">Published Date<p-sortIcon [field]="'publishedDate'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'status'" class="text-center" class="fw-semibold">Status<p-sortIcon [field]="'status'"></p-sortIcon>
                        </th>
                        <th class="text-center" class="fw-semibold" >Actions
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-news let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr  [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                <td><a class="text-decoration-none text-primary cursor-pointer text__wrap" (click)="newsOverView(news)"> {{news?.heading}}</a> </td>                 
                        <td>{{news?.department}} </td>  
                        <td>{{news?.createdDate  | date: 'dd/MM/yyyy' }} </td>
                        <td>{{news?.publishedDate | date: 'dd/MM/yyyy' }}</td>
                        <td [ngClass]="{'success_Class':news?.status == 'Published'}">{{news?.status }}</td>
                          <td class="align_inline d-flex align-item-center justify-content-center">
                            <a [routerLink]="" (click)="editNews(news)" >
                                <span  class="fa fa-pencil m-2 cursor-pointer"></span>
                            </a>
                            <!-- *ngIf="blogList?.status != 'Published' " *ngIf="news?.status != 'Published'" -->
                                <span  [routerLink]="" (click)="onDelete(news)" class="fa fa-trash m-2 cursor-pointer"></span>  
                        </td>                
                    </tr> 
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="row" *ngIf="loader">
        <div class="col-12 text-center">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>
<p-dialog [modal]="true" [draggable]="false"  [resizable]="false" class="addUserPopup" [(visible)]="showDeleteDialog"
closable="false" [style]="{'width': '100%', 'max-width': '600px'}" >
<div class="row  ">
        <div class="col-12 col-md-12 pb-5">
            <h4  class="burgendy fw-semibold pl-2">Are you sure you want to delete this News?</h4>
        </div>
        <div class="col-12 col-md-12 d-flex align-item-center justify-content-end bg-light-ash ">
            <p-button class="m-2 cancel_Btn" label="Cancel" type="button" (click)="closeDelete()"></p-button>
            <p-button class="m-2 primeNgButton" label="Delete" type="button"  (click)=" deleteNews()"></p-button>
        </div>
</div>
</p-dialog>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"   label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
    </p-confirmDialog>