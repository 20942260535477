import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadExcelService {

  constructor( private http: DataService,) { }
  
 

  uploadExcel(excelFile)
      {
        let url = 'api/StagedUsers';
        const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
        'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
        return this.http.post(url,excelFile,{headers: requestHeader}).pipe(map(data => data))       
      }
  getStagedUsers(){
    let url = 'api/StagedUsers';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
   
    // return this.http.get(url,{headers:this.headers}).pipe(map(data => data))
  }
  sampleTemplateExcel(){
    let url = 'api/StagedUsers/download/excel';
    const headers = new HttpHeaders({'Content-Type': 'application/octet-stream',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, {  responseType: 'blob' , headers: headers})
    .pipe(map(data => data));
  }
}
