<div class="container-fluid pb-5">
    <div class="row mt-3">
        <div class="col-12 align_inline px-5">
            <h1 class="burgendy fw-bold">Blogs</h1>
            <p-button label="Add Blog" class="primeNgButton" type="submit" icon="pi pi-plus" iconPos="left"
                (click)="navigateToCreateBlog()"></p-button>
        </div>
    </div>
    <div class="row " *ngIf="!loader">
        <div class="col px-5 py-3 ">
            <p-table #blogData [value]="blogsList" sortMode="single" [responsive]="true" [rows]="10" sortOrder="-1"
                [paginator]="true" [rowsPerPageOptions]="getPaginationOptions()"
                [globalFilterFields]="['title','authorName','createdAt','status']">
                <ng-template pTemplate="caption">
                    <div class="row align_inline">
                        <div class="col-12 col-md-4">
                            <p-dropdown placeholder="-- Select --" optionLabel="status" [(ngModel)]="selectedOption"  [options]="allBlogsOptions" (onChange)="filterDataBasedOnSelectedStatus($event)"></p-dropdown>
                        </div>
                        <div class="col-12 col-md-4 d-flex" style="text-align: right">
                            <div class="form-group has-search text-center d-inline">
                                <span class="fa fa-search form-control-feedback text-center text-black mt-1 d-block"></span>
                                <input  type="text"     (input)="blogData.filterGlobal($event.target.value, 'contains')"  class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2 text-black placeHolder-color" autocomplete="off" pInputText size="50" placeholder=" Search..." > 
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'name'" class="fw-semibold">Post Name<p-sortIcon
                                [field]="'title'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'lefa'" class="fw-semibold">Author <p-sortIcon
                                [field]="'authorName'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'next_occurrence_date'" class="fw-semibold">Created Date<p-sortIcon
                                [field]="'createdAt'"></p-sortIcon>
                        </th>

                        <th [pSortableColumn]="'status'" class="text-center" class="fw-semibold">Status<p-sortIcon
                                [field]="'status'"></p-sortIcon>
                        </th>
                        <th class="text-center" class="fw-semibold">Actions
                        </th>
                    </tr> 
                </ng-template>
                <ng-template pTemplate="body" let-blogList let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                    <td><a  href="#" [routerLink]=""  class="text-decoration-none text-primary cursor-pointer text__wrap" (click)="blogsOverView(blogList)">{{blogList?.title }}</a></td>
                    <td > <span class="text-wrap">{{blogList?.authorName}}</span> </td>
                        <td>{{blogList?.createdAt | date: 'dd/MM/yyyy' }} </td>
                        <td [ngClass]="{'success_Class':blogList?.status == 'Published'}">{{blogList?.status }}</td>
                        <td class="d-flex text-center lign-items-center justify-content-center">
                            <a href="#" [routerLink]="" (click)="editblog(blogList)" >
                                <span class="fa fa-pencil cursor-pointer m-2"></span>
                            </a>
                            <a href="#"  [routerLink]="" (click)="onDeleteBlog(blogList)" >
                                <span class="fa fa-trash cursor-pointer m-2"></span>
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>
    </div>
    <div class="row" *ngIf="loader">
        <div class="col-12 text-center">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>
<p-dialog [modal]="true" [draggable]="false"  [resizable]="false" class="addUserPopup" [(visible)]="showDeleteDialog"
closable="false" [style]="{'width': '100%', 'max-width': '600px'}" >
<div class="row  ">
        <div class="col-12 col-md-12 pb-5">
            <h4  class="burgendy fw-semibold pl-2">Are you sure you want to delete this Blog?</h4>
        </div>
        <div class="col-12 col-md-12 d-flex align-item-center justify-content-end bg-light-ash ">
            <p-button class="m-2 cancel_Btn" label="Cancel" type="button" (click)="closeDelete()"></p-button>
            <p-button class="m-2 primeNgButton" label="Delete" type="button"  (click)=" deleteBlog()"></p-button>
        </div>
</div>
</p-dialog>
<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton" label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
    </p-confirmDialog>