export class GlobalResources {
    static getResources() {
        return {
            en: {
                emailRequired:"Email Required",
                emailInValid:"Invalid Email",
                passwordRequired:"Password Required",
                passwordInValid:"Invalid Password",
            }
        }
    }
}