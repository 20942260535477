import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EventDetailsService } from 'src/app/services/event-details.service';
import { eventsList } from '../events-list/events-list';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
@Component({
  selector: 'app-event-public',
  templateUrl: './event-public.component.html',
  styleUrls: ['./event-public.component.css']
})
export class EventPublicComponent implements OnInit {
  eventsList: eventsList[] = [];
  eventsOriginalList: any;
  allEvents:any;
  eventsSearch=[
    {id:1,value:"Upcoming Events"},
    {id:2,value:"Past Events"},
  ]
  selectedOption: number = 0;
  loader: boolean = false;
  constructor(
    private titleService: Title,
    private EventDetails:EventDetailsService,
    private router: Router,
    private appinsightsService: LoggingService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loginService: LoginService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event list public screen visited')
    this.titleService.setTitle('Events list');
    this.getEventsList();
  }

 async getEventsList(){
    try{
    this.loader = true;
    await this.EventDetails.getAllEventsForPublic().subscribe((data)=>{
      if(data){
        this.loader = false;
        this.allEvents = data.result;
        this.eventsList = [...data.result.futureEvents,...data.result.pastEvents];
        this.eventsOriginalList = [...this.eventsList];
      }
    },(error:any)=>{
      this.loader = false;
      this.appinsightsService.logException(error,1)
      this.appinsightsService.logEvent("Error: error in getting Event list public api", {error:JSON.stringify(error)})
      if(error.status == 401){
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'toastMsg',
          accept: () => {
            localStorage.clear();
            this.loginService.SelectedFieldID.next(null)
            this.router.navigate(['/home']);
          },
        })
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn', 
          summary: 'Warning', 
          detail: error.error.responseException.message
        });
      }

    })
  }
  catch(error:any){
    this.loader = false;
    this.appinsightsService.logException(error,2)
  }
  }

  navigateToEventDetails(event){
    this.router.navigate(['/Event',event.eventId]);
  }
  filterDataBasedOnSelectedStatus(event:any){
    this.selectedOption = event.value.id;
    if(this.selectedOption == 1){
      this.eventsList = this.allEvents.futureEvents;
     this.eventsOriginalList = [...this.eventsList];
    }
    else {
      this.eventsList = this.allEvents.pastEvents;
      this.eventsOriginalList = [...this.eventsList];
    }
  }
  filterSearchedEvent(value){
    try{
      this.eventsList = this.eventsOriginalList;
      if(value.length != 0){
        let eventsFilteredArray=[];
        eventsFilteredArray =   this.eventsList.filter(event => event['eventName']?.toLowerCase( ).includes(value.toLowerCase( )) ||  event['eventDescription']?.toLowerCase( ).includes(value.toLowerCase( )))
        this.eventsList = eventsFilteredArray;
      }
      else{
        this.eventsList = this.eventsOriginalList;
      }
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }
}
