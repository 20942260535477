import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-event-submenu',
  templateUrl: './event-submenu.component.html',
  styleUrls: ['./event-submenu.component.css']
})
export class EventSubmenuComponent implements OnInit {
  eventId:number;
  constructor(
    private loginService: LoginService,
    private titleService: Title,
    private appinsightsService: LoggingService,
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Event submenu screen visited')
    this.titleService.setTitle('Event SubMenu');
    this.loginService.SelectedEventId.subscribe(data=>{
      this.eventId = data;
    })
    
  }

}
