<div class="container-fluid pb-5">
    <p-toast position="top-center"></p-toast>
    <div class="row mt-3 mx-md-5 p-3">
        
        <div class="col-12 align_inline">
            <h2 class="burgendy fw-semibold">Create new event</h2>
           <div (click)="navigateToEvents()">
            <i class="fa fa-arrow-left p-2 burgendy cursor-pointer"></i><span class="burgendy cursor-pointer">Back to Events</span>
           </div>
        </div>
    </div>

    <div class="p-3 rounded-4 bg-white custom_border mx-md-5 ">
        <div class="row">
            <div class="col p-3 fw-bold">
                <h5>General Information</h5>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <form [formGroup]="createNewEvent" (ngSubmit)="onSubmit(createNewEvent.value)">
                    <div class="row">
                        <div class="col">
                            <label class="fs-6 py-2" for="eventName">Event Name<span class="star">*</span></label>
                            <input pInputText name="eventName" id="eventName" type="text" class="clsControl form-control"
                            formControlName="eventName" placeholder="Enter event name" />
                            <div class="error"
                                *ngIf="!createNewEvent.controls['eventName'].valid&&createNewEvent.controls['eventName'].dirty">
                                <span *ngIf="createNewEvent.controls['eventName'].errors['required']">Event Name Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <div class="row">
                                <label class="fs-6 py-2" for="eventStartsOn">Starts<span class="star">*</span></label>
                                <div class="col-12 col-md-8">
                                    <p-calendar  name="eventStartsOn" id="eventStartsOn" formControlName="eventStartsOnCalendar" [minDate]="minStartDate" dateFormat="yy-mm-dd" (onSelect)="onDateSelection($event)"></p-calendar>
                                    <div class="error"
                                        *ngIf="!createNewEvent.controls['eventStartsOnCalendar'].valid&&createNewEvent.controls['eventStartsOnCalendar'].dirty">
                                        <span *ngIf="createNewEvent.controls['eventStartsOnCalendar'].errors['required']">Start Date Required</span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 pl-md-0 pt-1 pt-md-0">
                                    <p-dropdown name="eventStartsOn" class="eventStartsOn" id="eventStartsOn" placeholder="--Select--" formControlName="eventStartsOnTime" [options]="startTimeValue" optionLabel="value" (onChange)="onSelectingStartTime($event)"></p-dropdown>
                                    <div class="error"
                                        *ngIf="!createNewEvent.controls['eventStartsOnTime'].valid&&createNewEvent.controls['eventStartsOnTime'].dirty">
                                        <span *ngIf="createNewEvent.controls['eventStartsOnTime'].errors['required']">Start Time Required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="row">
                                <label class="fs-6 py-2" for="eventEndsOn">Ends<span class="star">*</span></label>
                                <div class="col-12 col-md-8">
                                    <p-calendar  name="eventEndsOn" [minDate]="minEndDate"  id="eventEndsOn" formControlName="eventEndsOnCalendar" dateFormat="yy-mm-dd" (onSelect)="onEndDateSelection($event)"></p-calendar>
                                    <div class="error"
                                        *ngIf="!createNewEvent.controls['eventEndsOnCalendar'].valid&&createNewEvent.controls['eventEndsOnCalendar'].dirty">
                                        <span *ngIf="createNewEvent.controls['eventEndsOnCalendar'].errors['required']">End Time Required</span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4  pl-md-0 pt-1 pt-md-0">
                                    <p-dropdown name="eventEndsOnTime" class="eventEndsOnTime" id="eventEndsOnTime" placeholder="--Select--" formControlName="eventEndsOnTime" [options]="endTimeValue" optionLabel="value" ></p-dropdown>
                                    <div class="error"
                                        *ngIf="!createNewEvent.controls['eventEndsOnTime'].valid&&createNewEvent.controls['eventEndsOnTime'].dirty">
                                        <span *ngIf="createNewEvent.controls['eventEndsOnTime'].errors['required']">End Time Required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 ">
                            <div class="row">
                                <label class="fs-6 py-2" for="eventEndsOn">Time Zone<span class="star">*</span></label>
                                <div class="col-12">
                                    <p-dropdown id="roleName" [filter]="true" placeholder="-- Select --" autoWidth="false" class="clsControl form-control-placeholder"
                                    [options]="timeZone" optionLabel="name" [tabindex]="5" name="roleName" formControlName="timeZone"
                                    >
                                </p-dropdown>
                                    <div class="error"
                                        *ngIf="!createNewEvent.controls['timeZone'].valid&&createNewEvent.controls['timeZone'].dirty">
                                        <span *ngIf="createNewEvent.controls['timeZone'].errors['required']">Time Zone Required</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <label class="fs-6 py-2" for="venue">Venue Name<span class="star">*</span></label>
                            <input pInputText name="venue" id="venue" type="text" class="clsControl form-control"
                            formControlName="venue" placeholder="Enter venue name" />
                            <div class="error"
                                *ngIf="!createNewEvent.controls['venue'].valid&&createNewEvent.controls['venue'].dirty">
                                <span *ngIf="createNewEvent.controls['venue'].errors['required']">Venue Required</span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <label class="fs-6 py-2" for="postcode">Venue Postcode / Zip <span class="star">*</span></label>
                            <input pInputText name="postcode" id="postcode" type="text" class="clsControl form-control"
                            formControlName="postcode" placeholder="Enter postcode" />
                            <div class="error"
                                *ngIf="!createNewEvent.controls['postcode'].valid&&createNewEvent.controls['postcode'].dirty">
                                <span *ngIf="createNewEvent.controls['postcode'].errors['required']">Postcode Required</span>
                                <span  tabindex="0"
                                *ngIf="createNewEvent.controls['postcode'].errors['pattern']">Invalid Postcode/zip</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="fs-6 py-2" for="description">Description<span class="star">*</span></label>
                            <!-- <p-editor  class="w-100" name="description" id="description" formControlName="description"></p-editor> -->
                            <textarea [autoResize]="true" name="description" class=" clsControl form-control w-100" id="description" formControlName="description" pInputTextarea></textarea>
                            <div class="error"
                                *ngIf="!createNewEvent.controls['description'].valid&&createNewEvent.controls['description'].dirty">
                                <span *ngIf="createNewEvent.controls['description'].errors['required']">Description Required</span>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 p-3 fw-bold d-flex align-item-center justify-content-between">
                            <h5>Ticket Information</h5>
                            <a (click)="addItem()" *ngIf="createNewEvent.get('items').valid">
                                <span class="pi " style="cursor: pointer;padding-right: 15px; color: blue;"
                                aria-hidden="true"> Add</span>
                            </a>
                        </div>
                        <div class="col-12">
                            <div formArrayName="items">
                                  <p-table [value]="TicketDetailsArr?.controls" id="sectionDetails" name="sectionDetails">
                                    <ng-template pTemplate="body" let-item let-i="rowIndex" >
                                        <tr [formGroupName]="i" class="custom_border">
                                            <!-- <div class="custom_border m-3"> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        <td  class="float-right p-3">
                                                            <a  (click)="deleteItem(i)" >                                                    
                                                                <span *ngIf="this.createNewEvent.controls['items'].length > 1" class="pi" style="cursor: pointer;padding-right: 10px;  color: red;"
                                                                        aria-hidden="true">Remove</span>
                                                            </a> 
                                                        </td>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <td class="table_td p-3">
                                                            <label class="fs-6 py-2" for="ticketName">Ticket name<span class="star">*</span></label>
                                                            <input pInputText name="ticketName" id="ticketName" type="text" class="clsControl form-control"
                                                            formControlName="ticketName" placeholder="Enter ticket name" />
                                                            <div class="error"
                                                            *ngIf="!item.controls['ticketName'].valid&&item.controls['ticketName'].dirty">
                                                            <span *ngIf="item.controls['ticketName'].errors['required']">Ticket Name Required</span>
                                                        </div>
                                                          </td>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <td class="table_td p-3">
                                                            <label class="fs-6 py-2" for="price">Price ($)<span class="star">*</span></label>
                                                            <input pInputText name="price" id="price" type="text" class="clsControl form-control"
                                                            formControlName="price" placeholder="Enter price" />
                                                            <div class="error"
                                                            *ngIf="!item.controls['price'].valid&&item.controls['price'].dirty">
                                                            <span *ngIf="item.controls['price'].errors['pattern']">Price InValid</span>
                                                            <span *ngIf="item.controls['price'].errors['required']">Price Required</span>
                                                        </div>
                                                          </td>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <td class="table_td p-3">
                                                            <label class="fs-6 py-2" for="bookingprice">Booking Fee ($)
                                                                <span><i
                                                                    class="pi pi-question-circle d-inline-block mx-2"
                                                                    pTooltip="Enter an optional booking fee which is charged per ticket to the customer and the funds are paid to you. We would recommend charging no more than 10% of the ticket price."
                                                                    tooltipPosition="top"></i></span></label>
                                                            <input pInputText name="bookingprice" id="price" type="text" class="clsControl form-control"
                                                            formControlName="bookingprice" placeholder="Enter Booking Fee" />
                                                            <div class="error"
                                                            *ngIf="!item.controls['bookingprice'].valid&&item.controls['bookingprice'].dirty">
                                                            <span *ngIf="item.controls['bookingprice'].errors['pattern']">booking price InValid</span>
                                                            <span *ngIf="item.controls['bookingprice'].errors['required']">booking price Required</span>
                                                        </div>
                                                          </td>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-md-4">
                                                        <td class="table_td p-3">
                                                            <label class="fs-6 py-2" for="quantity">Quantity<span class="star">*</span></label>
                                                        <input pInputText name="quantity" id="postcode" type="text" class="clsControl form-control"
                                                        formControlName="quantity" placeholder="Enter quantity" />
                                                        <div class="error"
                                                            *ngIf="!item.controls['quantity'].valid&&item.controls['quantity'].dirty">
                                                            <span *ngIf="item.controls['quantity'].errors['pattern']">Quantity InValid</span>
                                                            <span *ngIf="item.controls['quantity'].errors['required']">Quantity Required</span>
                                                        </div>
                                                          </td>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <td class="table_td p-3">
                                                            <label class="fs-6 py-2" for="description">Status<span class="star">*</span></label>
                                                            <p-dropdown name="status" id="status" formControlName="status" [options]="ticketStatusOptions" optionLabel="value"></p-dropdown>
                                                            <div class="error"
                                                            *ngIf="!item.controls['status'].valid&&item.controls['status'].dirty">
                                                            <span *ngIf="item.controls['status'].errors['required']">Status Required</span>
                                                        </div>
                                                          </td>
                                                    </div>
                                                </div>
                                            <!-- </div> -->
                                        </tr>
                                    </ng-template>
                                </p-table> 
                                <div class="ui-message ui-messages-error ui-corner-all star"  *ngIf="TicketDetailsArr.hasError('duplicate')">
                                    Duplicate Ticket Name
                                  </div> 
                            </div> 
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label class="fs-6 py-2" for="eventStatus">Event Status<span class="star">*</span></label>
                            <p-dropdown name="eventStatus" id="eventStatus" formControlName="eventStatus" [options]="eventStatusOptions" optionLabel="value"></p-dropdown>
                            <div class="error"
                                *ngIf="!createNewEvent.controls['eventStatus'].valid&&createNewEvent.controls['eventStatus'].dirty">
                                <span *ngIf="createNewEvent.controls['eventStatus'].errors['required']">Status Required</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col d-flex align-items-center">
                            <p-button class=" deleteIcon primeNgButton" label="Save or Publish" type="submit"></p-button>
                            <p-progressSpinner *ngIf="loader"
                            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<p-confirmDialog   key="sessionExpiry" #cd [style]="{width: '50vw'}" class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>