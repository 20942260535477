<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row py-5 px-3">
        <div class="col-12 py-2 col-md-10 offset-md-1">
            <h3 class="burgendy fw-semibold">Contact HBS Club of Seattle</h3>
            <p>If you'd like to contact the Club, please feel free to do so in any
                of the following ways:</p>
        </div>
        <div class="col-12 col-md-10 offset-md-1">
            <form [formGroup]="contactform" (ngSubmit)="sendmsg(contactform.value)">
                <div class="row p-3 py-md-4 p-md-5 rounded bg-white shadow">
                    <div class="col-xs-12 col-md-6 p-2">
                            <h4 class="fw-semibold">Ask us anything here</h4>
                        <div class="col-12 p-2">
                            <label class="fs-6 fw-semibold" for="cname">NAME<span class="star">*</span></label>:
                            <input pInputText name="cname" id="cname" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="cname" placeholder="eg: Robert Smith ">
                            <div class="error"
                                *ngIf="contactform.controls['cname'].invalid && contactform.controls['cname'].dirty">
                                <span *ngIf="contactform.controls['cname'].errors['required']">Name is
                                    Required</span>
                                    <span *ngIf="contactform.controls['cname'].errors['pattern']">Invalid Name</span>
                            </div>
                        </div>
                        <div class="col-12 p-2">
                            <label class="fs-6 fw-semibold" for="cemail">EMAIL ADDRESS<span
                                    class="star">*</span></label>:
                            <input pInputText name="cemail" id="cemail" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="cemail"
                                placeholder="eg: example@gmail.com ">
                            <div class="error"
                                *ngIf="contactform.controls['cemail'].invalid && contactform.controls['cemail'].dirty">
                                <span *ngIf="contactform.controls['cemail'].errors['required']">Email is
                                    Required</span>
                                    <span *ngIf="contactform.controls['cemail'].errors['pattern']">Email is
                                        Invalid</span>
                            </div>
                        </div>
                        <div class="col-12 p-2">
                            <label class="fs-6 fw-semibold" for="cphone">PHONE</label>:
                            <input pInputText name="cphone" id="cphone" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="cphone" placeholder="eg: 4251234567 ">
                            <div class="error"
                                *ngIf="contactform.controls['cphone'].invalid && contactform.controls['cphone'].dirty">
                                <span *ngIf="contactform.controls['cphone'].errors['pattern']">Invalid Phone Number</span>
                            </div>
                        </div>
                        <div class="col-12 p-2">
                            <label class="fs-6 fw-semibold" for="cmsg">MESSAGE<span class="star">*</span></label>:
                            <textarea rows="4" cols="50" id="cmsg" type="text" autocomplete="off"
                                class="clsControl form-control" formControlName="cmsg"
                                placeholder="Leave your message"></textarea>
                            <div class="error"
                                *ngIf="contactform.controls['cmsg'].invalid && contactform.controls['cmsg'].dirty">
                                <span *ngIf="contactform.controls['cmsg'].errors['required']">Message is
                                    Required</span>
                            </div>
                        </div>
                        <div class="col-12 text-start p-3 d-flex align-items-center">
                            <p-button class="maroon_btn deleteIcon primeNgButton" label="Send Message" type="submit"></p-button>
                            <p-progressSpinner *ngIf="loader"
                            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6  px-md-5 p-2">    
                            <h4 class="fw-semibold px-md-5">Contact Info</h4>
                        <div class="px-md-5 ">
                            <div class="pb-3">
                                <p class="fw-semibold m-0">U.S MAIL:</p>
                                <p class="text-start color-ash m-0">6351 Seaview Avenue<br>Seattle, WA 98107-2664</p>
                                <a href="https://www.bing.com/maps?cp=47.674349%7E-122.406582&lvl=15.9" target="_blank" class="text-decoration-none">Google Direction</a>
                            </div>
                            <div class="pb-3">
                                <p class="fw-semibold m-0">CALL US</p>
                                <p class="text-start color-ash m-0">Mobile:(206) 622-8425</p>
                                <p class="text-start color-ash m-0"> Fax: (206) 623-4474 </p>
                            </div>
                            <div class="pb-3">
                                <p class="fw-semibold m-0">MAIL US</p>
                                <a class="text-start color-ash m-0 text-decoration-none cursor-pointer" href="mailto:Iinfo@hbsseattle.com">info@hbsseattle.com </a>
                            </div>
                            <div>
                            <p class="fw-semibold m-0">Social Network</p>
                            <div> 
                                <div>
                                    <!-- <i class="fa fa-facebook border rounded-circle p-3 mx-2 cursor-pointer socila_network__icon" style="color: blue;"></i>
                                    <i class="fa fa-twitter border rounded-circle p-3 mx-2 cursor-pointer socila_network__icon" style="color:#1DA1F2;"></i>
                                    <i class="fa fa-instagram border rounded-circle p-3 mx-2 cursor-pointer socila_network__icon" style="color:#C13584;;"></i> -->
                                        <p>join in our community in <a href="https://www.linkedin.com/groups/14135627" target="_blank" class="cursor-pointers">Linkedin</a></p>
                                    <!-- <i class="fa fa-linkedin border rounded-circle p-3 mx-2 cursor-pointer socila_network__icon" style="color:#0077B5;"></i> -->
                                </div>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
