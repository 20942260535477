import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-blog-submenu',
  templateUrl: './blog-submenu.component.html',
  styleUrls: ['./blog-submenu.component.css']
})
export class BlogSubmenuComponent implements OnInit {
blogId: number;
  constructor(
  private loginService: LoginService,
  private titleService: Title,
  private appinsightsService: LoggingService,
  ) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Blog submenu visited')
    this.titleService.setTitle('Blog SubMenu');
    this.loginService.SelectedblogID.subscribe(data=>{
      this.blogId = data;
    })  
  }

}
