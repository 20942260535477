import { Component, OnInit } from '@angular/core';
import { EventDetailsService } from '../services/event-details.service';
import { HomeService } from '../services/home.service';
import { LoaderService } from '../services/loader.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from '../services/login.service';
import { LoggingService } from '../services/logging.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  summaryData:any=[];
  upcomingEvents:any=[];
  paginationValues: any;
  loader: boolean = false;
  eventsLoader: boolean = false;
  constructor( 
    private EventDetails: EventDetailsService,
    private homeDetails: HomeService, 
    private router: Router,
    private appinsightsService: LoggingService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loginService: LoginService,
    private loaderService: LoaderService,  
    private titleService: Title, ) { }

  ngOnInit(): void {
    try{
      this.appinsightsService.logEvent("Dashboard screen visited");
      this.titleService.setTitle('DashBoard');
      this.getSummaryData();
      this.getEventsList();
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }

  public  getPaginationOptions(): any {
    return [5, 10, 15, 20];
  }
 
 async getSummaryData(){
    try{
      await this.loginService.tokenRenewal();
      this.loader = true;
      await this.homeDetails.getSummaryDataForAdminDasboard().subscribe((data)=>{
        if(data.statusCode == 200){
          this.loader = false;
          this.summaryData = data.result;
        }
      },(error:any) =>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: error in getting summary data api", {error:JSON.stringify(error)})
        if(error.status == 401){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
            reject: () =>{

            }
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
  
      })
    }
    catch(error){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }

 async getEventsList(){
    try{
      this.eventsLoader = true;
      // await this.loginService.tokenRenewal();
      await this.EventDetails.getAllEventsForPublic().subscribe((data)=>{
        if(data.statusCode == 200){
          this.eventsLoader = false;
          this.upcomingEvents = data.result.futureEvents;
        }
      },(error)=>{
        this.eventsLoader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in getting events list api", {error:JSON.stringify(error)})
        if(error.status == 401){
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
              localStorage.clear();
              this.loginService.SelectedFieldID.next(null)
              this.router.navigate(['/home']);
            },
          })
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      })
      if(this.upcomingEvents.length>20)
      {
        let length = Math.ceil(this.upcomingEvents.length / 10) * 10;
        this.paginationValues[this.getPaginationOptions().length] = length;
      }
    }
    catch(error){
      this.eventsLoader = false;
      this.appinsightsService.logException(error,2)
    }
  }
   
}
