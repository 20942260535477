import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BlogDetailsService } from 'src/app/services/blog-details.service';
import { LoginService } from 'src/app/services/login.service';
import { BlogList } from '../blog-list/blog-list';
import { Title } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';


@Component({
  selector: 'app-blog-list-public',
  templateUrl: './blog-list-public.component.html',
  styleUrls: ['./blog-list-public.component.css']
})
export class BlogListPublicComponent implements OnInit {
  blogsList: BlogList[] = [];
  stackMemory : any;
  loader: boolean = false;
  constructor( 
    private BlogDetails:BlogDetailsService,
    private messageService: MessageService,
    private router: Router,
    private titleService: Title,
    private loaderService: LoaderService,
    private appinsightsService: LoggingService,
    private loginDetails: LoginService,
    private confirmationService: ConfirmationService, 
  ) { }

  ngOnInit(): void {
    try{
      this.appinsightsService.logEvent('Blog list public view screen visited')
      this.titleService.setTitle('Blogs list');
      this.getBlogs();
    }
    catch(error){
      this.appinsightsService.logException(error,2)
    }
  }
 async getBlogs(){
    try {
      await this.loginDetails.tokenRenewal();
      this.loader = true;
      await this.BlogDetails.getBlogs(0,false).subscribe((data) => {
        this.blogsList = data.result;
        this.stackMemory = [...this.blogsList]
        this.loader = false;
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in  Blog list api", {payload: JSON.stringify(0),error:JSON.stringify(error)})
        if (error.status >= 400 && error.status < 500) {
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:"sessionExpiry",
            accept: () => {
              localStorage.clear();
              this.loginDetails.SelectedFieldID.next(null);
              this.router.navigate(['/home']);
            },
          });
        }
        else  if(error.status == 500){
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn', 
            summary: 'Warning', 
            detail: error.error.responseException.message
          });
        }
      });
    } catch (error) {
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }

  }
  navigateToDetailView(id){
    this.router.navigate(['/blog',id]);
  }
  filterBlog(value) {
    try{  
      this.blogsList = this.stackMemory;
      let array = []
    if (value.length !== 0) {
      array  = this.blogsList.filter(event =>
        event['title'].toLowerCase().includes(value.toLowerCase(value)) ||
        event['description'].toLowerCase().includes(value.toLowerCase(value))
      );
      this.blogsList = array;
    } 
    else{
      this.blogsList = this.stackMemory;
    }
  }
  catch(error){
    this.appinsightsService.logException(error,2)
  }
  }
  

  
}
