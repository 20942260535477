import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class BlogDetailsService {

  constructor(
    private http: DataService,
    private loginDetails: LoginService
  ) { }
  public   SelectedblogID = new BehaviorSubject(0);
  private headers = new Headers({
    'Content-Type': 'application/json'
  });

  getBlogs(count,includeDescription){
    let url = `api/Blogs?top=${count}&includeDescription=${includeDescription}`;
    const headers = this.loginDetails.setHeaders();
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  postBlogs(BlogDetails){
    let url = 'api/Blogs';
    const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
    'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.post(url,BlogDetails,{headers: requestHeader}).pipe(map(data => data))   

  }
  postBlog(blogId,BlogDetails){
    let url = `api/Blogs/${blogId}`;
    const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
    'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.put(url,BlogDetails,{headers: requestHeader}).pipe(map(data => data))   

  }
  deleteBlogDetails(blogId){
    let url = `api/Blogs/${blogId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.delete(url, { headers: headers})
    .pipe(map(data => data));
  }
  getblogoverview(blogId){
    let url = `api/Blogs/BlogOverview/${blogId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  comments(BlogDetails ){
    let url = 'api/Blogs/Comments';
    const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
    'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.post(url,BlogDetails,{headers: requestHeader}).pipe(map(data => data))   
  }
  BlogLike(blogId,isLiked){
    let url = `api/Blogs/${blogId}/Likes?isLiked=${isLiked}`;
    const requestHeader = new HttpHeaders({'enctype':'multipart/form-data',
    'Accept': '*/*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))})
    return this.http.putWithOutBody(url,{headers: requestHeader}).pipe(map(data => data))   
  }

  getSingleBlog(blogId,includeDescription)
  {
    let url = `api/Blogs/${blogId}?includeDescription=${includeDescription}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }
  editBlog(blogId,includeDescription){
    let url = `api/Blogs/${blogId}?includeDescription=${includeDescription}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  getCommentsList(blogId){
    let url = `api/Blogs/${blogId}/comments`;
    const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token'))
    });
    return this.http.get(url, { headers: headers})
    .pipe(map(data => data));
  }

  
}
