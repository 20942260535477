<div class="container-fluid">
   <div class="row py-4 px-3">
      <div class="col-12 col-md-10 offset-md-1">
         <h1 class="burgendy fw-semibold">About Us</h1>
      </div>

      <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center py-3">
         <h2 class="burgendy fw-semibold">From the President</h2>
      </div>
      <div class="col-12 col-md-10 offset-md-1">
         <div class="row">
            <div class="col-12 col-md-4">
               <img class="img-fluid shadow" [src]="'../../../assets/images/shyam.jpg'" alt="president image">
            </div>
            <div class="col-12 col-md-7">
               <p><b>Welcome to the HBS Club of Seattle!</b> <br>
                   <span> After a period of quietness during COVID, it's been an exciting year for the HBS Club of Seattle! From learning about the business of baseball with Mariner’s Owner John Stanton (HBS MBA ’79) at T-Mobile Park, to helping our young alumni navigate the challenges of a changing tech market with Career Coach Anne Marie Checcone (HBS MBA ’04)– we are bringing our over 2,000 alumni together to foster a stronger HBS community and extend our learnings with exclusive bespoke events. 
                      <br>
                      <br>
                      For now, we consider all of our HBS alumni who live in the area as “Members” and we do not have a paid membership. <br>
                      This means we fund our events and basic costs through minimal ticket fees and sponsorship. </span><span id="dots">...</span>
                  <span class="text-decoration-underline cursor-pointer burgendy" (click)="readMore()" id="myBtn">Read more</span> </p>
            </div>
         </div>
         <div class="col-12 col-md-11">
         <span id="more">   
         <p class="pt-1">That also means the Club needs alumni like you to help us grow and succeed. If you have an idea for an event or new initiative, a corporate space for an event, fundraising and sponsorship, please reach out to us.</p>
         <p class="pt-1">If you are new to the greater Seattle area, please update your information with your Seattle-area location and your lifetime email, at  <a href="https://www.alumni.hbs.edu/Pages/default.aspx">alumni.hbs.edu</a>. And feel free to email us with any help you might need to make the area your new home. </p>
         <p class="pt-1">We are looking forward to an even more successful 2024 and hope to see you out as often as possible! <br> </p>
          <p class="pt-2">  Shyam Ayengar <br>
            President <br>
            HBS Club of Seattle<br>
            </p>
         <!-- <p><b>Please contact our current co-Presidents</b> <a class="text-danger"
            href="mailto:Testkgao@mba2007.hbs.edu"> Shyam Ayengar </a>, <a class="text-danger"
            href="mailto:Testsmark@mba1976.hbs.edu">Jon Weintraub </a></p>        -->
      </span>
   </div>
      </div>
      <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center py-4">
         <h2 class="burgendy fw-semibold">Our Mission</h2>
      </div>
      <div class="col-12 col-md-10 offset-md-1">
         <div class="row">
            <div class="col-12 col-md-6">
               <p>To cultivate a vibrant community of local HBS alumni that create a positive impact in our community.
               </p>
                  <p class="m-0">This mission will be accomplished by:</p>
                  <ul><li>Cultivate a vibrant community local HBS alumni through social and professional networking  </li>
                     <li> Promote lifelong learning and extend our HBS education through events with local corporate leaders, professors, and community leaders </li>
                     <li>Contribute to our local community through volunteering, non-profit and outreach programs</li></ul>
            </div>
            <div class="col-12 col-md-6">
               <img class="img-fluid" [src]="'../../../assets/images/our_mission.png'" alt="president image">
            </div>
         </div>
      </div>
      <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center pt-5 pb-3">
         <h2 class="burgendy fw-semibold">Club Officers & Board of Directors </h2>
      </div>
      <div class="col-12 col-md-10 offset-md-1">
         <h4 class="burgendy fw-semibold pl-5">Co-Presidents</h4>
      </div>
      <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center py-5">
         <div class="row">
            <div class="col-12 col-md-3 offset-md-3 px-2">
               <img [src]="'../../../assets/images/shyam.jpg'" class="img-rounded img-fluid w-100 shadow" alt="Co-President image">
               <div class="text-center color-ash">
                  <p class="fw-semibold m-0">Shyam Ayengar </p>
                  <!-- <p class="fw-semibold"> <a href="mailto:sayengar@mba2006.hbs.edu" class="color-ash" >sayengar@mba2006.hbs.edu</a></p> -->
               </div>
            </div>
            <div class="col-12 col-md-3 px-2">
               <img [src]="'../../../assets/images/jon.jpg'" class="img-rounded img-fluid w-100 shadow" alt="Co-President image">
               <div class="text-center color-ash">
                  <p class="fw-semibold m-0">Jon Weintraub </p>
                  <!-- <p class="fw-semibold"> <a href="mailto:jweintraub@mba1989.hbs.edu" class="color-ash" >jweintraub@mba1989.hbs.edu</a></p> -->
               </div>
            </div>
         </div>
      </div>
      <div class="col-12 col-md-10 offset-md-1">
         <h4 class="burgendy fw-semibold pl-5">Board Members</h4>
      </div>
      <div class="col-12 col-md-10 offset-md-1 d-flex align-items-center py-5">
         <div class="row">
            <div class="col-12 col-md-3 px-2">
               <img [src]="'../../../assets/images/jagan.jpg'" class="img-rounded img-fluid w-100 shadow" alt="Co-President image">
               <div class="text-center color-ash">
                  <p class="fw-semibold m-0">Jagan Chitiprolu</p>
                  <!-- <p class="fw-semibold"> <a href="mailto:demo@gmail.com" class="color-ash" >demo@gmail.com</a></p> -->
               </div>
            </div>
            <div class="col-12 col-md-3 px-2">
               <img [src]="'../../../assets/images/stan.jpg'" class="img-rounded img-fluid w-100 shadow" alt="Co-President image">
               <div class="text-center color-ash">
                  <p class="fw-semibold m-0">Stan Chang</p>
                  <!-- <p class="fw-semibold"> <a href="mailto:demo@gmail.com" class="color-ash" >demo@gmail.com</a></p> -->
               </div>
            </div>
            <div class="col-12 col-md-3 px-2">
               <img [src]="'../../../assets/images/alex.jpg'" class="img-rounded img-fluid w-100 shadow" alt="Co-President image">
               <div class="text-center color-ash">
                  <p class="fw-semibold m-0">Alex Ray</p>
                  <!-- <p class="fw-semibold"> <a href="mailto:demo@gmail.com" class="color-ash" >demo@gmail.com</a></p> -->
               </div>
            </div>
            <div class="col-12 col-md-3 px-2">
               <img [src]="'../../../assets/images/kyle.jpg'" class="img-rounded img-fluid w-100 shadow" alt="Co-President image">
               <div class="text-center color-ash">
                  <p class="fw-semibold m-0">Kyle Buffenmyer</p>
                  <!-- <p class="fw-semibold"> <a href="mailto:demo@gmail.com" class="color-ash" >demo@gmail.com</a></p> -->
               </div>
            </div>
         </div>
      </div>
      <!-- <div class="col-12 col-md-8 offset-md-2 d-flex align-items-center">
         <div class="row">

         </div>
      </div> -->
   </div>
</div>