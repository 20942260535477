<div class="container-fluid px-md-5 ">
    <div class="row mt-3  pb-2 px-md-4 mx-md-5">
        <div class="col-12 align_inline">
            <h1 class="burgendy fw-bold">Blogs</h1>
        </div>
    </div>
        <div class="col-12 d-flex justify-content-end align-items-center" *ngIf="!loader">
            <div class="row px-md-5 mx-md-3 d-flex justify-content-end">
                <div class="col-12 col-md-8  d-flex justify-content-end "  >   
                    <div class="form-group has-search text-center d-inline">
                        <span class="fa fa-search form-control-feedback text-center d-block"></span>
                        <input  type="text"  (keyup)="filterBlog($event.target.value)"  class="global_search form-control border-right-0 h-2.25rem border border-1 border-muted p-2 bg-light-ash" autocomplete="off" pInputText size="50" placeholder=" Ex: Search Blogs..."
                      > 
                    </div>
                </div>
            </div>
        </div>
    <div class="row mt-3 px-md-4 pb-5 mx-md-5 " *ngIf="!loader">
        <div class="col-xs-12 col-md-4 mt-2 pb-5 "  *ngFor="let blog of  blogsList; let i = index" >  
            <div  class="event_card cursor-pointer" (click)="navigateToDetailView(blog.id)">
                    <div class="image-container position-relative">
                      <img alt="Blog Image" src="{{ blog?.image}}" class="img-fluid w-100 h-100 img-fixed-size bg-black"/>
                    </div>
                  <h4 class="custom_width__ForTextWrap text-truncate m-0 col-12 col-md-12  mt-4 fw-semibold p-0">{{blog.title}}</h4>
                      <p-editor class="pEditor__Readonly  text-wrap w-100" [(ngModel)]="blog.description"  [readonly]="true"></p-editor>
                </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 text-center" *ngIf="loader">
            <p-progressSpinner
            styleClass="custom-spinner" aria-label="Loading" strokeWidth="3"
            animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>

<p-confirmDialog key="sessionExpiry" #cd [style]="{width: '50vw'}"  class="session_tiomeout__popup">
    <ng-template pTemplate="footer">
        <a (click)="cd.reject()"><span
            class="text-dark d-inline-block p-3 cursor-pointer">Cancel</span></a>
        <p-button type="button" class="primeNgButton"  label="LOGIN" (click)="cd.accept()"></p-button>
    </ng-template>
</p-confirmDialog>